import React from 'react';

const NavigationContext = React.createContext();

export const NavigationProvider = (props) => {

  const [navigationActivated, setNavigationActivated] = React.useState(false);

  const context = {
    navigationActivated,
    setNavigationActivated,
  };

  return (
    <NavigationContext.Provider value={context}>
      {props.children}
    </NavigationContext.Provider>
  );

};

export const useNavigationContext = () => React.useContext(NavigationContext);
