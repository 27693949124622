import React, { useEffect, useRef } from 'react'
import { IoCloseSharp } from 'react-icons/io5'
import CompanyModalCoupon from './CompanyModalCoupon'
import CompanyHeader from './CompanyHeader'

function CompanyModal({ company, companyType, userOwnsBooklet, isCompanyModalOpen, setIsCompanyModalOpen }) {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isCompanyModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        setIsCompanyModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isCompanyModalOpen, modalRef, setIsCompanyModalOpen]);

  return (
    <div className={`modal booklet-company-modal ${!isCompanyModalOpen ? 'hidden' : 'flex'}`}>
      <div ref={modalRef} className="box modal-box">
        <IoCloseSharp className="close" onClick={() => setIsCompanyModalOpen(false)} />
        <CompanyHeader company={company} companyType={companyType} />
        <div className="modal-coupon-container">
          {company.calculatedCoupons.map(coupon =>
            <CompanyModalCoupon key={coupon._id} coupon={coupon} userOwnsBooklet={userOwnsBooklet} />
          )}
        </div>
      </div>
    </div>
  )
}

export default CompanyModal
