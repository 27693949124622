import React, { useEffect, useState } from 'react';
import { usePreviewContext } from '../../context/PreviewContext';
import { useLazyQuery, useQuery } from 'react-apollo';
import { companyFromBookletQuery } from '../../queries/booklet';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import BookletCompany from '../vara-haften/BookletCompany';
import CompanyModalCoupon from '../vara-haften/CompanyModalCoupon';
import { getCompanyTypesQuery } from '../../queries/company';
import CompanyHeader from '../vara-haften/CompanyHeader';

function PreviewCompany({ togglePreview }) {
  const { isPreviewOpen, companyToPreview, previewType, setPreviewType, setIsPreviewOpen } = usePreviewContext();
  const { data: companyTypesData } = useQuery(getCompanyTypesQuery);

  const [company, setCompany] = useState(null);

  const [getCompanyPreview, { error }] = useLazyQuery(companyFromBookletQuery, {
    onCompleted: (data) => {
      if (previewType !== 'company') {
        setPreviewType('company');
      }
      setIsPreviewOpen(true);
      setCompany(data.companyFromBooklet);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (companyToPreview?.bookletId && companyToPreview?.companyId) {
      getCompanyPreview({ variables: { bookletId: companyToPreview.bookletId, companyId: companyToPreview.companyId } });
    }
  }, [companyToPreview, getCompanyPreview]);

  if (error) {
    console.error(error);
  };

  return (
    <div className='company-preview'>
      {company
        ? <>
          <h3>Såhär ser ert företag ut i häftet!</h3>
          {company &&
            <div className="company-header">
              <BookletCompany
                company={company}
                companyType={companyTypesData?.types[company?.typeId]}
              />
            </div>
          }
          <h3>Såhär ser det ut när man klickat in på ert företag</h3>
          <div className="relative company-preview-coupons">
            <CompanyHeader
              company={company}
              companyType={companyTypesData?.types[company?.typeId]}
            />
            {company?.calculatedCoupons.map(coupon =>
              <CompanyModalCoupon
                key={coupon._id}
                coupon={coupon}
              />
            )}
          </div>
        </>
        : <h3>
          Företaget kunde inte hittas i häftet.
        </h3>
      }

      <button className='toggler' onClick={togglePreview}>
        {isPreviewOpen ? <BsChevronRight size={20} /> : <BsChevronLeft size={20} />}
      </button>
    </div>
  )
}

export default PreviewCompany
