/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { updateMyPasswordMutation, getMyUserQuery } from '../../queries/queries';
import { Field, Button } from '../../common/index';
import { useHistory } from 'react-router-dom';
import { useNavigationContext } from '../../context/NavigationContext';


function MyUserForm(props) {
  // Add email as props or get it directly from query MyUser, or something
  const { updateAuthTokenCallback } = props;
  const [user, setUser] = useState({ currentPassword: '', newPassword: '' });
  const [message, setMessage] = useState(null);
  const [updateMyPassword, { loading: updateMyUserLoading, error: updateMyUserError }] = useMutation(updateMyPasswordMutation);
  //const [updatePasswordForm, setUpdatePasswordForm] = useState(props.updatePasswordForm);
  //const [errorNonMatchingPassword, setErrorNonMatchingPassword] = useState(false);
  //const [update, { loading: loginLoading, error: loginError }] = useMutation(updateMyUser);//, options);
  const { data: userData } = useQuery(getMyUserQuery);
  const history = useHistory();

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  useEffect(() => {
    if (userData)
      setUser({ exception: userData.user.exception, currentPassword: userData.user.currentPassword, newPassword: userData.user.newPassword, confirmedPassword: userData.user.confirmedPassword });
  }, [userData]);




  function submitForm(e) {
    e.preventDefault();
    console.log(user)

    //validate
    if (!user.currentPassword) {
      setMessage("Ange ditt nuvarande lösenord")
      return null;
    }

    if (!user.newPassword || user.newPassword.length < 6) {
      setMessage("Nytt lösenord måste vara mer än 6 tecken")
      return null;
    }

    if (!user.confirmedPassword || user.confirmedPassword != user.newPassword) {
      setMessage("Lösenorden är inte lika")
      return null;
    }

    if (updateMyUserLoading) {
      console.log("Loading. Double submit canceled.")
      return null;
    }

    let vars = {
      variables: {
        currentPassword: user.currentPassword,
        newPassword: user.newPassword
      },
    };

    //if (user.newPassword !== user.confirmedPassword)


    updateMyPassword(vars)
      .then(result => { console.log('result', result); setMessage("Dina ändringar är sparade"); updateAuthTokenCallback(result && result.data.updateUser); })
      .catch(useMutationError => {
        console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors)
      });
  }

  const verify = (v) => {
    setMessage(null);
    setUser({ ...user, ...v });
  };

  return (
    <div id="my_user" className="container">
      <div className="goBack">
        <Button className="secondary" onClick={() => history.goBack()}>Tillbaka till mina uppgifter</Button>
      </div>
      <div className="box md">
        <h3>Ändra lösenord</h3>
        <form onSubmit={(e) => { submitForm(e); }}>
          {console.log('updateMyUserLoading', updateMyUserLoading)}

          <p>för {userData && userData.user.email}</p>
          <Field type="password" x2 name="currentPassword" title="Nuvarande lösenord" value={user.currentPassword} onChange={verify} />
          <Field type="password" x2 name="newPassword" title="Nytt lösenord" value={user.newPassword} onChange={verify} />
          <Field type="password" name="confirmedPassword" title="Bekräfta nytt lösenord" value={user.confirmedPassword} onChange={verify} />


          {updateMyUserError && updateMyUserError.graphQLErrors.map(({ message }, i) => (
            <p className="error" key={i}>{message}</p>
          ))}

          {message && <p className="message">{message}</p>}
          <Button className="postBtn primary lg" loading={updateMyUserLoading} type="submit">Spara ändringar</Button>
        </form>
      </div>
    </div>
  );
}

MyUserForm.propTypes = {
};

MyUserForm.defaultProps = {
};

export default MyUserForm;
