import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { getAssociationPublicQuery, getTeamMembersQuery, getTeamNameQuery, getTeammemberById } from '../../../queries/queries';
import SalesTable from './SalesTable'
import SalesStatistics from './SalesStatistics'
import { useNavigationContext } from '../../../context/NavigationContext';
import { PageHeader } from '../../../common';

//Lag-vyn när inloggad som lagmedlem.
function DashboardSalesStatistic(props) {
  // const { associationId, teamId, teamMemberId  } = props;
  // const { data: userData, loading: userLoading, error: userError } = useQuery(getMyUserQuery);
  const { association: associationId, team: teamId, teammember: teamMemberId } = props.match.params;
  const { data: teamMemberData } = useQuery(getTeammemberById, { variables: { teamMemberId: teamMemberId } });
  const { data: teamData } = useQuery(getTeamNameQuery, { variables: { teamId: teamId } });
  const { data: associationData } = useQuery(getAssociationPublicQuery, { variables: { associationId: associationId } });
  const { data: teamMembersData } = useQuery(getTeamMembersQuery, {
    variables: {
      teamId: teamId
    }
  });

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  React.useEffect(() => {
    teamMembersData?.teamMembers && console.log('Mitt lag', teamMembersData.teamMembers);
  }, [teamMembersData?.teamMembers])

  return (
    <div id='sales-statistics'>
      <PageHeader>
        <h1>{teamMemberData && teamMemberData.teamMember.firstname} {teamMemberData && teamMemberData.teamMember.lastname}</h1>
        <p>{teamData && teamData.name}, {associationData && associationData.association.name}</p>
      </PageHeader>
      <SalesTable associationId={associationId} teamMemberId={teamMemberId} />
      <SalesStatistics associationId={associationId} teamId={teamId} teamMemberId={teamMemberId} />
    </div>
  )
}

export default DashboardSalesStatistic
