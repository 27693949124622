import React from 'react'
import Box from '../common/Box';
import { useState } from 'react';
import Plus from '../img/add-gr.svg'

function TextCollapse(props) {
  const { title, text } = props;
  const [showInfo, setShowInfo] = useState(true);

  return (
    <Box>
      <div className="more-info" onClick={() => setShowInfo(!showInfo)}>
        <div className="collapsed-info">
          <div className="more-info-title">
            <h5>{title}</h5>
          </div>
          <div className={` more-info-icon ${showInfo === true ? '' : 'toggleHide'}`}>
            <img src={Plus} alt="" />
          </div> 
        </div>
        <div className={`expanded-info ${showInfo === true ? '' : 'toggleHide'}`}>
          <p>{text}</p> 
          {/* Kom på att det inte går formatera denna texten. En lösning skulle kunna vara att ta bort p-taggen ovan och sätta dem på den inskickade datan istället. Kanske? */}
        </div>
      </div>
    </Box>
  )
}

export default TextCollapse
