import React from 'react';

function NavItem({title, icon}) {
  return (
    <div className={`nav-item`}>
      <img
        className="home-nav-link"
        alt="Home"
        src={icon}
        // src={activeTab === 'Hem' ? HomeFill : HomeLine}
      >
      </img>
      <span>{title}</span>
    </div>
  );
}

export default NavItem;
