import React, { useState } from 'react'
import { Button } from '../../common/Form';
import { useMutation } from 'react-apollo';
import { generateCompletedOrdersPDF } from '../../queries/booklet';

const OrderBatchDownloadPDF = ({ bookletId, batchLabel, isEmailView }) => {
  const [generatePDF, { loading: isPdfMutationLoading }] = useMutation(generateCompletedOrdersPDF);
  const [loading, setLoading] = useState('');

  const downloadAsPDF = async (mode) => {
    if (isPdfMutationLoading) return;

    setLoading(mode);
    try {
      const response = await generatePDF({
        variables: {
          batchLabel,
          bookletId,
          mode,
        }
      });

      const base64Data = response.data.generateCompletedOrdersPDF;
      let blob;
      let fileName;

      if (mode === 'separated') {
        // Handle data as a ZIP file
        const binaryString = window.atob(base64Data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        blob = new Blob([bytes], { type: 'application/zip' });
        fileName = `${batchLabel}.zip`;

      } else {
        // Handle data as a PDF file
        const asciiValues = base64Data.split(',').map(value => parseInt(value, 10));
        const bytes = new Uint8Array(asciiValues);

        blob = new Blob([bytes], { type: 'application/pdf' });
        fileName = `${batchLabel}.pdf`;
      }

      // Create and download file
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);

      setLoading('');
    } catch (err) {
      console.error('Error generating PDF:', err);
      setLoading('');
    }
  };

  return (
    <div>
      <b className='block'>Ladda ned PDF</b>
      <div className={`flex flex-col max-w mb ${isEmailView ? 'gap-1' : ''}`}>
        <Button type='button' onClick={() => downloadAsPDF('separated')} className="primary md" loading={loading === 'separated'}>
          Separerad
        </Button>
        <Button type='button' onClick={() => downloadAsPDF('combined')} className="primary md" loading={loading === 'combined'}>
          Kombinerad
        </Button>
      </div>
    </div>
  )
}

export default OrderBatchDownloadPDF
