import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { getMyUserQuery, getTeamMembersQuery } from '../../../queries/queries';
import { TableSection, EditButton, sortList, SortButton } from '../../../common/index';
import Form from './TeamMembersForm';
import Sort from '../../../img/sort.svg';
import ModalAddTeammember from './ModalAddTeammember';
import TeamMember from './TeamMember';
import ArrowBlack from '../../../img/arrow-black.svg';
import { REACT_APP_BRAND } from '../../../env';
import ImgShoppen from '../../../img/shoppen@2x.png';

function TeamMembersTable(props) {
  const { teamId } = props;
  const possessionType = JSON.parse(localStorage.getItem('POSSESSION'))?.type;
  const [editing, setEditing] = useState('');
  const [sortBy, setSortBy] = useState('firstname');
  const [sortOrder, setSortOrder] = useState(1);
  const sort = { sortBy, sortOrder, setSortBy, setSortOrder };
  const { data: userData } = useQuery(getMyUserQuery);
  const [isFirstTeammember, setIsFirstTeammember] = React.useState();
  const [moveOrdersFrom, setMoveOrdersFrom] = React.useState(null);
  var { loading: teamMembersLoading, error: teamMembersError, data: teamMembersData, refetch: refetchTeamMembers } = useQuery(getTeamMembersQuery, {
    variables: { teamId: teamId, includeNotApprovedByGuardian: true }
  })

  React.useEffect(() => {
    teamMembersData?.teamMembers && console.log('Lagmedlem', teamMembersData.teamMembers);
    setIsFirstTeammember(teamMembersData?.teamMembers?.length === 0);
  }, [teamMembersData?.teamMembers])

  return (
    <TableSection>

      {REACT_APP_BRAND !== 'SAENKELT' && (userData?.user?.superAdmin || possessionType === 'team') &&
        <div className="table-header">
          {/* <h2>{teamName?.name}</h2> */}
          <EditButton size="md" setEditing={() => setEditing('new')} >Lägg till lagmedlem</EditButton>
        </div>
      }

      {editing === 'new' &&
        <ModalAddTeammember
          teamId={teamId}
          isFirstTeammember={isFirstTeammember}
          setIsFirstTeammember={setIsFirstTeammember}
          setEditing={setEditing}
          refetchTeamMembers={refetchTeamMembers}
        />}

      {teamMembersData?.teamMembers?.length > 0 || possessionType === 'association'
        ? <table className="superAdmin teammembers-table">
          <thead>
            <tr>
              <td><SortButton className="BtnSort" name="firstname" sort={sort}>Lagmedlem<img className="sortBtn" src={Sort} /></SortButton></td>
              <td>Målsman/Ansvarig</td>
              {/* <td></td> */}
              <td></td>
              <td><SortButton name="soldBooklets" sort={sort}>Sålt<img className="sortBtn" src={Sort} /></SortButton></td>
            </tr>
          </thead>
          <tbody>
            {/* {editing === 'new' && (
              <Form key="new" content={{ teamId }} onComplete={() => setEditing('')} />
            )} */}
            {teamMembersLoading ? 'Laddar..' : (teamMembersError) ? 'Något gick fel vid hämtning av teamet' : (
              sortList(teamMembersData.teamMembers, sort)
                // .filter(teamMember => teamMember.isApprovedByGuardian === true)
                .map((obj) => (
                  editing === obj._id
                    ? <Form key={obj._id} content={obj} onComplete={() => setEditing('')} />
                    : <TeamMember key={obj._id} teamId={teamId} teammember={obj} user={userData?.user} setEditing={setEditing} refetchTeamMembers={refetchTeamMembers} moveOrdersFrom={moveOrdersFrom} setMoveOrdersFrom={setMoveOrdersFrom} />
                )))}

          </tbody>
        </table>
        : REACT_APP_BRAND !== 'SAENKELT' && teamMembersData?.teamMembers
          ? <table className="table-empty">
            <p>Klicka på 'Lägg till lagmedlem' för att lägga till dina lagmedlemmar.</p>
            <img src={ArrowBlack} alt="" />
          </table>
          : teamMembersData?.teamMembers && <table className="table-empty teammembers-table">
            <div>
              <p>Ojdå, här var det tomt!</p>
              <p>När säljarna har registrerat sig hittar du en lista med säljare här.</p>
              {/* <p>Låt säljarna skapa personliga webbshoppar och följ försäljningen på individnivå.</p> */}
            </div>
            <div>
              <b>Under fliken "Hem" hittar du information<br />om hur ni skapar personliga webbshoppar.</b>
            </div>
            <div>
              <img src={ImgShoppen} alt="" />
            </div>

          </table>
      }

    </TableSection>

  );
};


TeamMembersTable.propTypes = {
  //teamMembersQuery: PropTypes.object.isRequired,
  associationId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
};
export default TeamMembersTable;
/*
export default TeamMembersTable(
  graphql(getTeamMembersQuery, { name: 'teamMembersQuery' }),
)(TeamMembersTable);*/
