import React from 'react';
import PropTypes from 'prop-types';

function PageWrapperCorner({ type }) {
  return (
    <div className='ph-page-corner'
      style={{
        top: type.includes('top') ? 0 : 'auto',
        left: type.includes('left') ? 0 : 'auto',
        right: type.includes('right') ? 0 : 'auto',
        bottom: type.includes('bottom') ? 0 : 'auto',
        borderLeft: type.includes('left') ? 'none' : '1px solid #000',
        borderTop: type.includes('top') ? 'none' : '1px solid #000',
        borderBottom: type.includes('bottom') ? 'none' : '1px solid #000',
        borderRight: type.includes('right') ? 'none' : '1px solid #000',
        paddingLeft: type.includes('left') ? '0' : '1.4vw',
        paddingTop: type.includes('top') ? '0' : '1.4vw',
        paddingBottom: type.includes('bottom') ? '0' : '1.4vw',
        paddingRight: type.includes('right') ? '0' : '1.4vw',
      }}
    >
      <div className='ph-page-inner-corner'
        style={{
          borderLeft: type.includes('left') ? 'none' : '1px solid #000',
          borderTop: type.includes('top') ? 'none' : '1px solid #000',
          borderBottom: type.includes('bottom') ? 'none' : '1px solid #000',
          borderRight: type.includes('right') ? 'none' : '1px solid #000',
        }}
      />
    </div>
  )
}

PageWrapperCorner.propTypes = {
  type: PropTypes.string.isRequired,
}

export default PageWrapperCorner
