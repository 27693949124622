import React from 'react';
import Table from './UsersTable';
import { PageHeader } from '../../common/index';
import { useNavigationContext } from '../../context/NavigationContext';

function Users() {
  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  return (
    <div id="users">
      <PageHeader title="Hej, du!" text="Lorum ipsum" />
      <Table />
    </div>
  );
}

export default Users;
