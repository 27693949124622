import React from 'react'

export default function StepsInvitation({ step }) {
  return (
    <div className="step-counter">
      <ul>
        <li className={`active`}></li>
        <li className={`step-1 active`}>1</li>
        <li className={`step-2 ${step === 3 || step === 4 ? 'active' : ''}`}>2</li>
        <li className={`step-3 ${step === 4 || step === 5 ? 'active' : ''}`}>3</li>
        <li className={`${step === 4 ? 'active' : ''}`}></li>
      </ul>
    </div>
  )
}
