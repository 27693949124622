import React from 'react'
import { useState } from 'react';
import { Button } from '../../../common';
import { useNavigationContext } from '../../../context/NavigationContext';
import copy from '../../../img/feather-copy.svg'

function DashboardAssociationSend(props) {

  const [isCopied, setIsCopied] = useState(false);

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  const copyInvitationLink = (e) => {
    const text = document.querySelector('.teammembers-invite').innerText

    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);

    textarea.select()
    document.execCommand('copy');
    document.body.removeChild(textarea);

    // e.target.textContent = 'Kopierat!'
    setIsCopied(true);
  }

  return (
    <div id="teamshare">
      <div className="container">
        <div className="share-info">
          <h2>Utskick</h2>    
          <p>Vi rekommenderar att ni skickar ut detta meddelande till alla lagledare och lagmedlemmars målsmän innan ni börjar bjuda in lagledare för lagen. Kopiera texten nedan och skicka den där ni brukar skicka ut information till medlemmarna, t ex via er e-post.</p>
          <div className={`teammembers-invite ${isCopied ? 'copied' : ''}`}>
            <div>
              <b>Hej!</b>
              <p>Vi har valt att sälja rabatthäften via Så Enkelt för att få in de pengar som behövs för att driva föreningen.</p>
              <p className='m-0'>Lagledare kommer få en inbjudan till sin mail via saenkelt.se. Följa instruktionerna i mailet.</p>
              <p className='m-0'>Lagmedlemmars föräldrar kommer därefter få en inbjudan till sin mail via saenkelt.se. Följ instruktionen för att påbörja försäljningen.</p>
              <p>Obs! Vänligen kontrollera även spamfilter, ibland hamnar mailet där.</p>
            </div>
          </div>
          {/* <span className="italic">Skicka ut infon via era vanliga kanaler.</span> */}
          <Button className='primary lg' onClick={(e) => { copyInvitationLink(e) }}>
            <img src={copy} alt="" /> {isCopied ? 'Kopierat!' : 'Kopiera text'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DashboardAssociationSend
