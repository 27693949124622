import React from 'react'
import FontIcon from '../../common/FontIcon';

function CompanyCoupon({ coupon, userOwnsBooklet }) {
  const showAmount = userOwnsBooklet && coupon.availableByUser !== null && coupon?.availableByUser !== undefined && coupon.availableByUser > 1;
  return (
    <span className='company-coupon truncate'>
      <span className='font-icon'><FontIcon iconText='tag' /></span>
      <p>{coupon.discount}</p>
      <p className='truncate'>
        {`${showAmount ? `(${coupon?.availableByUser}) ` : ''}${coupon?.smallTitle}`}
      </p>
    </span>
  )
}

export default CompanyCoupon
