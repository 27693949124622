import React, { useState } from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import { getAssociationQuery } from '../../queries/queries';
import { LoadingTable, EditButton, sortList, PeriodById, TableSection } from '../../common/index';
import Form from './AssociationAgreementsForm';

function AssociationAgreementsTable(props) {
  const { associationQuery, associationId } = props;
  const [editing, setEditing] = useState('');
  const [sortBy, setSortBy] = useState('activeFrom');
  const [sortOrder, setSortOrder] = useState(1);
  const sort = { sortBy, sortOrder, setSortBy, setSortOrder };

  return (
    <TableSection>
      <div className="table-header">
        <h2>Avtal</h2>
        <EditButton size="sm add" setEditing={() => setEditing('new')}>Lägg till</EditButton>
      </div>
      <table>
        <thead>

          <tr>
            <td>Avtalsperiod</td>
          </tr>

        </thead>
        <tbody>

          {editing === 'new'
            && <Form key="new" content={{ associationId }} onComplete={() => setEditing('')} />}

          {!associationQuery.association ? <LoadingTable query={associationQuery} /> : (
            sortList(associationQuery.association.agreements, sort).map((obj) => (
              editing === obj._id
                ? <Form key={obj._id} content={{ ...obj, associationId }} onComplete={() => setEditing('')} />
                : (
                  <tr key={obj._id}>
                    <td>
                      <PeriodById id={obj.activeFrom} />
                      <span> - </span>
                      <PeriodById id={obj.activeTo} />
                    </td>
                    <td>
                      <EditButton size="xs" setEditing={() => setEditing(obj._id)}>Redigera</EditButton>
                    </td>
                  </tr>
                )
            )))}

        </tbody>
      </table>
    </TableSection>
  );
}

AssociationAgreementsTable.propTypes = {
  associationQuery: PropTypes.object.isRequired,
  associationId: PropTypes.string.isRequired,
};

export default compose(
  graphql(getAssociationQuery, { name: 'associationQuery' }),
)(AssociationAgreementsTable);
