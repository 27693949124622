import React, { useEffect, useState } from 'react';
import { addTeamMemberMutation } from '../../../queries/queries';
import { useMutation } from 'react-apollo';
import { inviteTeamMemberAdmin } from '../../../queries/invitations';
import { Button } from '../../../common/index';
import { inputValidator, useAutosize } from '../../../common/Helpers';
import close from '../../../img/deelo_close.svg';
import envelope from '../../../img/envelope.svg'
import removeCircle from '../../../img/remove-circle-icon.svg'
import { REACT_APP_BASE_URL } from '../../../env';

function ModalAddTeammember(props) {
  const { teamId, isFirstTeammember, setIsFirstTeammember, setEditing, refetchTeamMembers } = props;
  
  const { height } = useAutosize();
  const [overflow, setOverflow] = React.useState(false);
  const boxRef = React.useRef();
  const [step, setStep] = React.useState(0);
  const [errorVisible, setErrorVisible] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);

  const [teammember, setTeammember] = React.useState({
    firstname: '',
    lastname: '',
    adminEmails: ['']
  });

  const [validation, setValidation] = useState({...teammember, 
    firstname: inputValidator('default', ''),
    lastname: inputValidator('default', ''),
    adminEmails: inputValidator('emails', [''])
  });
  
  const [invitationTeammemberAdmin] = useMutation(inviteTeamMemberAdmin, {
    onCompleted: (res) => {
      console.log('Inbjudan skickad till målsman/ansvarig', res);
      setStep(2);
      setIsFirstTeammember(false);
    },
    onError: (err) => console.log(err)
  });
  const [addTeammember] = useMutation(addTeamMemberMutation, { variables: {
    teamId: teamId,
    firstname: teammember.firstname,
    lastname: teammember.lastname,
    // adminEmails: teammember.adminEmails
  }, 
  onCompleted: (result) => {
    teammember.adminEmails.forEach(adminEmail => {
      invitationTeammemberAdmin({variables: {
        email: adminEmail,
        teamMemberId: result.createTeamMember._id,
        portalUrlForEmails: `${REACT_APP_BASE_URL}/`
      }})
    })
  }});

  React.useEffect(() => {
    if(validation) {
      const validationFields = Object.values(validation);
      const isValid = validationFields.flat(1).every(field => field.valid === true);
      setIsValidForm(isValid);
    }
  }, [teammember, validation])

  const submitTeammember = (e) => {
    e.preventDefault();
    if(!isValidForm) {
      setErrorVisible(true);
      console.log('Ogiltiga fält')
      return null;
    }
    if(isFirstTeammember) {
      setStep(2);
    } else {
      addTeammember();
    }
  };

  // For autosize
  useEffect(() => {
    if(boxRef?.current?.clientHeight / height >= 0.9) {
      setOverflow(true);
      document.querySelector('body').style.cssText = 'overflow: hidden;';
    } else {
      setOverflow(false);
      document.querySelector('body').style.cssText = '';
    }
  }, [height, teammember?.adminEmails.length]);

  return (
    <div className={`open modal-form modal ${overflow ? 'autosize' : ''}`}>
      <div ref={boxRef} className={`box ${overflow ? 'autosize' : ''}`}>
        <div className="close" onClick={() => setEditing('')}><img src={close} alt="" /></div>
        
        {/* Step 1 - First teammember? */}
        {/* { step === 0 && isFirstTeammember ? <>
          <h3 className='mb-lg'>Vi ser att det är första gången du lägger till en lagmedlem</h3>
          <p>Om du är osäker på hur det fungerar när du lägger till en lagmedlem kan du kolla videon på FAQ-sidan</p>
          <Button className='primary lg arrow' onClick={() => setStep(step + 1)}>Lägg till lagmedlem</Button>
          <Button className="bg-none" onClick={() => setEditing('')}>Avbryt</Button>
        </> */}
        {
          // Gör om detta till else om steget ovanför ska visas
          (step === 0 || step === 1) && <>
        
            {/* <div> */}
            <h3 className='mb-lg'>Lägg till lagmedlemmar</h3>
            <form noValidate onSubmit={submitTeammember}>
              {/* Firstname */}
              <div className={`form-row`}>
                <label htmlFor={'firstname'}>Lagmedlemmens förnamn</label>
                <input type="text" name='firstname' onChange={(e) => {
                  setValidation({...validation, firstname: inputValidator('firstname', e.target.value)});
                  setTeammember({...teammember, firstname: e.target.value});
                }} autoComplete="off"/>
                {(!validation.firstname?.valid && errorVisible) && <small className="error">{validation.firstname?.error}</small>}
              </div>
              {/* Lastname */}
              <div className={`form-row`}>
                <label htmlFor={'lastname'}>Lagmedlemmens efternamn</label>
                <input type="text" name='lastname' onChange={(e) => {
                  setValidation({...validation, lastname: inputValidator('lastname', e.target.value)});
                  setTeammember({...teammember, lastname: e.target.value});
                }} autoComplete="off"/>
                {(!validation.lastname?.valid && errorVisible) && <small className="error">{validation.lastname?.error}</small>}
              </div>
              {/* Admin emails */}
              {teammember.adminEmails.map((admin, index) => (
                <div className={`form-row relative`} key={index}>
                  <label htmlFor={'email'}>Ansvarig persons e-post</label>
                  {index > 0 && <Button onClick={() => {
                    // Decrement admin email
                    const inputDecrement = [...teammember.adminEmails];
                    inputDecrement.splice(index, 1);
                    setTeammember({...teammember, adminEmails: inputDecrement})
                    setValidation({...validation, adminEmails: inputValidator('emails', inputDecrement)})
                  }} className='bg-none m-0 email-decrement'>
                    <img src={removeCircle} alt="" />  
                  </Button>}
                  {/* Admin email */}
                  <input type="email" name='email' onChange={(e) => {                            
                    const updateAdminEmail = [...teammember.adminEmails];
                    updateAdminEmail[index] = e.target.value;
                    setValidation({...validation, adminEmails: inputValidator('emails', updateAdminEmail)});
                    setTeammember({...teammember, adminEmails: updateAdminEmail});
                  }} autoComplete="off"/>
                  {(!validation.adminEmails[index]?.valid && errorVisible) && <small className="error">{validation.adminEmails[index]?.error}</small>}
                </div>
              ))}

              {/* Increment admin email */}
              <Button onClick={() => {
                setTeammember({...teammember, adminEmails: [...teammember.adminEmails, '']})
                const inputIncrement = [...teammember.adminEmails];
                inputIncrement.push('');
                setValidation({...validation, adminEmails: inputValidator('emails', inputIncrement)})
              }} className='bg-none m-0 email-increment'>Lägg till fler ansvariga +</Button>

              <button type="submit" className="lg primary">
                Lägg till och bjud in
                <img src={envelope} alt="" />
              </button>
            </form>
          </>
        }

        {/* Step 2 - First teammember? */}
        { step === 2 && isFirstTeammember 
          ? <>
            <h3>Är du säker på att du vill du skicka inbjudan?</h3>
            <p><b>OBS!</b> Om ni inte redan gjort det, rekommenderar vi starkt att göra utskicket till lagmedlemmarnas målsmän innan ni bjuder in dem (se fliken Utskick).</p>
            <Button className='secondary lg' onClick={addTeammember}>Ja, skicka inbjudan!</Button>
            <Button className='secondary lg' onClick={() => {
              setStep(0);
              setEditing('');
            }}>Nej, avbryt!</Button>
          </>

          // Else finished!
          : step === 2 && <>
            <h3>Lagmedlem tillagd!</h3>
            <p>Nu måste ansvarig godkänna att lagmedlemmen får visas i webbshoppen.</p>
            
            <div className="teammember-details">
              <span>Lagmedlem:</span>
              <p>{teammember.firstname} {teammember.lastname}</p>
              <span>Ansvarig:</span>
              {teammember.adminEmails.map((email, index) => (
                <p key={index} className='guardian m-0'>{email}</p>
              ))}      
            </div>
            
            {/* <p className='m-0'>har fått en inbjudan på sin mail.</p> */}
            <Button className='primary lg' onClick={() => setStep(0)}>Lägg till en lagmedlem till +</Button>
            <Button className='bg-none lg arrow' onClick={() => {
              setStep(0);
              setEditing('');
              refetchTeamMembers();
            }}>Jag är klar</Button>
          </>
        }
      </div>
    </div>

  // </div>
  );
}

export default ModalAddTeammember;
