import React from 'react'


function ListNames(props) {
  const {admins, emails, title, isInactive} = props;
  return (
    <ul className={`admins ${isInactive ? 'inactive' : ''} ${admins?.length > 0 ? 'capitalized' : ''}`}>
      {/* Namn */}
      {admins?.length > 0 && <>
        {title && <small>{title}:&nbsp;</small>}
        {admins.map((item, i) => (
          <li key={i} className="admin">
            {item?.firstname !== '' && item?.lastname !== ''
              ? <>{item.firstname.trim()}&nbsp;{item.lastname.trim()}{admins.length > 0 && i !== admins.length - 1 ? <>,&nbsp;</> : ''}</>
              : <>{item.email}</>
            }
          </li>
        ))}
      </>
      }
      {/* Emails */}
      {emails?.length > 0 && <> 
        {title && <small>{title}:&nbsp;</small>}
        {emails.map((email, i) => (
          <li key={i} className="admin">
            {email !== ''
              ? <>{email.trim()}{emails.length > 0 && i !== emails.length - 1 ? <>,&nbsp;</> : ''}</>
              : <>Okänt namn</>
            }
          </li>
        ))}
      </>
      }
    </ul>
  )
}

export default ListNames