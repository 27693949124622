import React, { useEffect, useState } from 'react';

const ZoneContext = React.createContext();

export const ZoneProvider = (props) => {
  const [zone, setZone] = useState(null);
  const [adminableBooklets, setAdminableBooklets] = useState([]);

  // Booklet filters
  const [showAllBooklets, setShowAllBooklets] = useState(false);
  const [filteredBooklets, setFilteredBooklets] = useState([]);
  const [hiddenBooklets, setHiddenBooklets] = useState([]);

  // Companies filter
  const [companiesFilter, setCompaniesFilter] = useState({
    selectedBookletId: '',
    hiddenCompanies: []
  });

  /**
   * Sets filteredBooklets with the necessary isAdminable property
   * It also handles the filtering of booklets based on zone, validFrom, showAllBooklets and hiddenBooklets
   */
  useEffect(() => {
    const today = new Date();
    const addAdminableProperty = (booklets) => booklets.map(booklet => ({ ...booklet, isAdminable: true }));
    const filterByZoneAndValidFrom = (booklets) => booklets.filter(booklet => booklet.zoneId === zone?._id && new Date(booklet.validFrom) > today);

    // Ensure adminableBooklets is an array
    const adminableBookletsArray = Array.isArray(adminableBooklets) ? adminableBooklets : [];
    const adminableBookletsWithProp = addAdminableProperty(adminableBookletsArray);

    let newFilteredBooklets;
    if (showAllBooklets) {
      newFilteredBooklets = [...adminableBookletsWithProp];
    } else {
      newFilteredBooklets = [...filterByZoneAndValidFrom(adminableBookletsWithProp)];
    }

    // Apply hidden booklets filter
    newFilteredBooklets = newFilteredBooklets.filter(booklet => !hiddenBooklets.includes(booklet._id));

    setFilteredBooklets(newFilteredBooklets);
  }, [adminableBooklets, zone, showAllBooklets, hiddenBooklets]);

  const context = {
    zone,
    adminableBooklets,
    filteredBooklets,
    companiesFilter,
    showAllBooklets,
    hiddenBooklets,
    setZone,
    setAdminableBooklets,
    setCompaniesFilter,
    setShowAllBooklets,
    setFilteredBooklets,
    setHiddenBooklets,
  };

  return (
    <ZoneContext.Provider value={context}>
      {props.children}
    </ZoneContext.Provider>
  );
};

export const useZoneContext = () => React.useContext(ZoneContext);
