import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { addCouponToCompanyAgreementMutation, updateCouponInCompanyAgreementMutation, getCompanyQuery } from '../../queries/queries';
import { Form, Field, Group } from '../../common/index';

function CompanyCouponsForm(props) {
  const { content, onComplete } = props;
  const [sContent, setSContent] = useState(content);

  const ss = (c) => setSContent({ ...sContent, ...c });

  return (
    <tr key={content ? content._id : 'new'} className="open">
      <td colSpan="100">
        <Form sContent={sContent} parent="companyId" get={getCompanyQuery} add={addCouponToCompanyAgreementMutation} update={updateCouponInCompanyAgreementMutation} onComplete={onComplete}>
          <Group title="Inställningar">
            <Field type="selectYesOrNo" name="active" title="Aktiv" value={sContent && sContent.active} selectDataInfo={{ loading: false }} selectData={[{ _id: true, name: 'Ja' }, { _id: false, name: 'Nej' }]} onChange={ss} />
            <Field type="image" name="imageURL" title="Bild" value={sContent && sContent.imageURL} onChange={ss} />
            <Field type="text" name="code" title="Rabattkod" value={sContent && sContent.code} onChange={ss} />
            <Field type="text" name="productLink" title="Produkt-URL" value={sContent && sContent.productLink} onChange={ss} />
          </Group>
          <Group title="Texter">
            <Field type="text" req name="discount" title="Rabatt" value={sContent && sContent.discount} onChange={ss} />
            <Field type="text" req name="smallTitle" title="Liten titel" value={sContent && sContent.smallTitle} onChange={ss} />
            <Field type="text" req x2 name="terms" title="Villkor titel" value={sContent && sContent.terms} onChange={ss} />
            <Field type="text" req x2 name="description" title="Beskrivning" value={sContent && sContent.description} onChange={ss} />
          </Group>
        </Form>
      </td>
    </tr>
  );
}

CompanyCouponsForm.propTypes = {
  content: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default CompanyCouponsForm;
