import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { addAgreementToCompanyMutation, updateCompanyAgreementMutation, getCompanyQuery, getPeriodsQuery } from '../../queries/queries';
import { Form, Field, Group } from '../../common/index';

function CompanyAgreementsForm(props) {
  const { content, onComplete, periods } = props;
  const [sContent, setSContent] = useState(content);

  const ss = (c) => setSContent({ ...sContent, ...c });

  return (
    <tr key={content ? content._id : 'new'} className="open">
      <td colSpan="100">
        <Form sContent={sContent} parent="companyId" get={getCompanyQuery} add={addAgreementToCompanyMutation} update={updateCompanyAgreementMutation} onComplete={onComplete}>
          <Group title="Inställningar">
            <Field type="selectPeriod" req name="activeFrom" title="Från period" value={sContent && sContent.activeFrom} selectDataInfo={periods} selectData={periods.periods} onChange={ss} />
            <Field type="selectPeriod" req name="activeTo" title="Till och med period" value={sContent && sContent.activeTo} selectDataInfo={periods} selectData={periods.periods} onChange={ss} />
          </Group>
        </Form>
      </td>
    </tr>
  );
}

CompanyAgreementsForm.propTypes = {
  periods: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default compose(
  graphql(getPeriodsQuery, { name: 'periods' }),
)(CompanyAgreementsForm);
