import React, { useEffect } from 'react';
import Table from './AssociationsTable';
import { useNavigationContext } from '../../context/NavigationContext';

function Associations(props) {
  const { zone: zoneId } = props.match.params;

  const { setNavigationActivated } = useNavigationContext();
  useEffect(() => {
    setNavigationActivated(true);
  }, []);

  return (
    <div id="associations">
      {/* <PageHeader>
        <h1>Föreningar</h1>
        <ul>
          <li>Här kan du lägga till och hantera föreningar</li>
        </ul>
      </PageHeader> */}
      <Table zoneId={zoneId} />
    </div>
  );
}

export default Associations;
