import React, { useState } from 'react';

const PreviewContext = React.createContext();

export const PreviewProvider = (props) => {
  const [previewType, setPreviewType] = useState('company');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [companyToPreview, setCompanyToPreview] = useState();

  const context = {
    previewType,
    setPreviewType,
    isPreviewOpen,
    setIsPreviewOpen,
    companyToPreview,
    setCompanyToPreview,
  };

  return (
    <PreviewContext.Provider value={context}>
      {props.children}
    </PreviewContext.Provider>
  );

};

export const usePreviewContext = () => React.useContext(PreviewContext);
