import React from 'react'
import FontIcon from '../../common/FontIcon'
import { REACT_APP_IMAGE_BASE_URL } from '../../env';

function CompanyModalCoupon({ coupon, userOwnsBooklet }) {
  const showAmount = userOwnsBooklet && coupon.availableByUser !== null && coupon?.availableByUser !== undefined && coupon.availableByUser > 1;
  return (
    <div key={coupon._id} className="box booklet-company-coupon">
      {coupon?.imageURL && <img src={REACT_APP_IMAGE_BASE_URL + coupon?.imageURL} alt={coupon?.smallTitle} />}
      <div className="coupon">
        <div className='details'>

          <h2>{coupon.discount}</h2>
          <p className="smallTitle">
            {`${showAmount ? `(${coupon?.availableByUser}) ` : ''}${coupon?.smallTitle}`}
          </p>
          <p>{coupon?.description}</p>
          <p>{coupon?.terms}</p>
        </div>

        <span className='font-icon'>
          <FontIcon iconText='tag' />
        </span>
      </div>

    </div>
  )
}

export default CompanyModalCoupon
