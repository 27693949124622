import React from 'react'
import { REACT_APP_IMAGE_BASE_URL } from '../../env'
import FontIcon from '../../common/FontIcon'
import { IoChevronForwardSharp } from 'react-icons/io5'

function CompanyHeader({ company, companyType }) {
  return (
    <div className="booklet-company-header">
      <img className="logo" src={REACT_APP_IMAGE_BASE_URL + company?.logoURL} alt='logo' />
      <div className="company-details">
        <h1>{company?.name}</h1>
        {company?.phone && <p className='phone'>{company?.phone}</p>}
        {companyType?.name === 'butik' && company?.websiteURL &&
          <a href={company?.websiteURL} target="_blank" rel="noreferrer" className='flex items-center'>
            Till hemsidan
            <IoChevronForwardSharp size={14}/>
          </a>
        }
        {companyType?.name === 'butik'
          ? <p className='truncate'>
            {company?.visitAddress.streetAddress &&
              <>
                <span className='font-icon'>
                  <FontIcon iconText='butik' />
                </span>
                {company?.visitAddress.streetAddress}
              </>
            }
          </p>
          : company?.websiteURL && <a href={company?.websiteURL} target="_blank" rel="noreferrer" className='truncate'><span className='font-icon'><FontIcon iconText='online' /></span>{company?.websiteURL}</a>
        }
      </div>
    </div>
  )
}

export default CompanyHeader
