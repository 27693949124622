import React from 'react';
import { Link } from 'react-router-dom';
import { IoChevronForwardSharp } from 'react-icons/io5';
import ShopSaenkelt from '../../img/shop-saenkelt.svg';

function PersonalWebshopsModal({ team, teamSlug, association, teamMembers, setShowPersonalShops }) {
  return (
    <div className="personal-webshop-modal modal">
      <div className="relative box md p-0">
        <div className="top flex flex-col gap-2">
          <img src={ShopSaenkelt} alt="" />
          <h2>Välkommen till<br />webbshoppen för</h2>
          <div>
            <h3>{team.name}<br />{association.name}</h3>
          </div>
        </div>
        <div className='personal-webshops'>
          <div className='title'>
            <span>Vem vill du köpa av?</span>
          </div>
          <div onClick={() => setShowPersonalShops(false)} className='option default'>
            <span>Laget</span>
            <IoChevronForwardSharp size={20}/>
          </div>
          <div className="teammembers">
            {teamMembers.map(teammember =>
              <Link to={teamSlug + '/' + teammember.slug} key={teammember._id}>
                <div className='option'>
                  <span>{teammember.firstname} {teammember.lastname}</span>
                  <IoChevronForwardSharp size={20}/>
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
};

export default PersonalWebshopsModal;
