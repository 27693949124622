import React, { useState } from 'react';
import { graphql, useQuery } from 'react-apollo';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import { getTeamsQuery, getMyUserQuery } from '../../../queries/queries';
import { LoadingTable, TableSection, EditButton, sortList } from '../../../common/index';
import Form from './TeamsForm';
import ModalAddTeam from './ModalAddTeam';
import Team from './Team';
import ArrowBlack from '../../../img/arrow-black.svg'


function TeamsTable(props) {
  const { teamsQuery, associationId, associationData, period } = props;
  const [editing, setEditing] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState(1);
  const sort = { sortBy, sortOrder, setSortBy, setSortOrder };
  const [isFirstTeam, setIsFirstTeam] = React.useState()
  const { data: userData } = useQuery(getMyUserQuery)

  // const isAdminActivated = (team, adminId) => {
  //   const isActivated = team?.adminInvitations?.find(inv => inv.userIdActivated === adminId)
  //   return isActivated;
  // }
  // const { data: agreementPeriods } = useQuery(ongoingPeriodsInAssociationQuery, { 
  //   variables: { 
  //     associationId 
  //   } 
  // });
  // React.useEffect(() => {
  //   agreementPeriods && setPeriod(agreementPeriods.periods.currentPeriod)
  // }, [agreementPeriods]);

  React.useEffect(() => {
    teamsQuery?.teams && console.log('Lag', teamsQuery.teams);
    setIsFirstTeam(teamsQuery?.teams?.length === 0)
  }, [teamsQuery?.teams])

  return (
    <TableSection>
      <div className="table-header">
        <h2>{associationData?.association?.name}</h2>
        <EditButton size="md" setEditing={() => setEditing('new')}>Lägg till lag</EditButton>
      </div>

      {editing === 'new' &&
        <ModalAddTeam
          associationId={associationId}
          isFirstTeam={isFirstTeam}
          setIsFirstTeam={setIsFirstTeam}
          setEditing={setEditing}
          refetchTeam={teamsQuery.refetch}
        />
      }
      {teamsQuery?.teams?.length > 0
        ? <table className='teams-table'>
          <thead className='relative'>
            <tr>
              <td>Lag</td>
              <td>Lagledare</td>
              <td>Lagmedlemmar/Sålt</td>
              {/* <td></td> */}
            </tr>
          </thead>
          <tbody>
            {/* {editing === 'new'
              && <Form key="new" content={{ associationId }} onComplete={() => setEditing('')} />} */}
            {!teamsQuery.teams ? <LoadingTable query={teamsQuery} /> : (
              sortList(teamsQuery.teams, sort).map((obj) => (
                editing === obj._id
                  ? <Form key={obj._id} content={obj} onComplete={() => setEditing('')} teamEdit />
                  : <Team key={obj._id} user={userData?.user} team={obj} associationId={associationId} setEditing={setEditing} period={period} />
              )))}
          </tbody>
        </table>
        : teamsQuery?.teams &&
        <div className='table-empty'>
          <p>Klicka på 'Lägg till lag' för att lägga till ditt första lag.</p>
          <img src={ArrowBlack} alt="" />
        </div>
      }


    </TableSection>
  );
}

TeamsTable.propTypes = {
  teamsQuery: PropTypes.object.isRequired,
  associationId: PropTypes.string.isRequired,
};

export default compose(
  graphql(getTeamsQuery, { name: 'teamsQuery' }),
)(TeamsTable);
