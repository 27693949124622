import React from 'react';

import Table from './AssociationAgreementsTable';

import { PageHeader } from '../../common/index';
import { useNavigationContext } from '../../context/NavigationContext';

function AssociationAgreements(props) {
  const { association: associationId } = props.match.params;

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  return (
    <div id="association-agreements">
      <PageHeader title="Hej, du!" text="Lorum ipsum">
        <h1>Föreningsavtal</h1>
        <ul>
          <li>Här kan du lägga till och hantera föreningens avtal</li>
        </ul>
      </PageHeader>
      <Table associationId={associationId} />
    </div>
  );
}

export default AssociationAgreements;
