import React from 'react'
import PageWrapperCorner from './PageWrapperCorner'

const PageWrapper = ({ children, className, bgColor }) => {
  return (
    <div className={`ph-page-wrapper `}>
      <PageWrapperCorner type='top-left' />
      <PageWrapperCorner type='top-right' />
      <PageWrapperCorner type='bottom-left' />
      <PageWrapperCorner type='bottom-right' />
      <div className='ph-page-content'>
        <div className={`ph-page-inner-content ${className}`}
          style={{ backgroundColor: bgColor }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default PageWrapper
