import React from 'react'
import { REACT_APP_IMAGE_BASE_URL } from '../../env';

function BookletCard({ booklet }) {
  //const { data: bookletData } = useQuery(getBookletQuery, { variables: { _id: booklet?._id } });

  return (
    <div className="box-card">
      <div className="top">
        <div className="bg left" style={{ backgroundColor: booklet?.colorLeft }}></div>
        <div className="bg right" style={{ backgroundColor: booklet?.colorRight }}></div>
        {<div className="img-container">
          {booklet?.logoURL && <img src={REACT_APP_IMAGE_BASE_URL + booklet?.logoURL} alt="" />}
        </div>}
      </div>
      <div className="bottom-row">
        <div>
          <div><b>{booklet?.title}</b></div>
          <div><b>{booklet?.price ? booklet.price : 0} kr</b></div>
          <small>Häfte aktiveras i iOS/Android</small>

          {(booklet?.validFrom && booklet?.validTo) && <small>Giltig {new Date(booklet.validFrom).toLocaleDateString().replaceAll('/', '-')} - {new Date(booklet.validTo).toLocaleDateString().replaceAll('/', '-')}</small>}
        </div>
      </div>
    </div>
  )
}

export default BookletCard
