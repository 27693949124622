import PropTypes from 'prop-types';

function errorMessage(props) {
  const { message, level } = props;
  alert('Error', message, level);
  return null;
}

errorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  level: PropTypes.string,
};

errorMessage.defaultProps = {
  message: null,
};

export { errorMessage };
