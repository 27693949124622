import React from 'react';

function Support({type}) {
  return (
    <div className='support'>
      {type} support
    </div>
  )
}

export default Support;
