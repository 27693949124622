import gql from 'graphql-tag';

// QUERIES

const getTeamsSafeQuery = gql`
  query teamsInAssociation($associationId: ObjectId!) {
    teams: teamsInAssociation(associationId: $associationId) {
      _id
      associationId
      name
      slug
    }
  }
`;

const getTeamsQuery = gql`
  query teamsInAssociation($associationId: ObjectId!) {
    teams: teamsInAssociation(associationId: $associationId) {
      _id
      associationId
      name
      adminEmails
      decryptedAdminToken
      adminUsers {
        _id
        email
        firstname
        lastname
      }
      adminInvitations {
        sentToEmail
        userIdActivated
      }
    }
  }
`;

const getTeamQuery = gql`
  query ($teamId: ObjectId!) {
    team: team(teamId: $teamId) {
      _id
      name
      decryptedTeamMemberCreationToken
      associationSlug
      slug
      adminEmails
      adminUsers {
        _id
        firstname
        lastname
        email
      }
      adminInvitations {
        sentToEmail
        userIdActivated
      }
    }
  }
`

const getTeamNameQuery = gql`
  query ($teamId: ObjectId!) {
    name: teamName(teamId: $teamId)
  }
`;

const getTeamBySlug = gql`
  query ($slug: String!, $associationSlug: String!) {
    team: teamBySlug(slug: $slug, associationSlug: $associationSlug) {
      _id
      name
      associationId
    }
  }
`;

const statOrdersByTeamQuery = gql`
  query ($teamId: ObjectId!, $fromDate: DateTime, $toDate: DateTime) {
    orders: statOrdersByTeam(teamId: $teamId, fromDate: $fromDate, toDate: $toDate) {
      #periods {
        #period
        #amount
        #number
      #}
      totalNumber
      #totalAmount
    }
  }
`;

const statOrdersByTeamMemberQuery = gql`
  query ($teamMemberId: ObjectId!, $fromDate: DateTime, $toDate: DateTime) {
    orders: statOrdersByTeamMember(teamMemberId: $teamMemberId, fromDate: $fromDate, toDate: $toDate) {
      #periods {
        #period
        #amount
        #number
      #}
      totalNumber
      #totalAmount
    }
  }
`;

const statTeamTopTeamMembersQuery = gql`
  query ($teamId: ObjectId!, $fromDate: DateTime, $toDate: DateTime) {
    teamMembers: statTeamTopTeamMembers(teamId: $teamId, fromDate: $fromDate, toDate: $toDate) {
      teamMembers {
        teamMember {
          _id
          firstname
          lastname
        }
        total
      }
    }
  }
`;

// MUTATIONS

const addTeamMutation = gql`
  mutation(
    $associationId: ObjectId!,
    $name: String!,
    $adminEmails: [String!]
    ) {
    createTeam(
      associationId: $associationId,
      name: $name,
      adminEmails: $adminEmails,
      ) {
        _id
      }
    }
`;

const updateTeamMutation = gql`
  mutation(
    $_id: ObjectId!
    $name: String
    $adminEmails: [String!]
    ) {
    updateTeam(
      _id: $_id,
      name: $name,
      adminEmails: $adminEmails,
      ) {
        _id
    }
  }
`;

export {
  getTeamsSafeQuery,
  getTeamsQuery,
  getTeamQuery,
  getTeamNameQuery,
  getTeamBySlug,
  statOrdersByTeamQuery,
  statOrdersByTeamMemberQuery,
  statTeamTopTeamMembersQuery,
  addTeamMutation,
  updateTeamMutation,
};
