import React, { useEffect } from 'react'
import { useNavigationContext } from '../../context/NavigationContext';
import Table from './BookletsTable';

const Booklets = (props) => {
  const { zone: zoneId } = props.match.params;

  const { setNavigationActivated } = useNavigationContext();
  useEffect(() => {
    setNavigationActivated(true);
  }, []);

  return (
    <div id="booklets">
      <Table zoneId={zoneId} />
    </div>
  )
}

export default Booklets
