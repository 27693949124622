import React, { useEffect, useState } from 'react'
import { Button, inputValidator } from '../../common';

function InvitationTeammember({ setStep, teammember, setTeammember }) {

  const [isValidForm, setIsValidForm] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [validation, setValidation] = useState({
    firstname: inputValidator('firstname', teammember.firstname),
    lastname: inputValidator('lastname', teammember.lastname),
  });

  useEffect(() => {
    if (validation) {
      const fields = Object.values(validation);
      const isValid = fields.every(field => field.valid === true);
      setIsValidForm(isValid);
    }
  }, [teammember])

  const teammemberSubmit = (e) => {
    e.preventDefault();

    if (!isValidForm) {
      setErrorVisible(true);
      console.log('Ogiltiga fält')
      return null;
    }

    setStep(4);
  }

  return (
    <>
      <h2 className="mb-lg">Fyll i säljarens uppgifter</h2>
      <form noValidate onSubmit={teammemberSubmit}>
        <div className="row">
          <label htmlFor="firstNameInput">Förnamn</label>
          <input type="text" id="firstNameInput" maxLength={30} onChange={(e) => {
            setValidation({ ...validation, firstname: inputValidator('firstname', e.target.value) });
            setTeammember({ ...teammember, firstname: e.target.value })
          }} value={teammember.firstname} autoComplete="off" />
          {(!validation.firstname?.valid && errorVisible) && <small className="error">{validation.firstname?.error}</small>}
        </div>
        <div className="row">
          <label htmlFor="lastNameInput">Efternamn</label>
          <input type="text" id="lastNameInput" onChange={(e) => {
            setValidation({ ...validation, lastname: inputValidator('lastname', e.target.value) });
            setTeammember({ ...teammember, lastname: e.target.value })
          }} value={teammember.lastname} autoComplete="off" />
          {(!validation.lastname?.valid && errorVisible) && <small className="error">{validation.lastname?.error}</small>}
        </div>
        <Button type="submit" className='primary lg arrow'>Gå vidare</Button>
      </form>
    </>
  )
}

export default InvitationTeammember
