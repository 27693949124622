import React from 'react'

function InfoShape({ title, color, x, y, height, width, isArrowReversed, arrowX, arrowY, rotate }) {
  return (
    <div className='info-shape' style={{
      top: y,
      left: x,
      height,
      width,
    }}>
      <div className="relative flex items-center justify-center">
        <svg viewBox="0 0 178.298 177.296" className='svg-shape'>
          <path
            data-name="Path 2535"
            d="M79.473 5.061a11.779 11.779 0 0 1 19.351 0l.032.046a11.779 11.779 0 0 0 15.782 3.354l.053-.032a11.779 11.779 0 0 1 17.679 7.874l.006.033a11.779 11.779 0 0 0 13.059 9.492l.048-.006a11.779 11.779 0 0 1 12.951 14.389v.009a11.779 11.779 0 0 0 8.075 13.985l.022.007a11.779 11.779 0 0 1 5.982 18.417 11.779 11.779 0 0 0 1.689 16.064 11.779 11.779 0 0 1-2.023 19.259l-.01.005a11.779 11.779 0 0 0-4.991 15.361 11.779 11.779 0 0 1-9.678 16.769h-.036a11.779 11.779 0 0 0-10.8 12v.02a11.779 11.779 0 0 1-15.657 11.38l-.054-.019a11.779 11.779 0 0 0-14.746 6.56l-.018.042a11.779 11.779 0 0 1-18.928 4.025l-.045-.042a11.779 11.779 0 0 0-16.134 0l-.045.042a11.779 11.779 0 0 1-18.928-4.025l-.018-.042a11.779 11.779 0 0 0-14.742-6.563l-.054.019a11.779 11.779 0 0 1-15.657-11.38v-.02a11.779 11.779 0 0 0-10.8-12h-.036a11.779 11.779 0 0 1-9.678-16.769 11.779 11.779 0 0 0-4.991-15.361l-.01-.005A11.779 11.779 0 0 1 4.1 88.69a11.779 11.779 0 0 0 1.689-16.064 11.779 11.779 0 0 1 5.982-18.416l.022-.007a11.779 11.779 0 0 0 8.075-13.985v-.009a11.779 11.779 0 0 1 12.946-14.392l.048.006a11.779 11.779 0 0 0 13.058-9.487l.006-.033a11.779 11.779 0 0 1 17.68-7.875l.053.032a11.779 11.779 0 0 0 15.782-3.354Z"
            fill={color}
          />
        </svg>
        <h6>{title}</h6>
      </div>
      <svg
        viewBox="0 0 99.877 99.877"
        width="45%"
        height="45%"
        className={`svg-arrow`}
        style={{
          transform: `scaleX(${isArrowReversed ? -1 : 1}) rotate(${rotate || 0}deg)`,
          top: arrowY,
          left: arrowX,
        }}
      >
        <defs>
          <clipPath id="a">
            <rect data-name="Rectangle 1661" width="34.498" height="106.75" fill="#222" />
          </clipPath>
        </defs>
        <g data-name="Group 1757" transform="rotate(-45 29.44616282 12.197)">
          <g data-name="Group 1756" clip-path="url(#a)">
            <path
              data-name="Path 2506"
              d="m23.276 100.561-10.752-2.41a4.726 4.726 0 0 0 3.317 4.241 139.926 139.926 0 0 0 14.881 4.249c2.886.609 4.4-1.406 3.532-4.207-1.453-4.675-3.148-9.275-4.746-13.9-.094-.271-.167-.636-.371-.764-.655-.41-1.365-.729-2.054-1.085-.291.6-.888 1.229-.817 1.777.24 1.848.715 3.666 1.105 5.493a6.345 6.345 0 0 1 .04 3.331c-1.1-1-2.253-1.957-3.3-3.011a67 67 0 0 1-11.384-80.166c1.862-3.407 4.145-6.583 6.212-9.878.721-1.147 1.38-2.334 2.068-3.5l-.64-.728c-.818.3-1.867.38-2.414.945a57.936 57.936 0 0 0-5.389 6.177c-16.19 22.337-16.82 53.36-1.389 77.358 2.943 4.577 6.729 8.613 10.156 12.876.705.877 1.538 1.651 2.312 2.472l-.368.731"
              fill="#222"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default InfoShape
