import React from 'react';
import { Button } from '../../common';
import { FaCheck } from 'react-icons/fa';

function InvitationConfirm({ userData, teammember, submit, setStep }) {

  const [isAgreementChecked, setIsAgreementChecked] = React.useState(false);

  return (
    <React.Fragment>
      <form onSubmit={submit} className="confirm-details">
        <h2>Kontrollera uppgifterna</h2>
        <div className="my-xl">
          <span>Målsmans kontaktuppgifter:</span>
          <ul className="account">
            <li className="mb">{userData?.user.email}</li>
          </ul>
          <div className="teammember">
            <span>Följ försäljningen för:</span>
            <ul>
              <li>{teammember.firstname} {teammember.lastname}</li>
            </ul>
          </div>
          <div className="agreement">
            <div className="relative">
              <input required type="checkbox" name="check" id="check" onChange={(e) => setIsAgreementChecked(e.target.checked)} />
              {isAgreementChecked &&
                <FaCheck className='checkbox' color='#fff' size={16} />
              }
            </div>
            <label htmlFor="check">
              Jag godkänner <a href="https://saenkelt.se/allmanna-villkor-integritetspolicy/" target='_blank'><span className="underline">villkoren</span></a>
            </label>
          </div>
        </div>
        <Button type={'submit'} className='primary lg btn-block arrow mb'>Skapa webbshopen</Button>
      </form>
      <Button onClick={() => setStep(3)} className='secondary lg btn-block mb'>Gå tillbaka</Button>
    </React.Fragment>
  )
}

export default InvitationConfirm
