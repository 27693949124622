import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-apollo';
import { Button, inputValidator } from '../../common';
import { forgotPasswordMutation, loginMutation, registerMutation, userExistsQuery } from '../../queries/user';
import passwordeye from '../../img/eye-solid.svg';

function InvitationGuardian({ updateAuthTokenCallback, setStep }) {

  const [register, { loading: registerLoading, error: registerError }] = useMutation(registerMutation);
  const [login, { loading: loginLoading, error: loginError }] = useMutation(loginMutation);

  const [forgotPassword, { loading: forgotPasswordLoading, error: forgotPasswordError }] = useMutation(forgotPasswordMutation);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoginForm, setIsLoginForm] = useState(false);
  const [resetPassword, setResetPassword] = useState();
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const [isValidForm, setIsValidForm] = useState(false);
  const [guardian, setGuardian] = useState({
    email: '',
    firstname: '',
    lastname: '',
    password: '',
    longExpiry: false
  });

  const { data: userCheck, refetch: checkUserExists } = useQuery(userExistsQuery, { variables: { email: guardian.email } });
  const [userExistError, setUserExistError] = useState(false);

  const [errorVisible, setErrorVisible] = useState(false);
  const [errorNonMatchingEmail, setErrorNonMatchingEmail] = useState(false);
  const [emailVerify, setEmailVerify] = useState('');
  const [validation, setValidation] = useState({
    email: inputValidator('email', ''),
    firstname: inputValidator('firstname', ''),
    lastname: inputValidator('lastname', ''),
    password: inputValidator('password', '')
  });

  useEffect(() => {
    if (validation) {
      const fields = Object.values(validation);
      const isValid = fields.every(field => field.valid === true);
      setIsValidForm(isValid);
    }
  }, [guardian])

  const guardianSubmit = (e) => {
    e.preventDefault();

    if (registerLoading) {
      console.log('Loading. Double submit canceled.')
      return null;
    }

    setErrorNonMatchingEmail(false);
    if (guardian.email !== emailVerify) {
      setErrorVisible(true);
      setErrorNonMatchingEmail(true);
      console.log('Email matchar inte')
      return null;
    }
    if (!isValidForm) {
      setErrorVisible(true);
      console.log('Ogiltiga fält')
      return null;
    }

    checkUserExists().then((res) => {
      console.log(res);
      if (res.data.userExists) {
        // setErrorVisible(true);
        setUserExistError(true);
        return null;
      }
      else {
        register({
          variables: {
            email: guardian.email,
            password: guardian.password,
            firstname: guardian.firstname,
            lastname: guardian.lastname,
            longExpiry: false,
          }
        })
          .then(result => {
            updateAuthTokenCallback(result && result.data.createUser);
            setStep(3);
            sessionStorage.setItem('account_set', true);
          })
          .catch(useMutationError => { console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors) });
      }
    }).catch(err => console.log(err));
  }

  const loginSubmit = (e) => {
    e.preventDefault();
    if (loginLoading) {
      console.log('Loading. Double submit canceled.')
      return null;
    }
    if (guardian.email === '' || guardian.password === '') {
      setErrorVisible(true);
      return null;
    }
    login({ variables: { email: guardian.email, password: guardian.password, longExpiry: false } })
      .then(result => {
        if (result.data.login) {
          updateAuthTokenCallback(result && result.data.login);
          sessionStorage.setItem('account_set', true);
          setStep(3);
        }
      })
      .catch(useMutationError => { console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors) });
  }

  const forgotPasswordSubmit = (e) => {
    e.preventDefault();
    if (forgotPasswordLoading) {
      console.log('Loading. Double submit canceled.')
      return null;
    };
    const vars = {
      variables: {
        email: guardian.email,
      },
    };

    forgotPassword(vars)
      .then(result => { console.log('result', result); setShowSuccessMessage(true); })
      .catch(useMutationError => { console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors) });
  }

  return (
    <>
      {resetPassword ? <>
        <h2 className='mb-lg'>Glömt lösenord?</h2>
        <form noValidate onSubmit={forgotPasswordSubmit}>
          <div className="row">
            {!showSuccessMessage && <>
              <label htmlFor="emailInput">E-post</label>
              <input type="email" id="emailInput" onChange={(e) => {
                setGuardian({ ...guardian, email: e.target.value })
              }} value={guardian.email} autoComplete="off" />
            </>}
          </div>
          {forgotPasswordError && forgotPasswordError.graphQLErrors.map(({ message }, i) => (
            <p className="error" key={i}>{message}</p>
          ))}
          {!showSuccessMessage && <div className='mb-lg'><Button className="primary lg" loading={forgotPasswordLoading} type="submit">Återställ</Button></div>}
          {showSuccessMessage && <>
            <p>Ett e-postmeddelande är skickat till dig med en länk för att återställa lösenordet.</p>
            <Button onClick={() => setResetPassword(false)} className="primary lg arrow" loading={forgotPasswordLoading} type="submit">Tillbaka</Button>
          </>
          }
          <span onClick={() => {
            setResetPassword(false);
            setIsLoginForm(true);
          }} className='change-form arrow'>Tillbaka till logga in</span>
        </form>
      </>
        : isLoginForm ? <>
          <h2 className="mb-lg">Logga in</h2>
          <form noValidate onSubmit={loginSubmit}>
            <div className="row">
              <label htmlFor="emailInput">E-post</label>
              <input type="email" id="emailInput" onChange={(e) => {
                setGuardian({ ...guardian, email: e.target.value })
              }} value={guardian.email} autoComplete="off" />
            </div>
            <div className="row">
              <label htmlFor="passwordInput">Lösenord</label>
              <input type={isPasswordShow ? 'text' : 'password'} id="passwordInput" onChange={(e) => {
                setGuardian({ ...guardian, password: e.target.value })
              }} value={guardian.password} autoComplete="off" />
              <div className={`password-icon  ${isPasswordShow && 'showed'}`} onClick={() => setIsPasswordShow(!isPasswordShow)}><img src={passwordeye} className={`show-password`} /></div>
            </div>
            <div className="mb-lg">
              <Button type="submit" className='primary lg arrow'>Logga in</Button>
            </div>

            {loginError && loginError.graphQLErrors.map(({ message }, i) => (
              <p className="submit-error" key={i}>{message}</p>
            ))}
            <span onClick={() => setIsLoginForm(false)} className='change-form'>Jag vill skapa ett nytt konto</span>
            <span className="forgot-pass change-form" to onClick={() => {
              setResetPassword(true);
            }}>Glömt lösenord?</span>
            
          </form>
          
        </>
          : <>
            <h2 className="mb-lg">Skapa målsmans konto</h2>
            <form noValidate onSubmit={guardianSubmit}>
              <div className="row">
                <label htmlFor="emailInput">Målsmans e-post</label>
                <input type="email" id="emailInput" onChange={(e) => {
                  setValidation({ ...validation, email: inputValidator('email', e.target.value) });
                  setGuardian({ ...guardian, email: e.target.value })
                }} value={guardian.email} autoComplete="off" />
                {(!validation.email?.valid && errorVisible) && <small className="error">{validation.email?.error}</small>}
              </div>
              <div className="row">
                <label htmlFor="emailVerifiedInput">Målsmans e-post igen</label>
                <input type="email" id="emailVerifiedInput" onChange={(e) => {
                  setEmailVerify(e.target.value);
                  setErrorNonMatchingEmail(guardian.email !== e.target.value);
                }} value={emailVerify} autoComplete="off" />
                {(errorNonMatchingEmail && errorVisible) && <small className="error">E-postadresserna matchar inte</small>}
              </div>
              <div className="row">
                <label htmlFor="firstNameInput">Målsmans förnamn</label>
                <input type="text" id="firstNameInput" maxLength={30} onChange={(e) => {
                  setValidation({ ...validation, firstname: inputValidator('firstname', e.target.value) });
                  setGuardian({ ...guardian, firstname: e.target.value })
                }} value={guardian.firstname} autoComplete="off" />
                {(!validation.firstname?.valid && errorVisible) && <small className="error">{validation.firstname?.error}</small>}
              </div>
              <div className="row">
                <label htmlFor="lastNameInput">Målsmans efternamn</label>
                <input type="text" id="lastNameInput" onChange={(e) => {
                  setValidation({ ...validation, lastname: inputValidator('lastname', e.target.value) });
                  setGuardian({ ...guardian, lastname: e.target.value })
                }} value={guardian.lastname} autoComplete="off" />
                {(!validation.lastname?.valid && errorVisible) && <small className="error">{validation.lastname?.error}</small>}
              </div>
              <div className="row">
                <label htmlFor="passwordInput">Målsmans lösenord</label>
                <input type={isPasswordShow ? 'text' : 'password'} id="passwordInput" onChange={(e) => {
                  setValidation({ ...validation, password: inputValidator('password', e.target.value) });
                  setGuardian({ ...guardian, password: e.target.value })
                }} value={guardian.password} autoComplete="off" />
                <div className={`password-icon  ${isPasswordShow && 'showed'}`} onClick={() => setIsPasswordShow(!isPasswordShow)}>
                  <img src={passwordeye} className={`show-password`} alt='' />
                </div>
                {(!validation.password?.valid && errorVisible) && <small className="error">{validation.password?.error}</small>}
              </div>
              <Button type="submit" className='primary lg arrow'>Skapa konto</Button>
              {registerError && registerError.graphQLErrors.map(({ message }, i) => (
                <p className="submit-error" key={i}>{message}</p>
              ))}
              {userExistError
                ? <span className="user-exist">Det finns redan ett konto med den e-postadressen.<br/><span className='arrow' onClick={() => setIsLoginForm(true)}>Logga in</span></span>
                : <span onClick={() => setIsLoginForm(true)} className='change-form'>Jag har redan ett konto</span>
              }
            </form>
          </>
      }
    </>
  )
}

export default InvitationGuardian
