import gql from 'graphql-tag';

// QUERIES


const ongoingPeriodsInCompanyQuery = gql`
  query ($companyId: ObjectId!) {
    periods: ongoingPeriodsInCompany(companyId: $companyId) {
      fromPeriod
      toPeriod
      currentPeriod
    }
  }
`;


const getCompanyTypesQuery = gql`
  {
    types: companyTypes {
      _id
      key
      name
    }
  }
`;

const getCompanyCategoriesQuery = gql`
  {
    categories: companyCategories {
      _id
      name
      iconPath
    }
  }
`;

const getCompaniesQuery = gql`
  query ($zoneId: ObjectId!) {
    companies: companiesInZone(zoneId: $zoneId) {
      _id
      zoneId
      name
      phone
      logoURL
      mainSponsor
      websiteURL
      visitAddress {
        streetAddress
        zipCode
        city
        state
      }
      adminEmails
      companyCategoryId
      typeId
      earlyBird
      contactPerson
      contactMobile
      notes
    }
  }
`;


const getCompanyQuery = gql`
  query ($companyId: ObjectId!) {
    company: company(_id: $companyId) {
      _id
      name
      agreements {
        _id
        activeFrom
        activeTo
        coupons {
          _id
          period
          active
          imageURL
          discount
          smallTitle
          terms
          description
          code
          productLink
        }
      }
    }
  }
`;

const statCouponsByCompanyQuery = gql`
  query ($companyId: ObjectId!, $fromPeriod: Int, $toPeriod: Int) {
    coupons: statCouponsByCompany(companyId: $companyId, fromPeriod: $fromPeriod, toPeriod: $toPeriod) {
      used {
        agreement
        season1
        season2
        season3
      }
      all {
        agreement {
          active
          total
        }
        season1 {
          active
          total
        }
        season2 {
          active
          total
        }
        season3 {
          active
          total
        }
      }
      topAgreement {
        coupon {
          _id
          smallTitle
          usedCache {
            userId
          }
        }
        company {
          name
        }cityIdcur
      }
      topSeason {
        coupon {
          _id
          smallTitle
          usedCache {
            userId
          }
        }
        company {
          name
        }
      }
    }
  }
`;


// MUTATIONS

const addCompanyMutation = gql`
  mutation(
    $zoneId: ObjectId!
    $name: String!
    $phone: String
    $companyCategoryId: ObjectId!
    $mainSponsor: Int
    $websiteURL: String
    $typeId: Int!
    $vStreetAddress: String
    $vZipCode: String
    $vCity: String
    $vState: String
    $adminEmails: [String!]
    $earlyBird: Boolean
    $logoURL: String
    $contactPerson: String
    $contactMobile: String
    $notes: String
    ) {
    createCompany(
      zoneId: $zoneId,
      name: $name,
      phone: $phone,
      companyCategoryId: $companyCategoryId,
      mainSponsor: $mainSponsor,
      websiteURL: $websiteURL,
      adminEmails: $adminEmails,
      visitAddress: {
        streetAddress: $vStreetAddress
        zipCode: $vZipCode
        city: $vCity
        state: $vState
      },
      earlyBird: $earlyBird,
      logoURL: $logoURL,
      typeId: $typeId,
      contactPerson: $contactPerson,
      contactMobile: $contactMobile,
      notes: $notes,
      ) {
        _id
        name
      }
    }
`;

const addAgreementToCompanyMutation = gql`
  mutation(
    $companyId: ObjectId!
    $activeFrom: Int!
    $activeTo: Int!
    ) {
      addAgreementToCompany(
        companyId: $companyId,
        activeFrom: $activeFrom,
        activeTo: $activeTo,
        ) {
        _id
      }
    }
`;

const addCouponToCompanyAgreementMutation = gql`
  mutation(
    $companyId: ObjectId!
    $agreementId: ObjectId!
    $period: Int
    $active: Boolean
    $imageURL: String
    $discount: String
    $smallTitle: String
    $terms: String
    $description: String
    $code: String
    $productLink: String
    ) {
      addCouponToCompanyAgreement(
        companyId: $companyId,
        agreementId: $agreementId,
        period: $period,
        active: $active,
        imageURL: $imageURL,
        discount: $discount,
        smallTitle: $smallTitle,
        terms: $terms,
        description: $description,
        code: $code,
        productLink: $productLink,
          ) {
          _id
      }
    }
`;

const updateCompanyAgreementMutation = gql`
  mutation(
    $companyId: ObjectId!
    $_id: ObjectId!
    $activeFrom: Int!
    $activeTo: Int!
    ) {
      updateCompanyAgreement(
        companyId: $companyId,
        agreementId: $_id,
        activeFrom: $activeFrom,
        activeTo: $activeTo,
        ) {
          _id
      }
    }
`;

const updateCouponInCompanyAgreementMutation = gql`
  mutation(
    $companyId: ObjectId!
    $agreementId: ObjectId!
    $_id: ObjectId!
    $period: Int
    $active: Boolean
    $imageURL: String
    $discount: String
    $smallTitle: String
    $terms: String
    $description: String
    $code: String
    $productLink: String
    ) {
      updateCouponInCompanyAgreement(
        companyId: $companyId,
        agreementId: $agreementId,
        couponId: $_id,
        period: $period,
        active: $active,
        imageURL: $imageURL,
        discount: $discount,
        smallTitle: $smallTitle,
        terms: $terms,
        description: $description,
        code: $code,
        productLink: $productLink,
          ) {
          _id
      }
    }
`;


const updateCompanyMutation = gql`
  mutation(
    $_id: ObjectId!
    $name: String!
    $phone: String
    $companyCategoryId: ObjectId
    $mainSponsor: Int
    $logoURL: String
    $websiteURL: String
    $typeId: Int!
    $vStreetAddress: String
    $vZipCode: String
    $vCity: String
    $adminEmails: [String!]
    $vState: String
    $earlyBird: Boolean
    $contactPerson: String
    $contactMobile: String
    $notes: String
    ) {
    updateCompany(
      _id: $_id,
      name: $name,
      phone: $phone,
      companyCategoryId: $companyCategoryId
      mainSponsor: $mainSponsor,
      logoURL: $logoURL,
      websiteURL: $websiteURL,
      adminEmails: $adminEmails,
      visitAddress: {
        streetAddress: $vStreetAddress
        zipCode: $vZipCode
        city: $vCity
        state: $vState
      },
      earlyBird: $earlyBird,
      typeId: $typeId,
      contactPerson: $contactPerson,
      contactMobile: $contactMobile,
      notes: $notes,
      ) {
        _id
        name
    }
  }
`;

export {
  ongoingPeriodsInCompanyQuery,
  getCompanyCategoriesQuery,
  getCompanyTypesQuery,
  getCompaniesQuery,
  getCompanyQuery,
  statCouponsByCompanyQuery,
  addCompanyMutation,
  addAgreementToCompanyMutation,
  addCouponToCompanyAgreementMutation,
  updateCompanyAgreementMutation,
  updateCouponInCompanyAgreementMutation,
  updateCompanyMutation,
};
