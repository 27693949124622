import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { PageHeader } from '../../common/index';
import { useNavigationContext } from '../../context/NavigationContext';
import Table from './CompaniesTable';
import { getZoneQuery } from '../../queries/queries';
import { PreviewProvider, usePreviewContext } from '../../context/PreviewContext';
import Preview from './Preview';
import { ZoneProvider, useZoneContext } from '../../context/ZoneContext';

function Companies(props) {
  const { zone: zoneId } = props.match.params;
  const { data: zoneData } = useQuery(getZoneQuery, {
    variables: { _id: zoneId }
  });

  const { setZone } = useZoneContext();
  const { isPreviewOpen } = usePreviewContext();

  const { setNavigationActivated } = useNavigationContext();
  useEffect(() => {
    setNavigationActivated(true);
  }, []);

  useEffect(() => {
    setZone(zoneData && zoneData.zone);
  }, [zoneData, setZone]);

  return (
    <div id="companies">
      <PageHeader>
        <h1>{zoneData && zoneData.zone.name}</h1>
      </PageHeader>
      <div className={`companies-flex ${isPreviewOpen ? 'preview-open' : ''}`}>
        <Table zoneId={zoneId} />
        <Preview />
      </div>
    </div>
  );
}

function CompaniesView(props) {
  return (
    <ZoneProvider>
      <PreviewProvider>
        <Companies {...props} />
      </PreviewProvider>
    </ZoneProvider>
  );
}

export default CompaniesView;
