import React from 'react'
import { usePappersHafteContext } from '../../context/PappersHafteContext';

const Category = ({ company }) => {
  const { allPages } = usePappersHafteContext();
  const companyPages = allPages.filter(page => page?.companyId === company._id);
  const firstPageNumber = allPages.findIndex(page => page?.companyId === company._id) + 1;
  const lastPageNumber = firstPageNumber + companyPages.length - 1;

  return (
    <li className="toc-category flex">
      <span>{company.name}</span>
      <div className='line' />
      <span>
        {firstPageNumber}
        {firstPageNumber !== lastPageNumber && `-${lastPageNumber}`}
      </span>
    </li>
  )
}

export default Category;
