import React from 'react'
import InfoShape from './InfoShape'
import CompanyHeader from '../vara-haften/CompanyHeader'
import CompanyModalCoupon from '../vara-haften/CompanyModalCoupon'
import { useAutosize } from '../../common/Helpers'

const CompanyReviewCoupons = ({ companyReviewData, companyTypesData }) => {
  const { width } = useAutosize();
  const isDesktop = width > 992;

  return (
    <div className="relative">
      <div className="relative company-review-coupons box">
        <CompanyHeader
          company={companyReviewData?.bookletCompanyReviewByToken?.company}
          companyType={companyTypesData?.types[companyReviewData?.bookletCompanyReviewByToken?.company?.typeId]}
        />
        {companyReviewData?.bookletCompanyReviewByToken?.coupons.map(coupon =>
          <CompanyModalCoupon
            key={coupon._id}
            coupon={coupon}
          />
        )}
      </div>
      <InfoShape
        isArrowReversed={isDesktop}
        rotate={!isDesktop ? 90 : 0}
        title='Såhär ser det ut när man klickat in på ert företag'
        color='#368181'
        height={160}
        width={160}
        x={isDesktop ? 370 : '29%'}
        y={isDesktop ? -50 : -155}
        arrowX={isDesktop ? '10%' : '-30%'}
        arrowY={isDesktop ? 130 : '50%'}
      />
    </div>
  )
}

export default CompanyReviewCoupons
