import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { payWithSwishMutation, payWithSwishMobileMutation, getMyUserQuery } from '../../queries/queries';
import { Button, Field } from '../../common/index';
import { REACT_APP_IMAGE_BASE_URL } from '../../env'

function OrderPayWithSwish(props) {
  const { orderId, order, email, code, association, team, teamMember, booklet } = props;
  const [sContent, setSContent] = useState(null);
  const [generalPaymentError, setGeneralPaymentError] = useState(false);
  const [paid, setPaid] = useState(false);
  const [loadingSwishApp, setLoadingSwishApp] = useState(false);
  const [swishExistsOnDevice, setSwishExistsOnDevice] = useState(false);
  const [payWithSwish, { error: payError }] = useMutation(payWithSwishMutation);//, options);
  const [payWithSwishMobile] = useMutation(payWithSwishMobileMutation);//, options);
  const [orderInUrl, setOrderInUrl] = useState();

  useEffect(() => {
    if (isMobile) {
      setSwishExistsOnDevice(true);
    }
  }, []);

  useEffect(() => {
    const orderInfo = {
      code: code,
      associationName: order?.associationName,
      teamMemberId: order?.teamMemberId,
      teamMemberFirstname: order?.teamMemberFirstname,
      teamMemberLastname: order?.teamMemberLastname,
      teamName: order?.teamName,
      bookletId: order?.bookletId,
    };
    // Convert object to JSON base 64 string
    const orderInfoString = Buffer.from(JSON.stringify(orderInfo)).toString('base64');
    setOrderInUrl(orderInfoString);
  }, [order]);

  function payWithSwishOnThisDevice() {
    setLoadingSwishApp(true);
    // const callbackUrl = saEnkelt ? window.location.origin + '/confirm/' + orderInUrl : window.location.origin + '/order/confirm/' + orderId + '?zoakwmvurbf=' + aToken;
    const callbackUrl = window.location.origin + '/confirm/' + orderInUrl;
    const vars = {
      variables: {
        orderId,
      },
      refetchQueries: [{ query: getMyUserQuery }],
    };
    payWithSwishMobile(vars)
      .then(result => {
        console.log('Mobile Swish Data:', result.data.payWithSwishMobile);
        console.log('Mobile Swish Data:', result.data.payWithSwishMobile.split('&')[0]);
        console.log('Mobile Swish Data:', result.data.payWithSwishMobile.split('&')[0] + '&callbackurl=' + encodeURI(callbackUrl));
        console.log('Mobile Swish Data:', callbackUrl);
        // setTimeout används endast om användaren är i icke default-webbläsaren i mobilen, 
        // kommer tillbaka till default-webbläsaren efter Swish och växlar sedan manuellt tillbaka till icke default-webbläsaren
        setTimeout(function () { window.location = callbackUrl; }, 1000);
        window.location = result.data.payWithSwishMobile.split('&')[0] + '&callbackurl=' + encodeURI(callbackUrl);
      })
      .catch(useMutationError => { console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors) });
  }

  function submitForm(e) {
    e.preventDefault();

    const vars = {
      variables: {
        payerAlias: sContent.swishMobile,
        orderId,
      },
      // refetchQueries: [{ query: getMyUserQuery }],
    };

    payWithSwish(vars)
      .then(result => {
        if (result && result.data && result.data.payWithSwish) {
          setGeneralPaymentError(false);
          //onSuccess(result && result.data && result.data.payWithSwish);
          setPaid(true);
          console.log('swish paid', result);
        } else if (result && result.data) {
          setGeneralPaymentError(true);
          console.log('swish failed', result);
        }
      })
      .catch(useMutationError => { console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors) });
  }

  const ss = (c) => {
    setSContent({ ...sContent, ...c });
  };

  return (
    <form id="order-form" onSubmit={(e) => { submitForm(e); }}>
      <h2>Betalning</h2>
      {email && <p className="loggedInAs"><i className="user"></i>{email}</p>}
      <section className="order-info">
        <div className='flex flex-col items-center gap-1'>
          <img src={REACT_APP_IMAGE_BASE_URL + booklet?.logoURL} width={60} alt="" />
          <div><span>{order?.bookletTitle}, {order?.bookletPrice} kr</span></div>
        </div>
        <p className="small-label">Du köper av:</p>
        <div>
          {teamMember && <span className='block text-center'>{teamMember}</span>}
          {association && <span>{association}</span>}
          {team && <span> - {team}</span>}
        </div>
      </section>
      <small className='code-info my text-center'>Din <span className='bold'>appkod</span> visas på skärmen direkt efter betalningen,<br />du får även en kopia i Swish-appen.</small>

      <div className='swish-info'>
        <div className="pay-methods" />
        <div className="price-info">
          <p>Att betala till DLO AB - digitalt rabatthäfte</p>
          <p className="city-price">{order ? order.bookletPrice : '0'} kr</p>
        </div>
        {paid && <Redirect to={
          `/confirm/${orderInUrl}`
        } push />}
        <small className='my text-center'>Genom att slutföra beställningen/köpet så godkänner du villkoren i våra köpvillkor och bekräftar att du tagit del av vår integritetspolicy.</small>
        {swishExistsOnDevice ?
          [
            <Button className="primary lg" onClick={() => payWithSwishOnThisDevice()} loading={loadingSwishApp}>Öppna Swish på den här enheten</Button>,
            <Button className="secondary lg" onClick={() => setSwishExistsOnDevice(false)}>Använd Swish på annan enhet</Button>
          ]
          :
          [
            <Field type="phone" name="swishMobile" title="Mobilnummer" x2 value={sContent && sContent.swishMobile} onChange={ss} />,
            (generalPaymentError || payError) && <p className="error order">Betalningen kunde inte genomföras, starta om swish-appen och prova igen.</p>,
            <button className="primary lg" type="submit">Betala med Swish</button>
          ]}
      </div>
    </form>
  );
}

OrderPayWithSwish.propTypes = {
  orderId: PropTypes.string,
  order: PropTypes.object,
  email: PropTypes.string,
};

OrderPayWithSwish.defaultProps = {
  orderId: null,
  email: '',
};

export default OrderPayWithSwish;
