import React from 'react';

const BookletOrderBatchItem = ({ batch, batchLabel, openModal }) => {
  const completedCount = batch?.orders?.filter(order => order.userId).length;
  return (
    <button key={batch} type="button" className='text-left batch-item' onClick={() => openModal(batch)}>
      <div className="flex justify-between">
        <div className="truncate">
          <div className='truncate' title={batchLabel}>
            {batchLabel}
          </div>
          <div>
            <small>
              {completedCount}/{batch?.orders?.length} aktiverade
            </small>
          </div>
        </div>
      </div>
      {batch?.completedDate && <small>{batch.completedDate}</small>}
    </button>
  );
};

export default BookletOrderBatchItem;
