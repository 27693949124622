import React, { useState } from 'react';
import { FaUserCheck } from 'react-icons/fa6';
import OrderBatchDownloadPDF from './OrderBatchDownloadPDF';
import { IoIosSend } from 'react-icons/io';
import { Button } from '../../common/Form';
import BookletOrderItem from './BookletOrderItem';

const BookletDownloadPDFModal = ({ bookletId, selectedBatch }) => {
  const [email, setEmail] = useState('');
  const [isEmailLoading, setIsEmailLoading] = useState(false);

  const sendEmail = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isEmailLoading) return;
    setIsEmailLoading(true);
    setTimeout(() => {
      alert('Den här funktionen är inte implementerad än.');
      setIsEmailLoading(false);
      setEmail('');
    }, 1000);
  };

  const handleDownloadCSV = (e) => {
    e.preventDefault();

    const codes = selectedBatch.orders.map(order => order.code);
    const csvContent = codes.map(code => [code]).map(e => e.join(',')).join('\n');

    const fileName = (selectedBatch?.batchLabel ? selectedBatch.batchLabel : 'Alla ordrar').replace(/ /g, '-');

    // Trigger download of the CSV file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {/* PDF options */}
      <div className="modal-top mb-lg">
        <h4 className='block mb-lg max-w'>
          {selectedBatch?.batchLabel
            ? selectedBatch.batchLabel
            : 'Alla ordrar'
          }
        </h4>
        {/* <OrderBatchDownloadPDF
          bookletId={bookletId}
          batchLabel={selectedBatch.batchLabel}
        />
        <b className='block'>Skicka mail med länk till PDF</b>
        <form onSubmit={sendEmail} className="flex flex-col max-w relative p-0">
          <label htmlFor="email">E-postadress</label>
          <input type="email" style={{ paddingRight: 40 }} id="email" value={email} onChange={e => setEmail(e.target.value)} required />
          <Button type="submit" className="primary send-email-btn" disabled={email.length === 0} loading={isEmailLoading}>
            <IoIosSend size={20} />
          </Button>
        </form> */}
      </div>

      {/* List of codes */}
      <div className="order-list">
        {selectedBatch.orders.length > 0 && (<>
          <div className="order-list-top flex items-center justify-between">
            {selectedBatch?.batchLabel
              ? <b className='text-left block'>Värdebevis ({selectedBatch.orders.length})</b>
              : <b className='text-left block'>Ordrar ({selectedBatch.orders.length})</b>
            }

            <button className='btn primary' onClick={handleDownloadCSV}>
              Exportera koder
            </button>
          </div>
          <ul>
            {selectedBatch.orders.map((order, index) => (
              <BookletOrderItem
                key={order._id}
                order={order}
                index={index}
              />
            ))}
          </ul>
        </>)}
      </div>

    </>
  );
};

export default BookletDownloadPDFModal;
