import gql from 'graphql-tag';

// QUERIES

const getUsersQuery = gql`
  {
    users: usersWithOrders {
      user {
        _id
        firstname
        lastname
        superAdmin
        facebookId
        email
        adminObjects {
          #citiesIAdmin {
            #_id
            #name
          #}
          zonesIAdmin {
            _id
            name
          }
          bookletsIAdmin {
            _id
            name
          }
          companiesIAdmin {
            _id
            name
            zoneName
            #cityName
          }
          associationsIAdmin {
            _id
            name
            zoneName
            #cityName
          }
          teamsIAdmin {
            _id
            name
            associationName
          }
          teamMembersIAdmin {
            _id
            firstname
            lastname
            associationName
            teamName
          }
        }
      }
      orders {
        bookletTitle
        #period
        #cityName
      }
    }
  }
`;

const getMyUserQuery = gql`
  {
    user: user {
      _id
      email
      firstname
      lastname
      superAdmin
      associationTutorialVersion
      teamTutorialVersion
      teamMemberTutorialVersion
      adminObjects {
        #citiesIAdmin {
          #_id
          #name
        #}
        zonesIAdmin {
          _id
          name
        }
        bookletsIAdmin {
          _id
          name
        }
        companiesIAdmin {
          _id
          name
          zoneName
          zoneId
          #cityName
          #cityId
        }
        associationsIAdmin {
          _id
          name
          zoneName
          zoneId
          #cityName
          #cityId
        }
        teamsIAdmin {
          _id
          name
          associationName
          associationId
        }
        teamMembersIAdmin {
          _id
          firstname
          lastname
          associationName
          associationId
          teamName
          teamId
          decryptedAdminToken
        }
      }
      orders {
        _id
        bookletId
        bookletTitle
        bookletPrice
        #cityId
        #cityName
        #cityPrice
        #period
        associationId
        associationName
        teamId
        teamName
        teamMemberId
        teamMemberFirstname
        teamMemberLastname
        status
        completedDate
        swishStatus
        paidWith
        paidAmount
        paidDate
      }
      activeOrders {
        _id
        bookletId
        bookletTitle
        #cityId
        #cityName
        #period
        associationId
        associationName
        teamId
        teamName
        teamMemberId
        teamMemberFirstname
        teamMemberLastname
      }
    }
  }
`;

const userExistsQuery = gql`
  query($email: String!) {
    userExists(email: $email)
  }
`

// MUTATIONS

const registerMutation = gql`
  mutation($email: String!, $password: String!, $firstname: String!, $lastname: String, $longExpiry: Boolean!) {
    createUser(email: $email, password: $password, firstname: $firstname, lastname: $lastname, longExpiry: $longExpiry)
  }
`;

const activateAccountMutation = gql`
  mutation($email: String!, $tmpPassword: String!, $newPassword: String!, $firstname: String!, $lastname: String, $termsApproved: Boolean!) {
    activateAccount(email: $email, tmpPassword: $tmpPassword, newPassword: $newPassword, firstname: $firstname, lastname: $lastname, termsApproved: $termsApproved) {
      email
    }
  }
`;

const loginMutation = gql`
  mutation($email: String!, $password: String!, $longExpiry: Boolean!) {
    login(email: $email, password: $password, longExpiry: $longExpiry)
  }
`;

const forgotPasswordMutation = gql`
  mutation($email: String!) {
    forgotPassword(email: $email)
  }
`;

const resetPasswordMutation = gql`
  mutation($email: String!, $tmpPassword: String!, $newPassword: String!) {
    changePasswordWithTmpPassword(email: $email, tmpPassword: $tmpPassword, newPassword: $newPassword) {
      _id
    }
  }
`;

const addUserMutation = gql`
  mutation($email: String!, $firstname: String!, $lastname: String!, $password: String!) {
    createUser(email: $email, firstname: $firstname, lastname: $lastname, password: $password)
  }
`;


const updateUserMutation = gql`
  mutation($_id: ObjectId!, $email: String!, $firstname: String!, $lastname: String!) {
    updateUser(_id: $_id, email: $email, firstname: $firstname, lastname: $lastname) {
      _id
      firstname
    }
  }
`;

const updateMyUserMutation = gql`
  mutation($email: String, $firstname: String, $lastname: String, $associationTutorialVersion: Int, $teamTutorialVersion: Int, $teamMemberTutorialVersion: Int) {
    updateMyUser(email: $email, firstname: $firstname, lastname: $lastname, associationTutorialVersion: $associationTutorialVersion, teamTutorialVersion: $teamTutorialVersion, teamMemberTutorialVersion: $teamMemberTutorialVersion) {
      firstname
    }
  }
`;
const updateMyPasswordMutation = gql`
  mutation($currentPassword: String, $newPassword: String ) {
    updateMyUser(currentPassword: $currentPassword, newPassword: $newPassword){
      _id
    }
  }
`;

const updateUserPasswordMutation = gql`
  mutation($_id: ObjectId!, $password: String!) {
    updateUser(_id: $_id, password: $password) {
      _id
    }
  }
`;

const deleteTeamMemberMutation = gql`
  mutation($teamMemberId: ObjectId! ) {
    deleteTeamMember(teamMemberId: $teamMemberId)
  }
`;
const moveTeamMemberSalesMutation = gql`
  mutation($fromTeamMemberId: ObjectId!, $toTeamMemberId: ObjectId! ) {
    moveTeamMemberSales(fromTeamMemberId: $fromTeamMemberId, toTeamMemberId: $toTeamMemberId)
  }
`;
const deactivateMyUserMutation = gql`
  mutation($currentPassword: String! ) {
    deActivateMyUser(currentPassword: $currentPassword) {
      _id
    }
  } 
`;
const deleteMyUserMutation = gql`
  mutation {
    deleteMyUser
  }
`;
export {
  getUsersQuery,
  userExistsQuery,
  getMyUserQuery,
  registerMutation,
  activateAccountMutation,
  forgotPasswordMutation,
  resetPasswordMutation,
  loginMutation,
  addUserMutation,
  updateUserMutation,
  updateMyUserMutation,
  updateMyPasswordMutation,
  deleteTeamMemberMutation,
  moveTeamMemberSalesMutation,
  deactivateMyUserMutation,
  deleteMyUserMutation,
  updateUserPasswordMutation
};
