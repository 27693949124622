
import React from 'react';
import { useMutation } from 'react-apollo';
import Slider from 'react-slick';
import { updateMyUserMutation, updateUserMutation } from '../../queries/user';
import { REACT_APP_TUTORIAL_VERSION } from '../../env';

export default function SimpleSlider({children, guideType, user}) {

  const [slideIndex, setSlideIndex] = React.useState();
  const slideLength = document.querySelectorAll('.slick-slide').length - 1;

  const [updateUser] = useMutation(updateMyUserMutation, {
    onCompleted: (res) => {
      console.log('Tutorial guide completed', res);
    },
    onError: err => console.log(err)
  })

  const settings = {
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    adaptiveHeight: true,
    touchMove: true,
    swipe: true,
    responsive: [{
      breakpoint: 767,
      settings: {
        arrows: false
      }
    }],
    afterChange: (index) => setSlideIndex(index)
  }

  React.useEffect(() => {
    if(guideType && slideLength === slideIndex) {
      switch (guideType) {
        case 'association':
          if(user.associationTutorialVersion !== REACT_APP_TUTORIAL_VERSION) {
            updateUser({ variables: { associationTutorialVersion: REACT_APP_TUTORIAL_VERSION }})
          }     
          break;        
        case 'team':
          if(user.teamTutorialVersion !== REACT_APP_TUTORIAL_VERSION) {
            updateUser({ variables: { teamTutorialVersion: REACT_APP_TUTORIAL_VERSION }})
          }    
          break;
        case 'sales':
          if(user.teamMemberTutorialVersion !== REACT_APP_TUTORIAL_VERSION) {
            updateUser({ variables: { teamMemberTutorialVersion: REACT_APP_TUTORIAL_VERSION }})
          }    
          break;
        default:
          break;
      }
    }
  }, [guideType, slideIndex])

  return (
    <div className="slider">
      <Slider {...settings}>
        {children}
      </Slider>
    </div>
  );
}


// export default class SimpleSlider extends Component {
//   render() {
//     const settings = {
//       arrows: true,
//       dots: true,
//       infinite: false,
//       speed: 500,
//       slidesToShow: 1,
//       slidesToScroll: 1,
//       draggable: true,
//       adaptiveHeight: true,
//       touchMove: true,
//       swipe: true,
//       responsive: [{
//         breakpoint: 767,
//         settings: {
//           arrows: false
//         }
//       }]
//     };
//     return (
//       <div className="slider">
//         <Slider {...settings}>
//           {this.props.children}
//         </Slider>
//       </div>
//     );
//   }
// }