import React from 'react'

function FontIcon({ iconText }) {
  const iconTextLower = iconText?.toLowerCase();
  let selectedIcon = '';
  switch (iconTextLower) {
    case 'övrigt':
      selectedIcon = 'A';
      break;
    case 'filter':
      selectedIcon = 'B';
      break;
    case 'filter-o':
      selectedIcon = 'C';
      break;
    case 'view':
      selectedIcon = 'D';
      break;
    case 'arrow':
      selectedIcon = 'E';
      break;
    case 'close':
      selectedIcon = 'F';
      break;
    case 'hälsa':
      selectedIcon = 'G';
      break;
    case 'tag':
      selectedIcon = 'H';
      break;
    case 'book':
      selectedIcon = 'I';
      break;
    case 'arrow-right':
      selectedIcon = 'J';
      break;
    case 'online':
      selectedIcon = 'K';
      break;
    case 'kampanj':
      selectedIcon = 'L';
      break;
    case 'online-thick':
      selectedIcon = 'M';
      break;
    case 'store-thick':
      selectedIcon = 'N';
      break;
    case 'butik':
      selectedIcon = 'O';
      break;
    case 'store':
      selectedIcon = 'O';
      break;
    case 'menu':
      selectedIcon = 'P';
      break;
    case 'arrow-down':
      selectedIcon = 'Q';
      break;
    case 'arrow-up':
      selectedIcon = 'q';
      break;
    case 'arrow-left':
      selectedIcon = 'R';
      break;
    case 'heart-o':
      selectedIcon = 'S';
      break;
    case 'heart':
      selectedIcon = 'T';
      break;
    case 'mode':
      selectedIcon = 'U';
      break;
    case 'fritid':
      selectedIcon = 'V';
      break;
    case 'hem':
      selectedIcon = 'W';
      break;
    case 'health-single':
      selectedIcon = 'X';
      break;
    case 'äta ute':
      selectedIcon = 'Y';
      break;
    case 'mat':
      selectedIcon = 'Z';
      break;
    case 'copy':
      selectedIcon = 'c';
      break;
    case 'user':
      selectedIcon = 'u';
      break;
    case 'info':
      selectedIcon = 'i';
      break;
    case 'logout':
      selectedIcon = 'l';
      break;
    case 'uppleva':
      selectedIcon = 'compass-outline';
      break;
    case 'skönhet':
      selectedIcon = 'mirror';
      break;
    case 'login':
      selectedIcon = 'login';
      break;
    default:
      selectedIcon = 'Not set';
      break;
  }

  return selectedIcon;
}

export default FontIcon
