import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useAutosize } from '../../common';
import { usePreviewContext } from '../../context/PreviewContext';
import PreviewCompany from './PreviewCompany';

function Preview() {
  const { previewType, isPreviewOpen, setIsPreviewOpen } = usePreviewContext();
  const { width } = useAutosize();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      setIsScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (width < 1200) {
      setIsPreviewOpen(false);
    }
  }, [setIsPreviewOpen, width]);

  const handlePreviewToggle = () => {
    setIsPreviewOpen(!isPreviewOpen);
  }

  return (
    <motion.div
      className={`app-preview ${width < 900 ? 'hidden' : 'block'}`}
      initial={false}
      animate={{
        width: isPreviewOpen ? 400 : 0,
      }}
      transition={{ duration: 0.4, ease: 'easeOut' }}
    >
      <motion.div
        className="box app-preview-box"
        initial={false}
        animate={{
          right: isPreviewOpen ? 0 : -400,
          top: isScrolled ? 0 : 160
        }}
        transition={{ duration: 0.4, ease: 'easeOut' }}
      >
        {previewType === 'company' && <PreviewCompany togglePreview={handlePreviewToggle} />}
      </motion.div>
    </motion.div>
  )
}

export default Preview
