/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { forgotPasswordMutation } from '../../queries/queries';
import { Button } from '../../common/index';
import { Link } from 'react-router-dom';
import { useNavigationContext } from '../../context/NavigationContext';
import SaEnkeltLogo from '../../img/sa-enkelt-logo.svg';
import CityLogo from '../../img/city-logo.svg';
import { REACT_APP_BRAND } from '../../env';


function SaEnkeltAuthForgotPasswordForm() {
  const [email, setEmail] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [forgotPassword, { loading: forgotPasswordLoading, error: forgotPasswordError }] = useMutation(forgotPasswordMutation);

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  function submitForm(e) {
    e.preventDefault();

    if (forgotPasswordLoading) {
      console.log('Loading. Double submit canceled.')
      return null;
    }

    const vars = {
      variables: {
        email
      },
    };

    forgotPassword(vars)
      .then(result => { console.log('result', result); setShowSuccessMessage(true); })
      .catch(useMutationError => { console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors) });
  }

  return (
    <div id="sa-enkelt-login">
      <header className="login-header">
        {REACT_APP_BRAND === 'CITY'
          ? <img src={CityLogo} alt="" />
          : <img src={SaEnkeltLogo} alt="" />
        }
      </header>
      <div className="left">
        <div className="box md m-0">
          {showSuccessMessage ? <h3>Kolla din e-post</h3> : <h3>Glömt lösenord?</h3>}
          <form id="sa-enkelt-form" onSubmit={(e) => { submitForm(e); }}>
            {!showSuccessMessage &&
              <div className='row'>
                <label htmlFor="email">E-post</label>
                <input type="email" name="email" id="email" onChange={(e) => { setEmail(e.target.value) }} />
              </div>
            }
            {forgotPasswordError && forgotPasswordError.graphQLErrors.map(({ message }, i) => (
              <p className="error" key={i}>{message}</p>
            ))}
            {!showSuccessMessage && <Button className="primary lg" loading={forgotPasswordLoading} type="submit">Återställ</Button>}

            {showSuccessMessage && <p>Ett e-postmeddelande är skickat till dig med en länk för att återställa lösenordet.</p>}

            <Link className="text new-pass" to="/login">Tillbaka</Link>
          </form>
        </div>
      </div>
      <div className="right" />
    </div>
  );
}


export default SaEnkeltAuthForgotPasswordForm;
