import React, { useEffect, useState } from 'react'
import SaleTips1 from '../../../img/sale-tips-1.svg'
import SaleTips2 from '../../../img/sale-tips-2.svg'
import SaleTips3 from '../../../img/sale-tips-3.svg'
// import SaleTips4 from '../../../img/sale-tips-4.svg'
// import SaleTips5 from '../../../img/sale-tips-5.svg'
import { useNavigationContext } from '../../../context/NavigationContext'
import { Link, useLocation } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import { getTeammemberById } from '../../../queries/teamMember'

const TeammemberTips = (props) => {
  const { teammember: teamMemberId } = props.match.params;
  const location = useLocation();

  const { data: teamMemberData } = useQuery(getTeammemberById, { variables: { teamMemberId: teamMemberId } });
  const [webshopUrl, setWebshopUrl] = useState();
  const baseUrl = location.pathname.substring(0, location.pathname.lastIndexOf('/'));

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  useEffect(() => {
    setWebshopUrl(`/${teamMemberData?.teamMember?.associationSlug}/${teamMemberData?.teamMember?.teamSlug}/${teamMemberData?.teamMember?.slug}`);
  }, [teamMemberData]);

  return (
    <div id='teammember-tips'>
      <div className='section-title'>
        <h2>Häftet är så enkelt att sälja</h2>
        <p>Följande metoder kan du använda för att sälja rabatthäften via din personliga webbshop.</p>
      </div>

      <div className="box">
        <img src={SaleTips1} alt="" />
        <div>
          <h3>Dela din webbshop på sociala medier</h3>
          <p>Ett snabbt och enkelt sätt att informera vänner och familj att du säljer häftet är att dela webbshoppen via sociala medier. Skriv något personligt i inlägget så ökar chansen att dina vänner klickar på länken.
            <br />Du hittar delningsfunktionen i under fliken <Link to={`${baseUrl}/home`}>HEM</Link>, eller i din <Link to={webshopUrl}>webbshop</Link></p>
        </div>
      </div>

      <div className="box">
        <img src={SaleTips2} alt="" />
        <div>
          <h3>Använd din personliga QR-kod</h3>
          <p>Under fliken <Link to={`${baseUrl}/home`}>HEM</Link> eller i <Link to={webshopUrl}>din webbshop</Link> finns en QR-kod som leder rakt in i din webbshop. Låt kunderna scanna QR-koden med mobilen för att komma till webbshoppen och köpa häftet av dig.</p>
        </div>
      </div>

      <div className="box">
        <img src={SaleTips3} alt="" />
        <div>
          <h3>Köp ett häfte åt kunden</h3>
          <ul>
            <li><b>1.</b> Köp häftet i din webbshop för att få en aktiveringskod</li>
            <li><b>2.</b> Be kunden swisha dig och lämna därefter ut aktiveringskoden</li>
            <li><b>3.</b> Kunden kan nu ladda ned SåEnkelt-appen och aktivera häftet med koden</li>
          </ul>
        </div>
      </div>

      {/* <div className='section-title'>
        <h2>Reklam i god tid ökar din försäljning</h2>
        <p>Använd tiden innan häftet börjar gälla till att göra reklam för försäljningen. Flyern och förbeställningslistan fungerar ypperligt att lägga ut i fikarum på olika arbetsplatser eller dela ut till intresserade personer i din omgivning.</p>
        <p>Både flyern och beställningslistan finns att ladda ned inne på Mina sidor.</p>
      </div>

      <div className="flex">
        <div className="box flex-col">
          <img src={SaleTips4} alt="" />
          <div>
            <h3>Sätt upp eller dela ut flyers</h3>
            <p>Flyern har en QR-kod som leder in till webbshoppen. I rutan under kan du fylla i ditt namn och vilken förening/organisation du tillhör, så att dina kunder kan köpa av dig.</p>
          </div>
        </div>
        <div className="box flex-col">
          <img src={SaleTips5} alt="" />
          <div>
            <h3>Samla in beställningar</h3>
            <p>Lägg ut listor på lämpliga ställen, gärna en tid innan häftet börjar gälla. Kontakta sedan personerna på listan, ta betalt, köp häftena och dela ut aktiveringskoderna till respektive kund.</p>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default TeammemberTips
