import React, { useEffect, useState } from 'react';
import { FaUserCheck } from 'react-icons/fa6';

const BookletOrderItem = ({ order, index }) => {
  const isClaimed = !!order.userId;

  const [userDetails, setUserDetails] = useState([]);
  const [supportDetails, setSupportDetails] = useState([]);

  useEffect(() => {
    const newUserDetails = new Set();
    const newSupportDetails = new Set();

    // User name, swish number and paid amount
    if (order.userFirstname || order.userLastname) {
      newUserDetails.add(`${order.userFirstname} ${order.userLastname}`);
    }
    if (isClaimed && !order.userFirstname && !order.userLastname) {
      newUserDetails.add('Okänt namn');
    }
    if (order.swishNumber) {
      newUserDetails.add(order.swishNumber);
    }
    if (order.paidAmount) {
      newUserDetails.add(`${order.paidAmount} kr`);
    }

    // Association name, team name and team member name
    if (order.associationName) {
      newSupportDetails.add(order.associationName);
    }
    if (order.teamName) {
      newSupportDetails.add(order.teamName);
    }
    if (order.teamMemberFirstname || order.teamMemberLastname) {
      newSupportDetails.add(`${order.teamMemberFirstname} ${order.teamMemberLastname}`);
    }

    setUserDetails(Array.from(newUserDetails));
    setSupportDetails(Array.from(newSupportDetails));
  }, [order, isClaimed]);

  return (
    <li key={order._id} className='order-item'>
      <div className="order-item-header">
        <div className="flex items-center gap-1">
          <strong>{index + 1}</strong>
          <hr style={{ flexGrow: 1 }} />
          <div className="order-details flex flex-col">
            <div className="flex items-center gap-1">
              <span>{order.code}</span>
              {isClaimed
                ? <FaUserCheck
                  className='claimed-icon'
                  title={`Aktiverad av ${(order.userFirstname || order.userLastname) ? `${order.userFirstname} ${order.userLastname}` : 'Okänt namn'}`}
                  style={{ width: '1.5rem' }}
                />
                : <div style={{ width: '1.5rem' }}>-</div>
              }
            </div>
          </div>
        </div>
      </div>
      {isClaimed ? (
        <div className="user-details text-left">
          <div>
            {userDetails.map((detail, i) => (
              <strong key={i}>
                {detail}
                {i !== userDetails.length - 1 && ', '}
              </strong>
            ))}
          </div>
          {order.paidDate && (
            <small>
              Betald: {new Date(order.paidDate).toLocaleDateString()}
            </small>
          )}
        </div>
      ) : (
        <div className="user-details text-left">
          <strong>Ej aktiverad</strong>
        </div>
      )}

      <div className="support-details text-left">
        {supportDetails.map((detail, i) => (
          <small key={i}>
            {detail}
            {i !== supportDetails.length - 1 && ', '}
          </small>
        ))}
      </div>
    </li>
  );
};

export default BookletOrderItem;
