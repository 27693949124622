import gql from "graphql-tag";

//Invites
export const inviteAssociationAdmin = gql`
    mutation(
        $email: GraphQLEmail!
        $associationId: ObjectId!
        $portalUrlForEmails: String!
    ) {
        inviteAssociationAdmin(
            email: $email
            associationId: $associationId
            portalUrlForEmails: $portalUrlForEmails
        )
    }
`
export const inviteTeamAdmin = gql`
    mutation(
        $email: GraphQLEmail!
        $teamId: ObjectId!
        $portalUrlForEmails: String!
    ) {
        inviteTeamAdmin(
            email: $email
            teamId: $teamId
            portalUrlForEmails: $portalUrlForEmails
        )
    }
`
export const inviteTeamMemberAdmin = gql`
    mutation(
        $email: GraphQLEmail!
        $teamMemberId: ObjectId!
        $portalUrlForEmails: String!
    ) {
        inviteTeamMemberAdmin(
            email: $email
            teamMemberId: $teamMemberId
            portalUrlForEmails: $portalUrlForEmails
        )
    }
`

//Logs
export const logAssociationInvitationAction = gql`
    mutation(
        $objectId: ObjectId!
        $adminToken: String!
        $action: String!
    ) {
        logAssociationInvitationAction(
            objectId: $objectId
            adminToken: $adminToken
            action: $action
        ) 
    }
`
export const logTeamInvitationAction = gql`
    mutation(
        $objectId: ObjectId!
        $adminToken: String!
        $action: String!
    ) {
        logTeamInvitationAction(
            objectId: $objectId
            adminToken: $adminToken
            action: $action
        ) 
    }
`
export const logTeamMemberInvitationAction = gql`
    mutation(
        $objectId: ObjectId!
        $adminToken: String!
        $action: String!
    ) {
        logTeamMemberInvitationAction(
            objectId: $objectId
            adminToken: $adminToken
            action: $action
        ) 
    }
`


//Add
export const addMeAsAssociationAdminByInvitation = gql`
    mutation(
        $objectId: ObjectId!
        $adminToken: String!
    ) {
        addMeAsAssociationAdminByInvitation(
            objectId: $objectId
            adminToken: $adminToken
        ){
            _id
        } 
    }
`
export const addMeAsTeamAdminByInvitation = gql`
    mutation(
        $objectId: ObjectId!
        $adminToken: String!
    ) {
        addMeAsTeamAdminByInvitation(
            objectId: $objectId
            adminToken: $adminToken
        ){
            _id
        }
    } 
`
export const addMeAsTeamMemberAdminByInvitation = gql`
    mutation(
        $objectId: ObjectId!
        $adminToken: String!
    ) {
        addMeAsTeamMemberAdminByInvitation(
            objectId: $objectId
            adminToken: $adminToken
        ){
            _id
        }
    }
`

// Delete
export const deleteAssociationAdminInvitation = gql`
    mutation(
        $email: GraphQLEmail!
        $associationId: ObjectId!
    ) {
        deleteAssociationAdminInvitation(
            email: $email
            associationId: $associationId
        ){
            _id
        }
    }
`
export const deleteTeamAdminInvitation = gql`
    mutation(
        $email: GraphQLEmail!
        $teamId: ObjectId!
    ) {
        deleteTeamAdminInvitation(
            email: $email
            teamId: $teamId
        ){
            _id
        }
    }
`
export const deleteTeamMemberAdminInvitation = gql`
    mutation(
        $email: GraphQLEmail!
        $teamMemberId: ObjectId!
    ) {
        deleteTeamMemberAdminInvitation(
            email: $email
            teamMemberId: $teamMemberId
        ){
            _id
        }
    }
`



