import React, { useRef, useState } from 'react'
import { REACT_APP_IMAGE_BASE_URL } from '../../env';
import FontIcon from '../../common/FontIcon';
import { IoChevronDownSharp, IoChevronUpSharp } from 'react-icons/io5';
import CompanyCoupon from '../vara-haften/CompanyCoupon';
import { BsChevronCompactRight } from 'react-icons/bs';

export default function BookletCompanyReview({ companyReview, userOwnsBooklet, companyType, isPrinting, isBookletView, style }) {
  const cardRef = useRef();
  const [showAllCoupons, setShowAllCoupons] = useState(false);

  const handleShowAllCoupons = (e) => {
    e.stopPropagation();
    setShowAllCoupons(!showAllCoupons);
  }

  return (
    <div className={`booklet-company-card ${isBookletView ? 'vara-haften' : ''}`} style={style}>
      <div ref={cardRef} className={`booklet-card`}>
        <div className="left">
          <p className="company-name truncate">{companyReview?.company.name}</p>
          <img className="logo" src={REACT_APP_IMAGE_BASE_URL + companyReview?.company.logoURL} alt='logo' />
          {companyType?.name === 'butik'
            ? <p className='truncate'>
              {companyReview?.company.visitAddress.streetAddress &&
                <>
                  <span className='font-icon'>
                    <FontIcon iconText='butik' />
                  </span>
                  {companyReview?.company.visitAddress.streetAddress}
                </>
              }
            </p>
            : companyReview?.company.websiteURL && <a href={companyReview?.company.websiteURL} target="_blank" rel="noreferrer" className='truncate'><span className='font-icon'><FontIcon iconText='online' /></span>{companyReview?.company.websiteURL}</a>
          }
        </div>
        <div className={`right`}>
          <h2 className='truncate'>
            <span className='font-icon'>
              <FontIcon iconText='tag' />
            </span>{companyReview?.coupons[0].discount}
          </h2>
          <p className="smallTitle truncate">{companyReview?.coupons[0].smallTitle}</p>
          {
            companyReview?.coupons.length > 1 &&
            (isPrinting || showAllCoupons
              ? companyReview?.coupons.slice(1).map((coupon) =>
                <CompanyCoupon key={coupon._id} coupon={coupon} userOwnsBooklet={userOwnsBooklet} />
              )
              : companyReview?.coupons.slice(1, 4).map((coupon) =>
                <CompanyCoupon key={coupon._id} coupon={coupon} userOwnsBooklet={userOwnsBooklet} />
              )
            )
          }
          {!isPrinting && companyReview?.coupons.length > 4 &&
            <p className="show-all" onClick={handleShowAllCoupons}>
              {showAllCoupons ? 'Visa färre' : 'Visa alla'}
              {showAllCoupons ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
            </p>
          }
          <BsChevronCompactRight className="arrow" size={30} />
        </div>
      </div>
    </div>
  )
}
