import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { addCompanyMutation, updateCompanyMutation, getCompaniesQuery } from '../../queries/queries';
import { Form, Field, Group } from '../../common/index';
import { useZoneContext } from '../../context/ZoneContext';

function CompaniesForm(props) {
  const { content, categories, types, onComplete } = props;
  const { zone } = useZoneContext();
  const [sContent, setSContent] = useState(content);

  useEffect(() => {
    setSContent({
      ...content,
      typeId: content.typeId || 0,
      vStreetAddress: content.visitAddress && content.visitAddress.streetAddress,
      vZipCode: content.visitAddress && content.visitAddress.zipCode,
      vCity: content.visitAddress?.city != null ? content.visitAddress.city : zone?.name,
      vState: content.visitAddress && content.visitAddress.state,
    });
  }, [content, zone?.name]);

  const ss = (c) => setSContent({ ...sContent, ...c });

  return (
    <tr key={content ? content._id : 'new'} className="open">
      <td colSpan={4}>
        <Form sContent={sContent} parent="zoneId" get={getCompaniesQuery} add={addCompanyMutation} update={updateCompanyMutation} onComplete={onComplete}>
          <Group title="Allmänt ">
            <Field type="text" req name="name" title="Namn på företag *" value={sContent.name} onChange={ss} />
            <Field type="image" name="logoURL" title="Logotyp (max 1MB)" value={sContent.logoURL} onChange={ss} />
            <Field type="textarea" x2 name="notes" title="Anteckningar" value={sContent.notes} onChange={ss} />
          </Group>
          <Group title="Publika uppgifter">
            <Field type="phone" name="phone" title="Telefon" value={sContent.phone} onChange={ss} />
            <Field type="url" name="websiteURL" title="Webbplats" value={sContent.websiteURL} onChange={ss} />
          </Group>
          <Group title="Besöksadress">
            <Field type="text" x2 name="vStreetAddress" title="Gatuadress" value={sContent.vStreetAddress} onChange={ss} />
            <Field type="text" name="vZipCode" title="Postnummer" value={sContent.vZipCode} onChange={ss} />
            <Field type="text" name="vCity" title="Stad" value={sContent.vCity} onChange={ss} />
            <Field type="text" name="vState" title="Län" value={sContent.vState} onChange={ss} />
          </Group>
          <Group title="Inställningar">
            <Field type="selectObject" req name="companyCategoryId" title="Kategori *" value={sContent.companyCategoryId} selectDataInfo={categories} selectData={categories.categories} onChange={ss} />
            <Field type="select" req name="typeId" title="Typ *" value={sContent.typeId} selectDataInfo={types} selectData={types.types} onChange={ss} />
            <Field type="number" name="mainSponsor" title="Sponsor" value={sContent.mainSponsor} onChange={ss} />
            <Field type="selectYesOrNo" name="earlyBird" title="Early Bird" value={sContent.earlyBird} selectDataInfo={{ loading: false }} selectData={[{ _id: true, name: 'Ja' }, { _id: false, name: 'Nej' }]} onChange={ss} />
          </Group>
          <Group title="Ansvarig kontakt">
            <Field type="text" name="contactPerson" title="Namn" value={sContent.contactPerson} onChange={ss} />
            <Field type="phone" name="contactMobile" title="Mobil" value={sContent.contactMobile} onChange={ss} />
            <Field type="admins" x2 name="adminEmails" title="E-post" value={sContent.adminEmails} onChange={ss} />
          </Group>
        </Form>
      </td>
    </tr>
  );
}

CompaniesForm.propTypes = {
  content: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  types: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

CompaniesForm.defaultProps = {
  content: null,
}

export default CompaniesForm;
