import React, { useEffect, useState } from 'react'
import { BookletFilterButton } from './BookletFilterButton';

export const BookletFilter = ({ selectedBookletCategories, categoriesFilter, setCategoriesFilter }) => {
  const [categories, setCategories] = useState([])

  useEffect(() => {
    if (selectedBookletCategories?.length > 0) {
      setCategories(selectedBookletCategories)
    }
  }, [selectedBookletCategories]);

  return (
    <div className='booklet-filter'>
      {categories.map(category => (
        <BookletFilterButton
          key={category._id}
          category={category}
          categoriesFilter={categoriesFilter}
          setCategoriesFilter={setCategoriesFilter}
        />
      ))}
    </div>
  )
}

