import gql from 'graphql-tag';

// QUERIES

const getOrdersByTeamMemberQuery = gql`
  query ordersByTeamMember($teamMemberId: ObjectId!) {
    orders: ordersByTeamMember(teamMemberId: $teamMemberId) {
      _id
      #period
      #cityName
      userFirstname
      userLastname
      associationName
      teamName
      teamMemberFirstname
      teamMemberLastname
      swishNumber
      completedDate
      bookletTitle
    }
  }
`;

const getTeamMembersSafeQuery = gql`
  query teamMembersInTeam($teamId: ObjectId!) {
    teamMembers: teamMembersInTeam(teamId: $teamId) {
      _id
      teamId
      firstname
      lastname
      slug
    }
  }
`;

const getTeamMembersQuery = gql`
  query teamMembersInTeam($teamId: ObjectId!, $includeNotApprovedByGuardian: Boolean) {
    teamMembers: teamMembersInTeam(teamId: $teamId, includeNotApprovedByGuardian: $includeNotApprovedByGuardian) {
      _id
      teamId
      firstname
      lastname
      adminEmails
      soldBooklets
      isApprovedByGuardian
      adminInvitations {
        sentToEmail
      }
    }
  }
`;


const getTeammemberById = gql`
  query teamMemberById($teamMemberId: ObjectId!){
    teamMember: teamMember(teamMemberId: $teamMemberId) {
      firstname
      lastname
      adminEmails
      isApprovedByGuardian
      slug
      associationSlug
      teamSlug
    }
  }
`

const getTeammemberBySlug = gql`
  query teamMemberBySlug($slug: String!, $associationSlug: String!, $teamSlug: String!) {
    teamMember: teamMemberBySlug(slug: $slug, associationSlug: $associationSlug, teamSlug: $teamSlug) {
      _id
      firstname
      lastname
    }
  }
`

// MUTATIONS

const addTeamMemberMutation = gql`
  mutation(
    $teamId: ObjectId!,
    $firstname: String!,
    $lastname: String!,
    $adminEmails: [String!]
    ) {
    createTeamMember(
      teamId: $teamId,
      firstname: $firstname,
      lastname: $lastname,
      adminEmails: $adminEmails,
      ) {
        _id
      }
    }
`;

const createTeamMemberAndAdmin = gql`
  mutation(
    $teamId: ObjectId!,
    $firstname: String!,
    $lastname: String!,
    $teamMemberCreationToken: String!
  ) {
  createTeamMemberAndAddMeAsTeamMemberAdmin(
    teamId: $teamId,
    firstname: $firstname,
    lastname: $lastname,
    teamMemberCreationToken: $teamMemberCreationToken,
    ) {
      _id
      decryptedAdminToken
      adminToken
    }
  }
`

const createTeamAdmin = gql`
  mutation(
    $teamId: ObjectId!,
    $teamAdminToken: String!,
    $portalUrlForEmails: String!
  ) {
    addMeAsTeamAdmin(
      teamId: $teamId,
      teamAdminToken: $teamAdminToken
      portalUrlForEmails: $portalUrlForEmails
    ) {
      _id
    }
  }
`

const addTeamAdmin = gql`
  mutation(
    $teamMemberId: ObjectId!,
    $adminToken: String!
  ) {
    addMeAsTeamMemberAdmin(
      teamMemberId: $teamMemberId,
      adminToken: $adminToken
    ) {
      _id
    }
  }
`

const updateTeamMemberMutation = gql`
  mutation(
    $_id: ObjectId!
    $firstname: String
    $lastname: String
    $adminEmails: [String!]
    ) {
    updateTeamMember(
      _id: $_id,
      firstname: $firstname,
      lastname: $lastname,
      adminEmails: $adminEmails,
      ) {
        _id
    }
  }
`;

const approveTeamMemberMutation = gql`
  mutation(
    $teamMemberId: ObjectId!
    $adminToken: String!
  ) {
    approveTeamMember(
      teamMemberId: $teamMemberId
      adminToken: $adminToken
    ) {
      _id
      isApprovedByGuardian
    }
  } 
`


export {
  getOrdersByTeamMemberQuery,
  getTeamMembersSafeQuery,
  getTeamMembersQuery,
  getTeammemberById,
  getTeammemberBySlug,
  addTeamMemberMutation,
  createTeamMemberAndAdmin,
  createTeamAdmin,
  addTeamAdmin,
  updateTeamMemberMutation,
  approveTeamMemberMutation
};
