import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider, useQuery } from '@apollo/react-hooks';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import ScrollToTop from 'react-router-scroll-top';
import CityFav from './img/favicon-city-vit.png';
import SaEnkeltFav from './img/sa-enkelt-favicon.png';
import { REACT_APP_API_WEBSOCKET_URL, REACT_APP_API_HTTP_URL, REACT_APP_BRAND } from './env';
import { getMyUserQuery } from './queries/queries';
import Navigation from './components/Navigation';
import MyUserForm from './components/auth/MyUserForm';
import MyUserPassword from './components/auth/MyUserPassword';
import Users from './components/super-admin/Users';
import Cities from './components/super-admin/Cities';
import Companies from './components/super-admin/Companies';
import CompanyAgreements from './components/super-admin/CompanyAgreements';
import Associations from './components/super-admin/Associations';
import AssociationAgreements from './components/super-admin/AssociationAgreements';
import CompanyCoupons from './components/super-admin/CompanyCoupons';
import AuthLoginForm from './components/auth/AuthLoginForm';
import AuthActivateAccountForm from './components/auth/AuthActivateAccountForm';
import AuthResetPasswordForm from './components/auth/AuthResetPasswordForm';
import BookletListView from './components/vara-haften/BookletListView';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DashboardHelp from './components/dashboard/DashboardHelp';
import AssociationTeams from './components/dashboard/association/DashboardTeams';
import AssociationSend from './components/dashboard/association/DashboardSend';
import AssociationHome from './components/dashboard/association/DashboardAssociationHome';
import TeamHome from './components/dashboard/team/DashboardTeamHome';
import Team from './components/dashboard/team/DashboardTeam';
import SalesHome from './components/dashboard/teammember/DashboardSalesHome';
import SalesStatistic from './components/dashboard/teammember/DashboardStatistic';
import Order from './components/webshop/Order';
import Startpage from './components/Startpage';
import Invitation from './components/invitations/Invitation';
import OrderConfirm from './components/webshop/OrderConfirm';
import SaEnkeltAuthLoginForm from './components/auth/SaEnkeltAuthLoginForm'
import SaEnkeltAuthForgotPasswordForm from './components/auth/SaEnkeltAuthForgotPasswordForm';
import { useNavigationContext } from './context/NavigationContext';
import BookletView from './components/vara-haften/BookletView';
import PappersHafteView from './components/pappershafte/PappersHafteView';
import CompanyReview from './components/review/CompanyReview';
import TeammemberTips from './components/dashboard/teammember/TeammemberTips';
import Booklets from './components/super-admin/Booklets';
import OrderBatch from './components/super-admin/OrderBatch';

function Auth(props) {
  const { onLoading, logout, authToken, children } = props;
  const { data: userData, loading: userLoading, error: userError, refetch: refetchUser } = useQuery(getMyUserQuery);//, { errorPolicy: 'ignore' });

  const onFocus = () => {
    userData && refetchUser(); // Lade till userData &&
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  if (!authToken) {
    return children;
  }

  if (userLoading) {
    return onLoading;
  }

  if (userError && !userError.networkError && userError.graphQLErrors.length > 0) {
    console.log('userError logging out: Session expired', userError);
    logout();
    return null;
  }
  return children;
}

function LoggedIn(props) {
  const { authToken, children } = props;
  return authToken ? children : null;
}

function Guest(props) {
  const { authToken, children } = props;
  return !authToken ? children : null;
}

function App() {
  const [authToken, setAuthToken] = useState(localStorage.getItem('AUTH_TOKEN'));
  const [possession] = useState(JSON.parse(localStorage.getItem('POSSESSION')));
  const [networkError, setNetworkError] = useState(null);
  const [graphQLErrors, setGraphQLErrors] = useState(null);

  const { navigationActivated } = useNavigationContext();
  const wsLink = new WebSocketLink({
    uri: REACT_APP_API_WEBSOCKET_URL,
    options: {
      reconnect: true,
      connectionParams: {
        authToken,
      },
    },
  });

  const httpLink = ApolloLink.from([
    onError(({ graphQLErrors: newGraphQLErrors, networkError: newNetworkError }) => {
      console.log('set error messages: ', newGraphQLErrors, newNetworkError);
      //if (newGraphQLErrors) setGraphQLErrors(newGraphQLErrors);
      //if (networkError) setNetworkError(newNetworkError);
    }),
    createUploadLink({
      uri: REACT_APP_API_HTTP_URL,
      credentials: 'same-origin',
    }),
  ]);

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    authToken ? setContext((_, { headers }) => (
      {
        headers: {
          ...headers,
          token: authToken,
        },
      }
    )).concat(httpLink) : httpLink,
  );

  const client = new ApolloClient({ link, cache: new InMemoryCache() });
  /*    link: authToken ? setContext((_, { headers }) => (
        {
          headers: {
            ...headers,
            token: authToken,
          },
        }
      )).concat(httpLink) : httpLink,*/


  function updateAuthToken(token) {
    updateActivePossession(null);
    //    client.resetStore().then(() => {
    if (token) localStorage.setItem('AUTH_TOKEN', token);
    else localStorage.removeItem('AUTH_TOKEN');
    if (token !== authToken) setAuthToken(localStorage.getItem('AUTH_TOKEN'));
    console.log('ROOT AuthToken', authToken, token);
    client.clearStore();
    //    });
  }

  function updateActivePossession(p) {
    //    setPossession(p);
    if (p) localStorage.setItem('POSSESSION', JSON.stringify(p));
    else localStorage.removeItem('POSSESSION');
  }

  function logout() {
    updateAuthToken(null);
  }


  return (
    <Router>
      <ScrollToTop>
        <ApolloProvider client={client}>
          <Helmet>
            <title>{REACT_APP_BRAND === 'CITY' ? 'Cityhäftet | Shop' : 'Så enkelt | Shop'}</title>
            <meta property="og:image:alt" content={REACT_APP_BRAND === 'CITY' ? 'Cityhäftet logo' : 'Så enkelt logo'} />
            <meta property="og:url" content={REACT_APP_BRAND === 'CITY' ? 'https://web.cityhaftet.se/' : 'https://shop.saenkelt.se/'} />
            <link rel="shortcut icon" href={REACT_APP_BRAND === 'CITY' ? CityFav : SaEnkeltFav}></link>
          </Helmet>
          <Auth onLoading={''} logout={() => { logout(); }} authToken={authToken}>
            <div className={`App ${window.location.pathname.includes('/skapa-webbshop') ? 'invitation' : ''}`}>
              <LoggedIn authToken={authToken}>
                {navigationActivated &&
                  <Route path="/:firstparam?/:secondparam?/:thirdparam?/:fourthparam?/:fifthparam?/:sixthparam?/:seventhparam?/:eighthparam?"
                    render={
                      (routeProps) => <Navigation
                        firstURLParam={routeProps.match.params.firstparam}
                        secondURLParam={routeProps.match.params.secondparam}
                        thirdURLParam={routeProps.match.params.thirdparam}
                        fourthURLParam={routeProps.match.params.fourthparam}
                        fifthURLParam={routeProps.match.params.fifthparam}
                        sixthURLParam={routeProps.match.params.sixthparam}
                        seventhURLParam={routeProps.match.params.seventhparam}
                        eighthURLParam={routeProps.match.params.eighthparam}
                        possessionLS={possession} setPossessionLS={(p) => { updateActivePossession(p); }} logout={logout} />
                    }
                  />
                }
                {(networkError || graphQLErrors) &&
                  <div className="error-message">
                    <span>{networkError}{
                      graphQLErrors && graphQLErrors.map(
                        ({ message, locations, path }) => { return `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}` }
                      )
                    }</span>
                    <button type="button" className="x md" onClick={() => { setNetworkError(null); setGraphQLErrors(null); }}>Stäng</button>
                  </div>
                }
                <Switch>
                  <Route path="/activate/:email/:temppassword" exact><p>Logga ut och gå till samma URL på nytt för att aktivera konto.</p></Route>
                  <Route component={SaEnkeltAuthForgotPasswordForm} path="/forgotpassword" exact />
                  <Route component={AuthResetPasswordForm} path="/resetpassword/:email/:temppassword" exact />
                  <Route path="/profile" exact render={(props) => <MyUserForm {...props} logout={logout} />} />
                  <Route component={MyUserPassword} path="/changepassword" exact />
                  <Route component={Users} path="/users" exact />
                  <Route component={Cities} path="/cities" exact />
                  <Route component={Companies} path="/cities/:zone/companies" exact />
                  <Route component={CompanyAgreements} path="/cities/:city/companies/:company/agreements" exact />
                  <Route component={CompanyCoupons} path="/cities/companies/:company/agreements/:agreement/coupon" exact />
                  <Route component={CompanyCoupons} path="/cities/companies/:company/agreements/:agreement/coupons/:period" exact />
                  <Route component={Associations} path="/cities/:zone/associations" exact />
                  <Route component={AssociationAgreements} path="/cities/associations/:association/agreements" exact />
                  <Route component={Booklets} path="/cities/:zone/booklets" exact />

                  {/* Föreningsansvarig */}
                  <Route component={AssociationHome} path="/cities/associations/:association/home" exact />
                  <Route component={AssociationSend} path="/cities/associations/:association/send" exact />
                  <Route component={AssociationTeams} path="/cities/associations/:association/teams" exact />
                  <Route component={DashboardHelp} path="/cities/associations/:association/help" exact />

                  {/* Lagledare */}
                  <Route component={TeamHome} path="/cities/associations/:association/teams/:team/home" exact />
                  <Route component={Team} path="/cities/associations/:association/teams/:team/team" exact />
                  <Route component={DashboardHelp} path="/cities/associations/:association/teams/:team/help" exact />

                  {/* Lagmedlemmar */}
                  <Route component={SalesHome} path="/cities/associations/:association/teams/:team/teammembers/:teammember/home" exact />
                  <Route component={SalesStatistic} path="/cities/associations/:association/teams/:team/teammembers/:teammember/statistic" exact />
                  <Route component={TeammemberTips} path="/cities/associations/:association/teams/:team/teammembers/:teammember/help" exact />

                  <Route path="/pappershaften" exact render={(props) => <BookletListView {...props} />}></Route>
                  <Route path="/pappershaften/:booklet" exact render={(props) => <PappersHafteView {...props} />}></Route>
                  <Route path="/vara-haften" exact render={(props) => <BookletListView {...props} />}></Route>
                  <Route component={CompanyReview} path="/review/:token" exact />
                  <Route component={OrderBatch} path="/order-batches/:bookletId/:batchLabel" exact />
                  <Route path="/vara-haften" exact render={(props) => <BookletListView {...props} />}></Route>
                  <Route path="/vara-haften/:booklet" exact render={(props) => <BookletView {...props} />}></Route>
                  <Route path="/cities/:booklet" exact render={(props) => <BookletView {...props} />}></Route>
                  <Route path="/login" exact render={(props) => <SaEnkeltAuthLoginForm updateAuthTokenCallback={(token) => updateAuthToken(token)} />}></Route>
                  <Route path="/register" exact render={(props) => <AuthLoginForm updateAuthTokenCallback={(token) => updateAuthToken(token)} />}></Route>
                  <Route path="/skapa-webbshop/:association/:team/:teammembertoken" exact render={(props) =>
                    <Invitation {...props} updateAuthTokenCallback={(token) => { updateAuthToken(token) }} />}></Route>
                  <Route path="/confirm/:order" render={(props) => <OrderConfirm {...props} />}></Route>
                  <Route path="/:association" exact render={(props) => <Order {...props} />}></Route>
                  <Route path="/:association/:team" exact render={(props) => <Order {...props} />}></Route>
                  <Route path="/:association/:team/:teammember" exact render={(props) => <Order {...props} />}></Route>

                  <Route path="/" exact>
                    <Startpage />
                  </Route>
                </Switch>
              </LoggedIn>

              <Guest authToken={authToken}>
                <Switch>
                  <Route component={CompanyReview} path="/review/:token" exact />
                  <Route component={OrderBatch} path="/order-batches/:bookletId/:batchLabel" exact />
                  <Route path="/profile" exact render={(props) => <MyUserForm {...props} logout={logout} />} />
                  <Route render={(props) => <AuthActivateAccountForm {...props} updateAuthTokenCallback={(token) => { updateAuthToken(token); }} />} path="/activate/:email/:temppassword" exact />
                  <Route path="/forgotpassword">
                    <SaEnkeltAuthForgotPasswordForm />
                  </Route>
                  <Route component={AuthResetPasswordForm} path="/resetpassword/:email/:temppassword" exact />
                  <Route path="/order"><AuthLoginForm updateAuthTokenCallback={(token) => { updateAuthToken(token); }} order /></Route>
                  <Route path="/register" exact><AuthLoginForm updateAuthTokenCallback={(token) => { updateAuthToken(token); }} registerForm /></Route>
                  <Route path="/activated" exact><AuthLoginForm updateAuthTokenCallback={(token) => { updateAuthToken(token); }} accountActivated /></Route>
                  <Route path="/resetpassword" exact><AuthLoginForm updateAuthTokenCallback={(token) => { updateAuthToken(token); }} resetPassword /></Route>
                  <Route path="/pappershaften" exact render={() => <SaEnkeltAuthLoginForm updateAuthTokenCallback={(token) => updateAuthToken(token)} />}></Route>
                  <Route path="/pappershaften/:booklet" exact render={() => <SaEnkeltAuthLoginForm updateAuthTokenCallback={(token) => updateAuthToken(token)} />}></Route>
                  <Route path="/vara-haften" exact render={(props) => <BookletListView {...props} />}></Route>
                  <Route path="/vara-haften/:booklet" exact render={(props) => <BookletView {...props} />}></Route>
                  <Route path="/skapa-webbshop/:association/:team/:teammembertoken" exact render={(props) =>
                    <Invitation {...props} updateAuthTokenCallback={(token) => { updateAuthToken(token) }} />}></Route>
                  <Route path="/login" exact render={(props) => <SaEnkeltAuthLoginForm updateAuthTokenCallback={(token) => updateAuthToken(token)} />}></Route>
                  <Route path="/confirm/:order" render={(props) => <OrderConfirm {...props} />}></Route>
                  <Route path="/:association" exact render={(props) => <Order {...props} />}></Route>
                  <Route path="/:association/:team" exact render={(props) => <Order {...props} />}></Route>
                  <Route path="/:association/:team/:teammember" exact render={(props) => <Order {...props} />}></Route>

                  <Route path="/">
                    <Startpage />
                  </Route>
                </Switch>
              </Guest>
            </div>
          </Auth>
        </ApolloProvider>
      </ScrollToTop>
    </Router>
  );
}

export default App;
