import React from 'react'

export default function Info(props) {
  const {children} = props;
  return (
    <div className="container">
      <div className="box info">
        <div className='content'>
          {children}
        </div>
      </div>
    </div>
  )
}
