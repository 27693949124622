import React, { useEffect, useState } from 'react';

const PappersHafteContext = React.createContext();

export const PappersHafteProvider = (props) => {
  const [booklet, setBooklet] = useState(null); // The booklet
  const [bookletCategories, setBookletCategories] = useState([]); // The categories in the booklet
  const [tableOfContent, setTableOfContent] = useState([]); // Array of table of content (categories with companies)

  // This is a flat array of all pages in the print.
  // Used to get the correct page number for each page.
  const [allPages, setAllPages] = useState([]);

  useEffect(() => {
    const topContent = [{ type: 'top-content-1' }, { type: 'top-content-2' }];
    const bottomContent = [{ type: 'bottom-content-1' }, { type: 'bottom-content-2' }];

    // Extract all companycoupons from the table of content
    const companyCoupons = tableOfContent
      .flat()
      .map(categoryGroup => categoryGroup.companies)
      .flat()
      .map(company => company.calculatedCoupons)
      .flat();

    /**
     * All pages in the print
     * Top content
     * Table of content
     * Company coupons
     * Bottom content
     */
    const allPages = [...topContent, ...tableOfContent, ...companyCoupons, ...bottomContent];

    setAllPages(allPages);
  }, [setAllPages, tableOfContent]);

  const context = {
    booklet,
    bookletCategories,
    allPages,
    tableOfContent,
    setBooklet,
    setBookletCategories,
    setAllPages,
    setTableOfContent,
  };

  return (
    <PappersHafteContext.Provider value={context}>
      {props.children}
    </PappersHafteContext.Provider>
  );

};

export const usePappersHafteContext = () => React.useContext(PappersHafteContext);
