import React from 'react'
import Switch from 'react-switch'


const ToggleSwitch = ({ onChange, checked, setMenu }) => (
  <Switch
    onBlurCapture={() => setMenu(false)}
    onChange={onChange}
    checked={checked}
    className="react-switch"
    uncheckedIcon={false}
    checkedIcon={false}
    onColor={'#f5f6fa'}
    offColor={'#f5f6fa'}
    height={26}
    // offHandleColor={'#C6CDD6'}
    // offHandleColor={'#C6CDD6'}
    // onHandleColor={'#26A286'}
    handleDiameter={20}
    activeBoxShadow={'0 0 0px 0px #3bf'}
  />
)

export default ToggleSwitch