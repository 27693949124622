import React from 'react'
import { useMutation } from 'react-apollo';
import { EditButton, useAutosize, Button } from '../../../common/index';
import ListNames from '../../../common/ListNames';
import { deleteTeamMemberMutation, moveTeamMemberSalesMutation } from '../../../queries/user';
import ModalTeamMember from './ModalTeamMember';

function TeamMember({ teamId, teammember, user, setEditing, refetchTeamMembers, moveOrdersFrom, setMoveOrdersFrom }) {
  const {width} = useAutosize();
  const [manageTeamMember, setManage] = React.useState(false);
  const [deleteTeamMember] = useMutation(deleteTeamMemberMutation);
  const [moveTeamMemberSales] = useMutation(moveTeamMemberSalesMutation);

  function handleRemove(teamMemberId, soldBooklets) {
    if (soldBooklets >= 1) {
      return (window.alert('Du kan inte radera lagmedlemmen eftersom det finns registrerade försäljningar på den. Flytta på dessa för att kunna radera lagmedlemmen.'));
    }

    if (!window.confirm('Vill du verkligen radera lagmedlemmen?'))
      return false;

    let vars = {
      variables: {
        teamMemberId: teamMemberId,
        soldBooklets: soldBooklets
      },
      // refetchQueries: [
      //   {
      //     query: getTeamMembersQuery,
      //     variables: { teamId: teamId }
      //   }
      // ]
    };
    deleteTeamMember(vars)
      .then(result => {
        console.log('result', result);
        refetchTeamMembers();
        //var row = document.getElementById("user_"+teamMemberId);
        //row.parentNode.removeChild(row);
      })
      .catch(useMutationError => {
        console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors)
      });
  }

  function handleMoveOrders(toTeamMemberId) {
    let vars = {
      variables: {
        toTeamMemberId: toTeamMemberId,
        fromTeamMemberId: moveOrdersFrom
      },
      // refetchQueries: [
      //   {
      //     query: getTeamMembersQuery,
      //     variables: { teamId: teamId }
      //   }
      // ]
    };

    moveTeamMemberSales(vars)
      .then(result => {
        console.log('result', result);
        setMoveOrdersFrom(null);
        refetchTeamMembers();
      })
      .catch(useMutationError => {
        console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors)
      });
  };

  return (
    <>
      { manageTeamMember &&
        <ModalTeamMember user={user} teammember={teammember} setManage={setManage} refetchTeamMembers={refetchTeamMembers}/>
      }
      <tr id={'user_' + teammember._id} className={`c-pointer ${!teammember.isApprovedByGuardian ? 'inactive' : ''}`}
        onClick={(e) => (e.target.nodeName.toLowerCase() !== 'button' && e.target.nodeName.toLowerCase() !== 'img' && e.target.nodeName.toLowerCase() !== 'span') && setManage(true)}
      >
        <td>
          <p className='m-0 arrow'>{`${teammember.firstname} ${teammember.lastname}`}</p>
          {teammember?.adminEmails?.length > 0 && width < 1000 && <ListNames title={'Målsmän'} emails={teammember?.adminEmails}/>}
          <span className="small italic">{!teammember.isApprovedByGuardian && 'Inväntar godkännande...'}</span>
        </td>
        <td>
          {teammember?.adminEmails?.length > 0 && <ListNames emails={teammember?.adminEmails}/>}
        </td>
        <td>
          {/* Redigera */}
          {user.superAdmin && <EditButton size="xs" setEditing={() => setEditing(teammember._id)}>Redigera</EditButton>}
          {/* Flytta */}
          {(moveOrdersFrom === null && user.superAdmin && teammember.soldBooklets !== 0) && 
            // <Button className="xs secondary" size="xs" onClick={() => setMoveOrdersFrom(teammember._id)}>Flytta</Button>
            <button className="xs text secondary" size="xs" onClick={() => setMoveOrdersFrom(teammember._id)}>Flytta</button>
          }
          {(moveOrdersFrom === teammember._id && user.superAdmin) && <Button className="xs secondary" size="xs" onClick={() => setMoveOrdersFrom(null)}>Avbryt</Button>}
          {(moveOrdersFrom !== null && moveOrdersFrom !== teammember._id && user.superAdmin) && <Button className="xs secondary" size="xs" onClick={() => handleMoveOrders(teammember._id)}>Flytta hit</Button>}

          {/* Radera */}
          {user?.superAdmin && <button className=" xs text secondary" size="xs" onClick={() => { handleRemove(teammember._id, teammember.soldBooklets) }}>Radera</button>}      
        </td>
        <td>
          <span>{teammember.soldBooklets}</span>
        </td>
      </tr>
    </>
  )
}

export default TeamMember
