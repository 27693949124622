import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { getPeriodsQuery } from '../queries/queries';


function monthNameSwe(month) {
  const m = parseInt(month, 10);
  return {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'Maj',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Okt',
    10: 'Nov',
    11: 'Dec',
  }[m];
}

function ValueById(props) {
  const { id, idName, valName, queryData } = props;
  let name = '...';
  if ((id || id === 0) && queryData) {
    queryData.map((obj) => {
      if (obj[idName] === id) {
        name = obj[valName];
      }
      return null;
    });
  }
  return name;
}

ValueById.propTypes = {
  id: PropTypes.number,
  idName: PropTypes.string,
  valName: PropTypes.string,
  queryData: PropTypes.array,
};

ValueById.defaultProps = {
  id: null,
  idName: '_id',
  valName: 'name',
  queryData: null
};

function PeriodById(props) {
  const { id } = props;
  const { loading, error, data } = useQuery(getPeriodsQuery);

  let name = '...'; // `${id}`;
  if ((id || id === 0) && !loading && !error && data) {
    data.periods.map((obj) => {
      if (obj.period === id) {
        const date = new Date(obj.startDate);
        const year = date.getFullYear();
        name = `${obj.seasonName} ${year}`;
      }
      return null;
    });
  }
  return name;
}

PeriodById.propTypes = {
  id: PropTypes.number,
};

PeriodById.defaultProps = {
  id: null,
};

function SeasonById(props) {
  const { id, valName, date, excludeYear } = props;
  const { loading, error, data } = useQuery(getPeriodsQuery);

  let name = '...'; // `${id}`;
  if ((id || id === 0) && !loading && !error && data) {
    data.periods.map((obj) => {
      if (obj.period === id) {
        name = obj[valName];
        if (date) {
          const _date = new Date(name);
          name = !excludeYear ? `${_date.getDate()} ${monthNameSwe(_date.getMonth())} ${_date.getFullYear()}` : `${_date.getDate()} ${monthNameSwe(_date.getMonth())}`;
        }
      }
      return null;
    });
  }
  return name;
}

SeasonById.propTypes = {
  date: PropTypes.bool,
  id: PropTypes.number,
  valName: PropTypes.string,
  queryData: PropTypes.array,
};

SeasonById.defaultProps = {
  id: null,
  valName: 'seasonName',
  queryData: null,
};

function Values(props) {
  const { values } = props;
  return (
    values.map((option) => (
      <div key={option}>
        {option}
        <br />
      </div>
    ))
  );
}

Values.propTypes = {
  values: PropTypes.array.isRequired,
};

export { ValueById, Values, PeriodById, SeasonById };
