import React, { useEffect, useState } from 'react';
import SaEnkeltHomeIcon from '../../../img/sa-enkelt-home.svg';
import saljare from '../../../img/shop_user.svg';
import SaEnkeltCard from '../../../img/sa-enkelt-card.png';
import CityCard from '../../../img/cityhaftet.png';
import { useQuery } from 'react-apollo';
import { getTeamNameQuery } from '../../../queries/team';
import { REACT_APP_BASE_URL, REACT_APP_BRAND } from '../../../env';
import { getTeammemberById, getAssociationPublicQuery } from '../../../queries/queries';
import HowTo from '../HowTo';
import Onboarding from '../Onboarding';
import { PageHeader } from '../../../common';
import { useNavigationContext } from '../../../context/NavigationContext';
import ShareWebshop from '../../webshop/ShareWebshop';

export default function DashboardSalesHome(props) {
  const { association: associationId, team: teamId, teammember: teamMemberId } = props.match.params;
  const { data: teamMemberData } = useQuery(getTeammemberById, { variables: { teamMemberId: teamMemberId } });
  const { data: teamData } = useQuery(getTeamNameQuery, { variables: { teamId: teamId } });
  const { data: associationData } = useQuery(getAssociationPublicQuery, { variables: { associationId: associationId } })

  const [shareLink, setShareLink] = useState();
  const [showModal, setShowModal] = useState(false);

  const { setNavigationActivated } = useNavigationContext();
  useEffect(() => {
    setNavigationActivated(true);
  }, []);

  useEffect(() => {
    setShareLink(`${REACT_APP_BASE_URL}/${teamMemberData?.teamMember?.associationSlug}/${teamMemberData?.teamMember?.teamSlug}/${teamMemberData?.teamMember?.slug}`);
  }, [teamMemberData]);

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  return (
    <div id="sa-enkelt-home" className={`teammember`}>
      <PageHeader>
        <h1>{teamMemberData && teamMemberData.teamMember.firstname} {teamMemberData && teamMemberData.teamMember.lastname}</h1>
        <p>{teamData && teamData.name}, {associationData && associationData.association.name}</p>
      </PageHeader>
      <div className='webshop'>
        <Onboarding seller={teamMemberData} />
        <HowTo seller={teamMemberData} team={teamData} />
        <section className='share-shop'>
          <img src={saljare} alt="" />
          <h3>Eller dela en <br></br>direktlänk till din shop!</h3>
          <figure className='share-card'>
            {REACT_APP_BRAND === 'CITY'
              ? <img src={CityCard} alt="" />
              : <img src={SaEnkeltCard} alt="" />
            }
            <figcaption>
              Köp ett rabatthäfte av mig!
              <small>{shareLink}</small>
            </figcaption>
          </figure>
          <button className='share-link-btn' onClick={handleOpenModal}>
            <img height={20} width={20} src={SaEnkeltHomeIcon} alt="" />
            <span>
              Dela länk till min shop
            </span>
          </button>
        </section>
        {showModal &&
          <ShareWebshop
            isMyWebshop
            associationSlug={teamMemberData?.teamMember?.associationSlug}
            teamSlug={teamMemberData?.teamMember?.teamSlug}
            teamMemberSlug={teamMemberData?.teamMember?.slug}
            closeModal={handleCloseModal}
          />
        }
      </div>
    </div>
  )
};
