import React from 'react'
import { FiLoader } from 'react-icons/fi'

function ModalLoading({ teammember, userData }) {
  return (
    <div className='invitation modal-onboarding loading'>
      <div className="box fixed-bottom">
        <FiLoader className='loading-spinner' size={32} />
        <h2>Webbshoppen skapas...</h2>
        <ul>
          <li>{userData?.user.email}</li>
          <li className='capitalize'>{teammember.firstname} {teammember.lastname}</li>
        </ul>
      </div>
    </div>
  )
}

export default ModalLoading
