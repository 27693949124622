import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { currentPeriodQuery, statAllOrdersQuery } from '../../queries/queries';
import { StatsSection, StatsBox, StatsNumber } from '../../common/index';

// function statsVariables(associationId, periods, currentPeriod) {
//   return {
//     variables: {
//       associationId,
//       fromPeriod: currentPeriod === 'all' ? (periods && periods.fromPeriod) : currentPeriod,
//       toPeriod: currentPeriod === 'all' ? (periods && periods.toPeriod) : currentPeriod,
//     },
//   };
// }

function CompaniesStatistics(props) {
  const [period, setPeriod] = useState(null);
  const {data: currentPeriod} = useQuery(currentPeriodQuery);

  const [statDates, setStatDates] = useState({ fromDate: null, toDate: null });

  React.useEffect(() => {
    currentPeriod && setPeriod(currentPeriod.period.period)
  }, [currentPeriod]);
  // const statsVars = statsVariables(associationId, agreementPeriods, period);
  // const { data: agreementPeriods } = useQuery(ongoingPeriodsInAssociationQuery, { 
  //   variables: { 
  //     associationId 
  //   } 
  // });

  // React.useEffect(() => {
  //   agreementPeriods && setPeriod(agreementPeriods.periods.currentPeriod)
  // }, [agreementPeriods])
  React.useEffect(() => {
    currentPeriod && setPeriod(currentPeriod.period.period)
  }, [currentPeriod])

  const { data: allOrders } = useQuery(statAllOrdersQuery, {
    variables: {
      fromDate: statDates.fromDate,
      toDate: statDates.toDate
    }
  });

  return (
    <StatsSection currentPeriod={period} setPeriod={(p) => setPeriod(p)} setStatDates={setStatDates} statDates={statDates}>
      <StatsBox type="number" title="Total försäljning, alla zoner" corner="icon superb">
        <StatsNumber value={allOrders && allOrders.allOrders && allOrders.allOrders.totalNumber} />
      </StatsBox>
    </StatsSection>
  );
}

CompaniesStatistics.propTypes = {
};

CompaniesStatistics.defaultProps = {
};

export default CompaniesStatistics;
