import React, { useState } from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import { getUsersQuery } from '../../queries/queries';
import { TableSection, LoadingTable, EditButton, sortList, SortButton } from '../../common/index';
import Form from './UsersForm';
import Sort from '../../img/sort.svg';

function UserTable(props) {
  const { usersQuery } = props;
  const [editing, setEditing] = useState('');
  const [sortBy, setSortBy] = useState('firstname');
  const [sortOrder, setSortOrder] = useState(1);
  const sort = { sortBy, sortOrder, setSortBy, setSortOrder };

  return (
    <TableSection>
      <div className="table-header">
        <h2>Användare</h2>
      </div>
      <table>
        <thead>

          <tr>
            <td><SortButton name="firstname" sort={sort}>Förnamn<img className="sortBtn" src={Sort} /></SortButton></td>
            <td><SortButton name="lastname" sort={sort}>Efternamn<img className="sortBtn" src={Sort} /></SortButton></td>
            <td><SortButton name="email" sort={sort}>E-post<img className="sortBtn" src={Sort} /></SortButton></td>
            <td>Adminobjekt</td>
            <td>Köpta häften</td>
          </tr>

        </thead>
        <tbody>

          {editing === 'new'
            && <Form key="new" onComplete={() => setEditing('')} />}

          {usersQuery.loading || usersQuery.error ? <LoadingTable query={usersQuery} /> : (
            sortList(usersQuery.users, sort).map((obj) => (
              editing === obj.user._id
                ? <Form key={obj.user._id} content={obj.user} onComplete={() => setEditing('')} />
                : (
                  <tr key={obj.user._id}>
                    <td>{obj.user.firstname}</td>
                    <td>{obj.user.lastname}</td>
                    <td>{obj.user.email}</td>
                    <td>
                      {obj.user.superAdmin && <p>SuperAdmin</p>}
                      {obj.user.adminObjects.zonesIAdmin && obj.user.adminObjects.zonesIAdmin.map((adminObj) => (
                        <p key={adminObj._id}>{adminObj.name}</p>
                      ))}
                      {obj.user.adminObjects.bookletsIAdmin && obj.user.adminObjects.bookletsIAdmin.map((adminObj) => (
                        <p key={adminObj._id}>{adminObj.name}</p>
                      ))}
                      {obj.user.adminObjects.companiesIAdmin && obj.user.adminObjects.companiesIAdmin.map((adminObj) => (
                        <p key={adminObj._id}>{adminObj.name}, {adminObj.zoneName}</p>
                      ))}
                      {obj.user.adminObjects.associationsIAdmin && obj.user.adminObjects.associationsIAdmin.map((adminObj) => (
                        <p key={adminObj._id}>{adminObj.name}, {adminObj.zoneName}</p>
                      ))}
                      {obj.user.adminObjects.teamsIAdmin && obj.user.adminObjects.teamsIAdmin.map((adminObj) => (
                        <p key={adminObj._id}>{adminObj.name}, {adminObj.associationName}</p>
                      ))}
                      {obj.user.adminObjects.teamMembersIAdmin && obj.user.adminObjects.teamMembersIAdmin.map((adminObj) => (
                        <p key={adminObj._id}>{adminObj.firstname} {adminObj.lastname}, {adminObj.teamName}, {adminObj.associationName}</p>
                      ))}
                    </td>
                    <td>{obj.orders && obj.orders.map((order) => (
                      <p key={order && order.orderId}>{order && order.bookletTitle}</p>
                    ))}</td>
                    <td>{obj.user.facebookId}</td>
                    <td><EditButton size="xs" setEditing={() => setEditing(obj.user._id)}>Redigera</EditButton></td>
                  </tr>
                )
            )))}

        </tbody>
      </table>
    </TableSection>
  );
}

UserTable.propTypes = {
  usersQuery: PropTypes.object.isRequired,
};

export default compose(
  graphql(getUsersQuery, { name: 'usersQuery' }),
)(UserTable);
