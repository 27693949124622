import React from 'react'
import { FiLoader } from 'react-icons/fi'

function InvitationCompleted({team, teamMemberId, teammember}) {
  React.useEffect(() => {
    setTimeout(() => {
      console.log('Redirect')
      localStorage.setItem('POSSESSION', JSON.stringify({
        type: 'teamMember',
        teamId: team._id,
        title1: teammember.firstname + ' ' + teammember.lastname,
        typeTitle: 'Lagmedlem',
        _id: teamMemberId
      }))
      window.location.replace(`/cities/associations/${team.associationId}/teams/${team._id}/teamMembers/${teamMemberId}/home`)
    }, 2000)
  }, []);

  return (
    <div className='webshop-completed'>
      <h2>Klart! Redo att börja sälja!</h2>
      <div>
        <FiLoader className='loading-spinner' size={32} />
        <p className='m-0'>Du skickas strax till Mina sidor...</p>
      </div>

    </div>
  )
}

export default InvitationCompleted
