import React from 'react';
import PropTypes from 'prop-types';

function PageHeader(props) {
  const { children } = props;
  return (
    <div className="header">
      <div className="container">
        <div className="header-text">
          {children}
        </div>
      </div>
    </div>
  );
}

PageHeader.propTypes = {
  children: PropTypes.any.isRequired,
};

export { PageHeader };
