import React, { useEffect, useState } from 'react'
import BookletCompany from './BookletCompany'
import { useQuery } from 'react-apollo';
import { getCompanyTypesQuery } from '../../queries/company';
import { validateSvg } from '../../common/Helpers';

function Booklet({ bookletData, categoriesFilter, bookletCategories, isPrinting }) {
  const [sortedCompanies, setSortedCompanies] = useState([]);
  const { data: companyTypesData } = useQuery(getCompanyTypesQuery);

  useEffect(() => {
    if (bookletData?.booklet?.calculatedCompanies) {
      const companies = [...bookletData.booklet.calculatedCompanies];

      // Filter if categoriesFilter has items, otherwise keep all companies
      const filteredCompanies = categoriesFilter?.length > 0
        ? companies.filter(company => categoriesFilter.includes(company.companyCategoryId))
        : companies;

      // Sort the filtered companies by visitAddress.city
      filteredCompanies.sort((a, b) => {
        if (a?.visitAddress?.city < b?.visitAddress?.city) {
          return -1;
        }
        if (a?.visitAddress?.city > b?.visitAddress?.city) {
          return 1;
        }
        return 0;
      });

      setSortedCompanies(filteredCompanies);
    }
  }, [bookletData, categoriesFilter]);

  /* variable used to store only used categories! */
  const usedCategories = bookletCategories?.categoriesInBooklet
    ? categoriesFilter.length > 0
      ? bookletCategories.categoriesInBooklet.filter(category => categoriesFilter.includes(category._id))
      : [...bookletCategories.categoriesInBooklet]
    : [];

  return (
    <div style={{ backgroundColor: '#ffffff' }}>
      {
        bookletCategories ? (
          usedCategories.map((category) => (
            <div className='category-section' key={category._id}>
              <div>
                <div className="category-container">
                  <div className='filter-button-icon' dangerouslySetInnerHTML={{ __html: validateSvg(category.iconPath) ? category.iconPath : '' }} />
                  <h2>{category.name}</h2>
                </div>
              </div>
              <div className='booklets-wrapper'>
                {sortedCompanies
                  .filter((company) => company.companyCategoryId === category._id)
                  .map((company) => (
                    <BookletCompany
                      key={company._id}
                      company={company}
                      userOwnsBooklet={bookletData?.booklet?.userOwnsBooklet}
                      companyType={companyTypesData?.types[company.typeId]}
                      isPrinting={isPrinting}
                      isBookletView
                    />
                  ))}
              </div>
            </div>
          ))
        ) : (
          <div className='booklets-wrapper'>
            {sortedCompanies
              .map((company) => (
                <BookletCompany
                  key={company._id}
                  company={company}
                  userOwnsBooklet={bookletData?.booklet?.userOwnsBooklet}
                  companyType={companyTypesData?.types[company.typeId]}
                  isPrinting={isPrinting}
                  isBookletView
                />
              ))}
          </div>
        )
      }
    </div>
  )
}

export default Booklet
