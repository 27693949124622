import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { addTeamMutation, updateTeamMutation, getTeamsQuery } from '../../../queries/queries';
import { Form, Field, Group } from '../../../common/index';

function TeamForm(props) {
  const { content, onComplete } = props;
  const [sContent, setSContent] = useState(content);

  const ss = (c) => setSContent({ ...sContent, ...c });

  return (
    <tr key={content ? content._id : 'new'} className="open">
      <td colSpan="100">
        <Form sContent={sContent} parent="associationId" get={getTeamsQuery} add={addTeamMutation} update={updateTeamMutation} onComplete={onComplete}>
          <Group title="Allmänt">
            <Field type="text" x2 req name="name" title="Lagnamn" value={sContent.name} onChange={ss} />
          </Group>
          {/* {teamEdit &&  */}
          <Group title="Inställningar">
            <Field type="admins" x2 name="adminEmails" title="Lagledarens e-post" value={sContent.adminEmails} onChange={ss} />
          </Group>
          {/* } */}
        </Form>
      </td>
    </tr>
  );
}

TeamForm.propTypes = {
  content: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default TeamForm;
