import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { REACT_APP_BRAND } from './env'
import { NavigationProvider } from './context/NavigationContext';

const CityTheme = React.lazy(() => import('./themes/CityTheme'));
const SaEnkeltTheme = React.lazy(() => import('./themes/SaEnkeltTheme'));

const ThemeSelector = ({ children }) => {
  console.log(REACT_APP_BRAND)
  return (
    <>
      <React.Suspense fallback={<></>}>
        {REACT_APP_BRAND === 'CITY' ? <CityTheme /> : <SaEnkeltTheme />}
      </React.Suspense>
      {children}
    </>
  )
}

ReactDOM.render(
  <ThemeSelector>
    <NavigationProvider>
      <App />
    </NavigationProvider>
  </ThemeSelector>,
  document.getElementById('root'),
)



