import React from 'react'
import { validateSvg } from '../../common/Helpers';

export const BookletFilterButton = ({ category, categoriesFilter, setCategoriesFilter }) => {
  const isSelected = categoriesFilter.includes(category._id);
  const isValidSvg = validateSvg(category.iconPath);

  const setCategoryFilter = (e, category) => {
    e.preventDefault();
    // Add to filter if it doesn't exist
    if (!categoriesFilter.includes(category._id)) {
      setCategoriesFilter([...categoriesFilter, category._id])
    } else {
      // Remove from filter
      setCategoriesFilter(categoriesFilter.filter(id => id !== category._id))
    }
  };

  return (
    <button onClick={(e) => setCategoryFilter(e, category)} className={`booklet-filter-button ${isSelected ? 'selected' : ''}`}>
      <div className='filter-button-icon' dangerouslySetInnerHTML={{ __html: isValidSvg ? category.iconPath : '' }} />
      <span>{category.name}</span>
    </button>
  )
}

