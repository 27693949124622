import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import { ongoingPeriodsInAssociationQuery, statOrdersByAssociationQuery, statOrdersByTeamQuery, statAssociationTopTeamsQuery, statTeamTopTeamMembersQuery } from '../../../queries/queries';
import { StatsSection, StatsBox, StatsListItem, StatsNumber } from '../../../common/index';
import { REACT_APP_BRAND } from '../../../env';

// function statsVariables(associationId, periods, currentPeriod) {
//   return {
//     variables: {
//       associationId,
//       fromPeriod: currentPeriod === 'all' ? (periods && periods.fromPeriod) : currentPeriod,
//       toPeriod: currentPeriod === 'all' ? (periods && periods.toPeriod) : currentPeriod,
//     },
//   };
// }

function TeamMembersStatistics(props) {
  const { associationId, teamId, _id } = props;
  const [period, setPeriod] = useState();
  const [statDates, setStatDates] = useState({ fromDate: null, toDate: null });

  // const statsVars = statsVariables(associationId, agreementPeriods, period);
  const { data: agreementPeriods } = useQuery(ongoingPeriodsInAssociationQuery, {
    variables: {
      associationId
    }
  });

  React.useEffect(() => {
    agreementPeriods && setPeriod(agreementPeriods.periods.currentPeriod)
  }, [agreementPeriods])

  const { data: statsOrders } = useQuery(statOrdersByAssociationQuery, {
    variables: {
      associationId,
      fromDate: statDates.fromDate ? statDates.fromDate : null,
      toDate: statDates.toDate ? statDates.toDate : null
    }
  });
  const { data: statsOrderTeams } = useQuery(statOrdersByTeamQuery, {
    variables: {
      teamId,
      fromDate: statDates.fromDate ? statDates.fromDate : null,
      toDate: statDates.toDate ? statDates.toDate : null
    }
  });
  const { data: statsTopTeams } = useQuery(statAssociationTopTeamsQuery, {
    variables: {
      associationId,
      fromDate: statDates.fromDate ? statDates.fromDate : null,
      toDate: statDates.toDate ? statDates.toDate : null
    }
  });
  const { data: statsTopTeamMembers } = useQuery(statTeamTopTeamMembersQuery, {
    variables: {
      teamId,
      fromDate: statDates.fromDate ? statDates.fromDate : null,
      toDate: statDates.toDate ? statDates.toDate : null
    }
  });


  return (
    <StatsSection agreementPeriods={agreementPeriods} currentPeriod={period} setPeriod={(p) => setPeriod(p)} setStatDates={setStatDates} statDates={statDates}>
      <StatsBox type="number" title="Laget har sålt" corner="icon hf">
        {statsOrderTeams?.orders?.totalNumber > 0
          ? <StatsNumber value={statsOrderTeams && statsOrderTeams.orders && statsOrderTeams.orders.totalNumber} />
          : <p className='no-sales'>Inga häften sålda under denna period.</p>
        }
      </StatsBox>
      {REACT_APP_BRAND !== 'SAENKELT' &&
        <StatsBox type="number" title="Föreningen har sålt" corner="icon wow">
          {statsOrderTeams?.orders?.totalNumber > 0
            ? <StatsNumber value={statsOrders && statsOrders.orders && statsOrders.orders.totalNumber} />
            : <p className='no-sales'>Inga häften sålda under denna period.</p>
          }
        </StatsBox>
      }
      <StatsBox type="list" title="Säljtoppen (i laget)" corner="icon leader">
        {statsOrderTeams?.orders?.totalNumber > 0
          ? <>
            {statsTopTeamMembers && statsTopTeamMembers.teamMembers.teamMembers.map((teamMember) => (
              <StatsListItem key={teamMember.teamMember._id} title={`${teamMember.teamMember.firstname} ${teamMember.teamMember.lastname}`} value={teamMember.total} />
            ))}
          </>
          : <p className='no-sales'>Inga häften sålda under denna period.</p>
        }
      </StatsBox>
      {/* <StatsBox type="amount" title="Laget har tjänat" corner="icon cash">
        <StatsAmount value={statsOrderTeams && statsOrderTeams.orders && statsOrderTeams.orders.totalAmount} />
      </StatsBox> */}
      {REACT_APP_BRAND !== 'SAENKELT' &&
        <StatsBox type="list" title="Säljtoppen lag" corner="icon leader">
          {statsOrderTeams?.orders?.totalNumber > 0
            ? <>
              {statsTopTeams && statsTopTeams.teams.teams.map((team) => (
                <StatsListItem key={team.team._id} title={team.team.name} value={team.total} />
              ))}
            </>
            : <p className='no-sales'>Inga häften sålda under denna period.</p>
          }
        </StatsBox>
      }
    </StatsSection>
  );
}

TeamMembersStatistics.propTypes = {
  associationId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
};

TeamMembersStatistics.defaultProps = {
};

export default TeamMembersStatistics;
