import React from 'react'
import OrderBatchDownloadPDF from './OrderBatchDownloadPDF';

const OrderBatch = (props) => {
  const { bookletId, batchLabel } = props.match.params;

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <h2 className='mb-lg'>PDF-värdebevis</h2>
      <div className="box">
        <OrderBatchDownloadPDF isEmailView bookletId={bookletId} batchLabel={batchLabel} />
      </div>
    </div>
  )
}

export default OrderBatch
