import React, { useState, useEffect } from 'react';
import { graphql, useLazyQuery } from 'react-apollo';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import { Link, NavLink, Redirect } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { getAssociationQuery, getMyUserQuery } from '../queries/queries';
import User from '../img/user.svg';
import MenuIcon from '../img/menu-icon.png';
import Logout from '../img/logout.svg';
import NavItem from '../common/NavItem';
import HomeIcon from '../img/home_line.svg';
import HomeIconActive from '../img/home_fill.svg';
import SendIcon from '../img/send_line.svg';
import SendIconActive from '../img/send_fill.svg';
import TeamIcon from '../img/users_line.svg';
import TeamIconActive from '../img/users_fill.svg';
import HelpIcon from '../img/help.svg';
import HelpIconActive from '../img/help_fill.svg';
import StatisticIcon from '../img/statistic.svg';
import StatisticIconActive from '../img/statistic_fill.svg';
import SaEnkeltTipsIcon from '../img/sa-enkelt-bulb-line.svg';
import SaEnkeltTipsIconActive from '../img/sa-enkelt-bulb.svg'
import SaEnkeltHomeIconActive from '../img/sa-enkelt-active-home.svg'
import SaEnkeltHomeIcon from '../img/sa-enkelt-home.svg'
import { REACT_APP_BRAND } from '../env';

function MainNavigation(props) {
  const { possession, redirect, firstURLParam } = props;
  const [activeLink, setActiveLink] = React.useState('')

  // console.log('MAIN NAVIGATION - start');
  let menuItems = [];
  let redirectPath = '';

  if (!possession) {
    return null;
  }

  // console.log(possession);

  switch (possession.type) {

    case 'superAdmin':
      redirectPath = `/cities`;
      menuItems = [
        <li key="cities"><Link to="/cities">Zoner</Link></li>,
        <li key="users"><Link to="/users">Användare</Link></li>,
      ];
      break;
    case 'zone':
      redirectPath = `/cities/${possession._id}/companies`;
      break;
    /*
      case 'booklet':
      break;
    */
    case 'company':
      redirectPath = `/cities/${possession.zoneId}/companies/${possession._id}/agreements`;
      menuItems = [
        <li key="company-agreements"><Link to={`/cities/companies/${possession._id}/agreements`}>Startsida</Link></li>,
      ];
      break;
    case 'association':
      // redirectPath = `/cities/associations/${possession._id}/teams`;
      redirectPath = `/cities/associations/${possession._id}/home`;
      menuItems = [
        <li>
          <NavLink to={`/cities/associations/${possession._id}/home`} isActive={(isActive) => isActive && setActiveLink('home')}>
            <NavItem title="Hem" icon={activeLink === 'home' ? HomeIconActive : HomeIcon} />
          </NavLink>
        </li>,
        <li>
          <NavLink to={`/cities/associations/${possession._id}/send`} isActive={(isActive) => isActive && setActiveLink('send')}>
            <NavItem title="Utskick" icon={activeLink === 'send' ? SendIconActive : SendIcon} />
          </NavLink>
        </li>,
        <li>
          <NavLink to={`/cities/associations/${possession._id}/teams`} isActive={(isActive) => isActive && setActiveLink('teams')}>
            <NavItem title="Lag" icon={activeLink === 'teams' ? TeamIconActive : TeamIcon} />
          </NavLink>
        </li>,
        <li>
          <NavLink to={`/cities/associations/${possession._id}/help`} isActive={(isActive) => isActive && setActiveLink('help')}>
            <NavItem title="Hjälp" icon={activeLink === 'help' ? HelpIconActive : HelpIcon} />
          </NavLink>
        </li>
        // <li key="association-agreements"><Link to={`/cities/associations/${possession._id}/teams`}>Startsida</Link></li>,
      ];
      break;
    case 'team':
      redirectPath = `/cities/associations/${possession.associationId}/teams/${possession._id}/home`;
      menuItems = [
        <li>
          <NavLink to={`/cities/associations/${possession.associationId}/teams/${possession._id}/home`} isActive={(isActive) => isActive && setActiveLink('home')}>
            <NavItem title="Hem" icon={activeLink === 'home' ? SaEnkeltHomeIconActive : SaEnkeltHomeIcon} />
          </NavLink>
        </li>,
        <li>
          <NavLink to={`/cities/associations/${possession.associationId}/teams/${possession._id}/team`} isActive={(isActive) => isActive && setActiveLink('teams')}>
            <NavItem title="Statistik" icon={activeLink === 'teams' ? StatisticIconActive : StatisticIcon} />
          </NavLink>
        </li>,
        <li>
          <NavLink to={`/cities/associations/${possession.associationId}/teams/${possession._id}/help`} isActive={(isActive) => isActive && setActiveLink('help')}>
            <NavItem title="Tips" icon={activeLink === 'help' ? SaEnkeltTipsIconActive : SaEnkeltTipsIcon} />
          </NavLink>
        </li>
      ]
      break;
    case 'teamMember':
      redirectPath = `/cities/associations/${possession.associationId}/teams/${possession.teamId}/teamMembers/${possession._id}/home`;
      menuItems = [
        <li>
          <NavLink to={`/cities/associations/${possession.associationId}/teams/${possession.teamId}/teamMembers/${possession._id}/home`} isActive={(isActive) => isActive && setActiveLink('home')}>
            <NavItem title="Hem" icon={activeLink === 'home' ? SaEnkeltHomeIconActive : SaEnkeltHomeIcon} />
          </NavLink>
        </li>,
        <li>
          <NavLink to={`/cities/associations/${possession.associationId}/teams/${possession.teamId}/teamMembers/${possession._id}/statistic`} isActive={(isActive) => isActive && setActiveLink('teams')}>
            <NavItem title="Statistik" icon={activeLink === 'teams' ? StatisticIconActive : StatisticIcon} />
          </NavLink>
        </li>,
        <li>
          <NavLink to={`/cities/associations/${possession.associationId}/teams/${possession.teamId}/teamMembers/${possession._id}/help`} isActive={(isActive) => isActive && setActiveLink('help')}>
            <NavItem title="Tips" icon={activeLink === 'help' ? SaEnkeltTipsIconActive : SaEnkeltTipsIcon} />
          </NavLink>
        </li>
      ]
      break;
    case 'buyer':
      redirectPath = '/profile';
      break;
    default:
      break;
  }

  return (
    <header className={`main-header`}>
      <h1 className="mh-logo">{REACT_APP_BRAND === 'CITY' ? 'Cityhäftet' : 'Så Enkelt'}</h1>
      <ul className={`nav-possession ${possession.type === 'superAdmin' ? 'superadmin' : ''}`}>
        {menuItems && menuItems.map((obj, index) => (
          <React.Fragment key={index}>
            {obj}
          </React.Fragment>
        ))}
        {redirect && <Redirect to={redirectPath} push />}
      </ul>
    </header>

  );
}

MainNavigation.propTypes = {
  possession: PropTypes.object,
};

MainNavigation.defaultProps = {
  possession: null,
};

function Role(props) {
  const { possession, onClick } = props;
  const { title1, typeTitle, title2 } = possession;

  const handleClick = () => {
    onClick(possession);
  }
  return (
    <tr>
      {/* <td>
        <Link className="remove" to="./#">Ta bort</Link>
      </td> */}
      <td>
        <span className={`role-type ${REACT_APP_BRAND ==='CITY' ? 'cityhaftet' : ''}`}>{typeTitle}</span>
        <span className="role-title">{title1}</span>
        {title2 !== ''
          && <span className="role-sub-title">{title2}</span>}
      </td>
      <td>
        <button className="primary md alt handle" type="button" onClick={handleClick}>Hantera</button>
      </td>
    </tr>
  );
}

Role.propTypes = {
  possession: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

function Navigation(props) {
  // const { userQuery, logout, possessionLS, setPossessionLS, firstURLParam, secondURLParam } = props;
  const { userQuery, logout, possessionLS, setPossessionLS, firstURLParam, secondURLParam, thirdURLParam, fourthURLParam, fifthURLParam, sixthURLParam, seventhURLParam, eighthURLParam } = props;
  const [possession, setPossession] = useState(possessionLS);
  const [redirecting, setRedirecting] = useState(false);
  const [changing, setChanging] = useState(false);

  const location = useLocation();
  const history = useHistory();

  // console.log('NAVIGATION - start');
  // console.log(firstURLParam);
  // console.log(props);

  function setPoss(p, r = true, c = false) {
    setPossessionLS(p);
    if (possession !== p) setPossession(p);
    if (redirecting !== r) setRedirecting(r);
    if (changing !== c) setChanging(c);
    // console.log('NAVIGATION - possession change set CURRENT, NEW, REDIRECTING, CHANGING', possession, p, r, c);
  }


  // console.log('firstURLParam', firstURLParam);

  const userQueryReady = userQuery && !userQuery.loading && !userQuery.error && userQuery.user;

  const { zonesIAdmin, bookletsIAdmin, companiesIAdmin, associationsIAdmin, teamsIAdmin, teamMembersIAdmin } =
    (userQueryReady ?
      userQuery.user?.adminObjects :
      {
        /*zonesIAdmin: null,
        companiesIAdmin: null,
        associationsIAdmin: null,
        teamsIAdmin: null,
        teamMembersIAdmin: null*/
      }
    );

  const numberOfPossesions = userQueryReady && (zonesIAdmin.length + companiesIAdmin.length + associationsIAdmin.length + teamsIAdmin.length + teamMembersIAdmin.length + (userQuery.user?.superAdmin && 1));
  const buyerPossession = { title1: userQueryReady ? `${userQuery.user?.firstname} ${userQuery.user?.lastname}` : '', typeTitle: 'Köpare', type: 'buyer' };
  const invitationGuardianPossession = { title1: 'Lagmedlem', typeTitle: 'Inbjudan', type: 'invitation' };
  const invitationTeamLeaderPossession = { title1: 'Lag', typeTitle: 'Inbjudan', type: 'invitation' };
  const invitationAssociationPossession = { title1: 'Förening', typeTiyle: 'Inbjudan', type: 'invitation' }

  const [getAssociation, { loading, data: associationData }] = useLazyQuery(getAssociationQuery)

  useEffect(() => {
    console.log('Possession: ' + possession?.type.toUpperCase());
  }, [possession?.type])

  useEffect(() => {
    // console.log(props);
    const onBuyPage = firstURLParam === 'kop' || firstURLParam === 'order';
    const onInvitationPage = firstURLParam === 'invitation';
    const onRootPage = !firstURLParam || firstURLParam === 'login';
    // const onTeamPage = firstURLParam === 'cities' && secondURLParam === 'associations' && fourthURLParam === 'teams' && sixthURLParam === 'teamMembers' && !seventhURLParam;
    const onTeamPage = firstURLParam === 'cities' && secondURLParam === 'associations' && fourthURLParam === 'teams' && sixthURLParam && !seventhURLParam;
    // console.log('On teampage:', onTeamPage);
    // console.log('On sixthURL:', sixthURLParam);
    const onTeamMemberPage = firstURLParam === 'cities' && secondURLParam === 'associations' && fourthURLParam === 'teams' && sixthURLParam?.toLowerCase() === 'teammembers' && !!seventhURLParam;


    if (onRootPage) {
      localStorage.removeItem('LOCK_USER');
    }

    if (onInvitationPage && !redirecting) {
      if (!possession || possession.type !== 'invitation') {
        if (secondURLParam === 'team') {
          setPoss(invitationTeamLeaderPossession, false);
        }
        else if (secondURLParam === 'teammemberguardian') {
          setPoss(invitationGuardianPossession, false);
        }
        else {
          setPoss(invitationAssociationPossession, false)
        }
      }
    }
    else if (numberOfPossesions) { // Is "admin"
      if (!possession && onBuyPage) { // Entered URL /kop
        // console.log('NAVIGATION ACTION - Set buyer possession, no redirect');
        setPoss(buyerPossession, false);
      }
      else if (!(possession && (possession.type === 'team' || possession.type === 'association') && possession._id === fifthURLParam) && onTeamPage) {
        // else if (possession && possession.type !== 'association' && possession._id === fifthURLParam && onTeamPage) {
        console.log('SET POSSESSION TEAM');
        teamsIAdmin.map((team) => {
          if (team._id === fifthURLParam) {
            // console.log('team:', team);
            console.log(team);
            setPoss(formatedPossession('team', team), false);
          }
          return;
        });
      }
      else if (!(possession && possession.type === 'teamMembers' && possession._id === seventhURLParam) && onTeamMemberPage) {
        // console.log('Set possession teamMember');
        teamMembersIAdmin.map((teamMember) => {
          if (teamMember._id === seventhURLParam) {
            // console.log('teamMember:', teamMember);
            console.log(teamMember);
            setPoss(formatedPossession('teamMember', teamMember), false);
          }
          return;
        });
      }
      else if (!possession && numberOfPossesions === 1) { // Only one admin-possession available
        // console.log('NAVIGATION ACTION - Set to the onlye admin-possession, redirect');
        let poss = buyerPossession;
        if (userQuery.user?.superAdmin) {
          poss = formatedPossession('superAdmin', {});
        }
        else if (zonesIAdmin.length) {
          poss = formatedPossession('zone', zonesIAdmin[0]);
        }
        else if (bookletsIAdmin.length) {
          poss = formatedPossession('booklet', bookletsIAdmin[0]);
        }
        else if (companiesIAdmin.length) {
          poss = formatedPossession('company', companiesIAdmin[0]);
        }
        else if (associationsIAdmin.length) {
          poss = formatedPossession('association', associationsIAdmin[0]);
        }
        else if (teamsIAdmin.length) {
          poss = formatedPossession('team', teamsIAdmin[0]);
        }
        else if (teamMembersIAdmin.length) {
          poss = formatedPossession('teamMember', teamMembersIAdmin[0]);
        }
        setPoss(poss, true);
      }
      else if (possession && onRootPage && !redirecting) { // Just logged in and possession saved from previous session?
        // console.log('NAVIGATION ACTION - Force user to select possession');
        setPoss(null, false);
      }
    }
    else { // Not "admin"
      if (!possession || possession.type !== 'buyer') { // Possession not 'buyer'
        // console.log('NAVIGATION ACTION - Set buyer possession, redirect if not already onBuyPage');
        setPoss(buyerPossession, !onBuyPage);
      }
      else if (possession && onRootPage && !redirecting) { // Logged in, not admin, possession allready set, redirect not triggered yet
        // console.log('NAVIGATION ACTION - Redirect to possession');
        setRedirecting(true);
      }
    }

    return () => console.log('unmounting Navigation...');
  }, [location.pathname])

  useEffect(() => {
    const teamFromApp = firstURLParam === 'cities' && secondURLParam === 'associations' && fourthURLParam === 'teams' && !!fifthURLParam && sixthURLParam?.toLowerCase() === 'teammembers' && !seventhURLParam;
    const teammemberFromApp = firstURLParam === 'cities' && secondURLParam === 'associations' && fourthURLParam === 'teams' && sixthURLParam === 'teamMembers' && !!seventhURLParam && !eighthURLParam;

    if (teammemberFromApp) {
      history.push(`${location.pathname}/home`);
    }
    if (teamFromApp) {
      history.push(location.pathname.split('/').slice(0, -1).join('/') + '/home')
    }
  }, [])

  useEffect(() => {
    if (possession) {
      getAssociation({ variables: { associationId: possession.associationId } })
    }
    // console.log(possessionLS);
  }, [possession, associationData])

  if (userQuery.loading) {
    // console.log('NAVIGATION - userQuery.loading');
    return null;
  }
  if (userQuery.error) {
    // console.log('NAVIGATION - userQuery.error');
    setPoss(null, false);
    logout();
    // console.log('logout');
    return null;
  }

  // Olika scenarion vid inloggning/reload:
  // Anger kop-URL -> (possession ej satt) Loggar in -> Konto "köpare" väljs automatiskt
  // Anger annan URL -> (possession ej satt) Loggar in -> Tvingas välja Konto och redirectas dit
  // Anger kop-URL -> (possession ej satt) Inloggad -> Konto "köpare" väljs automatiskt
  // Anger annan URL -> (possession ej satt) Inloggad -> Tvingas välja Konto och redirectas dit
  // Anger kop-URL (possession satt) -> Inloggad -> Kvar på URL
  // Anger root URL (possesion satt) -> Inloggad -> Tvingas välja konto och redirectas dit
  // Anger annan URL (possesion satt) -> Inloggad -> Kvar på URL

  // Resultat - Efter inloggnig/reload:
  // Om inga adminroller || (kop-URL && (possession ej satt)) -> Välj "köpare" automatiskt, redirecta inte
  // Annars om (annan URL && (possession ej satt)) || (root-URL) -> Tvingas välja konto och redirecta 

  function formatedPossession(adminObjectType, obj) {
    return {
      superAdmin:
        { title1: `${userQuery.user?.firstname} ${userQuery.user?.lastname}`, typeTitle: 'Superadmin', type: 'superAdmin' },
      //        { title1: `${userQuery.user?.firstname} ${userQuery.user?.lastname}`, typeTitle: 'Superadmin', type: 'superAdmin' },
      zone:
        { _id: obj._id, title1: obj.name, typeTitle: 'Zon', type: 'zone' },
      /*booklet:
        { _id: obj._id, title1: obj.name, typeTitle: 'Häfte', type: 'booklet' },*/
      company:
        { _id: obj._id, zoneId: obj.zoneId, title1: obj.name, title2: obj.zoneName, typeTitle: 'Företag', type: 'company' },
      association:
        { _id: obj._id, zoneId: obj.zoneId, title1: obj.name, title2: obj.zoneName, typeTitle: 'Förening', type: 'association' },
      team:
        { _id: obj._id, associationId: obj.associationId, title1: obj.name, title2: obj.associationName, typeTitle: 'Lag', type: 'team' },
      teamMember:
        { _id: obj._id, associationId: obj.associationId, teamId: obj.teamId, title1: `${obj.firstname} ${obj.lastname}`, title2: `${obj.associationName}, ${obj.teamName}`, typeTitle: 'Lagmedlem', type: 'teamMember' },
      buyer:
        { title1: `${userQuery.user?.firstname} ${userQuery.user?.lastname}`, typeTitle: 'Köpare', type: 'buyer' }
      // { title1: `${userQuery.user?.firstname} ${userQuery.user?.lastname}`, typeTitle: 'Köpare', type: 'buyer' }
    }[adminObjectType];
  }

  formatedPossession.propTypes = {
    adminObjectType: PropTypes.object.isRequired,
    obj: PropTypes.object.isRequired,
  };
  // console.log(possession);
  // console.log(
  //   'NAVIGATION - numberOfPossesions, possession, onBuyPage, onInvitationPage, onRootPage, userQuery.user', 
  //   numberOfPossesions, possession, onBuyPage, onInvitationPage, onRootPage, userQuery.user);


  return (
    <nav className={`main-nav ${REACT_APP_BRAND === 'SAENKELT' ? 'saenkelt' : ''}`}>
      <MainNavigation possession={possession} redirect={redirecting} firstURLParam={firstURLParam} />
      <ul className="nav-user">
        <li className={`menu-icon`} onClick={() => setChanging(true)}>
          <img src={MenuIcon} alt="menu" />
          <span>MENY</span>
        </li>
      </ul>
      {(possession && possession.type && !changing)
        ? (
          <></>
          // <ul className="nav-user">
          //   <li className="role" onClick={() => setChanging(true)}>
          //     <span className="role-titles">
          //       <span className="role-title">{possession.title1}</span>
          //       {possession.title2 !== ''
          //         && <span className="role-sub-title">{possession.title2}</span>}
          //     </span>
          //     {associationData && <span><img height={27} src={`${REACT_APP_IMAGE_BASE_URL}${associationData.association.logoURL}`} alt="logo" /></span>}
          //     <span className="role-change"><i className="arrow-down"></i></span>
          //   </li>
          // </ul>
        ) :
        (
          <div className="modal" onClick={() => { setChanging(false); }}>
            <div className="box md">
              <h3>
                Du är inloggad som
              </h3>
              <p>{userQuery.user?.email}</p>
              <div className="nav-table">
                <table>
                  <tbody>
                    {(numberOfPossesions > 0) && <tr><td className="small-label">Du hanterar följande konton:</td></tr>}
                    {userQuery.user?.superAdmin
                      && <Role key="superAdmin" possession={formatedPossession('superAdmin', {})} onClick={setPoss} />}

                    {zonesIAdmin && zonesIAdmin.map((obj) => (
                      <Role key={obj._id} possession={formatedPossession('zone', obj)} onClick={setPoss} />
                    ))}
                    {/*bookletsIAdmin && bookletsIAdmin.map((obj) => (
                      <Role key={obj._id} possession={formatedPossession('booklet', obj)} onClick={setPoss} />
                    ))*/}

                    {companiesIAdmin && companiesIAdmin.map((obj) => (
                      <Role key={obj._id} possession={formatedPossession('company', obj)} onClick={setPoss} />
                    ))}
                    {associationsIAdmin && associationsIAdmin.map((obj) => (
                      <Role key={obj._id} possession={formatedPossession('association', obj)} onClick={setPoss} />
                    ))}
                    {teamsIAdmin && teamsIAdmin.map((obj) => (
                      <Role key={obj._id} possession={formatedPossession('team', obj)} onClick={setPoss} />
                    ))}
                    {teamMembersIAdmin && teamMembersIAdmin.map((obj) => (
                      <Role key={obj._id} possession={formatedPossession('teamMember', obj)} onClick={setPoss} />
                    ))}

                    {/*<Role key="buyer" possession={{ title1: `${userQuery.user?.firstname} ${userQuery.user?.lastname}`, typeTitle: 'Köpare', type: 'buyer' }} onClick={setPoss} />*/}

                  </tbody>
                </table>
              </div>


              {/* <button className="primary lg" type="button" onClick={() => { setPoss(formatedPossession('buyer', {})); }}>Köp häfte</button> */}
              {possession && changing && (
                <button type="button" className="x md" onClick={() => { setChanging(false); }}>Avbryt</button>)}
              {/*userQuery && userQuery.user?.superAdmin || userQuery.user?.adminObjects.associationsIAdmin.length > 0 ?*/
                !localStorage.getItem('LOCK_USER') &&
                <div className="user-actions">
                  <Link to="/profile" className="primary text" onClick={() => { if (!possession) alert('Vänligen välj en roll innan du går in på inställningar för din användare.') }}><img className="user" src={User} />Mina uppgifter</Link>
                  <Link to="/" className="primary text" onClick={() => { setPoss(null, false); logout(); }}><img className="logout" src={Logout} />Logga ut</Link>
                </div>
              }
            </div>
          </div>
        )}
    </nav>
  );
}

Navigation.propTypes = {
  possessionLS: PropTypes.object,
  setPossessionLS: PropTypes.func.isRequired,
  userQuery: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

Navigation.defaultProps = {
  possessionLS: null,
};

export default compose(
  graphql(getMyUserQuery, { name: 'userQuery' }),
)(Navigation);


