/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { loginMutation, registerMutation } from '../../queries/queries';
import { Field, Button } from '../../common/index';
import { Link } from 'react-router-dom';
import { REACT_APP_BRAND } from '../../env';
import { useNavigationContext } from '../../context/NavigationContext';
import SaEnkeltLogo from '../../img/sa-enkelt-logo.svg';
import CityLogo from '../../img/city-logo.svg';

function SaEnkeltAuthLoginForm(props) {
  const { updateAuthTokenCallback, accountActivated, passwordReset, order } = props;
  const [user, setUser] = useState({ email: '', password: '', firstname: '', lastname: '' });
  const [registerForm, setRegisterForm] = useState(props.registerForm);
  const [errorNonMatchingEmail, setErrorNonMatchingEmail] = useState(false);
  const [register, { loading: registerLoading, error: registerError }] = useMutation(registerMutation);//, options);
  const [login, { loading: loginLoading, error: loginError }] = useMutation(loginMutation);//, options);

  const { setNavigationActivated } = useNavigationContext();
  useEffect(() => {
    setNavigationActivated(true);
  }, []);

  useEffect(() => {
    if (order) {
      setRegisterForm(true);
    }
  }, []);

  function submitForm(e) {
    e.preventDefault();

    if (loginLoading || registerLoading) {
      console.log('Loading. Double submit canceled.')
      return null;
    }

    console.log('Submitting...')

    const vars = {
      variables: {
        email: user.email,
        password: user.password,
        firstname: user.firstname,
        lastname: user.lastname,
        longExpiry: false,
      },
    };

    setErrorNonMatchingEmail(false);
    if (registerForm) {
      if (user.email !== user.emailVerified) {
        setErrorNonMatchingEmail(true);
        return null;
      }
      register(vars)
        .then(result => { updateAuthTokenCallback(result && result.data.createUser); })
        .catch(useMutationError => { console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors) });
    } else {
      console.log('login');
      login(vars)
        .then(result => { updateAuthTokenCallback(result && result.data.login); console.log('result && result.data.login', result && result.data.login); })
        .catch(useMutationError => { console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors) });
    }
  }

  const ss = (v) => setUser({ ...user, ...v });

  return (
    <>
      <div id="sa-enkelt-login">
        <header className="login-header">
          {REACT_APP_BRAND === 'CITY'
            ? <img src={CityLogo} alt="City Logo" />
            : <img src={SaEnkeltLogo} alt="Sa Enkelt Logo" />
          }
        </header>
        <div className="left">
          <div className="box md m-0">
            <h3>{registerForm ? 'Registrera konto' : 'Logga in på Mina sidor'}</h3>
            <form id="sa-enkelt-form" onSubmit={(e) => { submitForm(e); }}>

              {accountActivated && <p>Kontot är aktiverat. Vänligen logga in.</p>}
              {passwordReset && <p>Lösenordet är nu ändrat. Vänligen logga in.</p>}
              {(order && registerForm) && <p className="text">Fyll i dina uppgifter. När du sedan genomför köpet finns alla dina rabatter i appen när du loggat in med dina uppgifter.</p>}
              {(order && !registerForm) && <p className="text">Logga in. När du sedan genomför köpet finns alla dina rabatter i appen när du loggat in med dina uppgifter.</p>}

              <div className='row'>
                <Field type="email" x2 name="email" title="E-post" value={user.email} onChange={ss} />
              </div>

              <div className='row'>
                <Field type="password" x2 name="password" title="Lösenord" value={user.password} onChange={ss} />
              </div>

              {registerError && registerError.graphQLErrors.map(({ message }, i) => (
                <p className="error" key={i}>{message}</p>
              ))}
              {loginError && loginError.graphQLErrors.map(({ message }, i) => (
                <p className="error" key={i}>{message}</p>
              ))}
              {errorNonMatchingEmail && <p className="error">E-postadresserna matchar inte.</p>}

              <Button className="primary lg" loading={registerLoading || loginLoading} type="submit">{registerForm ? 'Registrera' : 'Logga in'}</Button>
              {!registerForm && <Link className="text new-pass" to="/forgotpassword">Glömt lösenord?</Link>}

              {registerForm && <Button className="md secondary" onClick={() => setRegisterForm(false)}>Logga in</Button>}
            </form>
          </div>
        </div>
        <div className="right" />
      </div>

    </>
  );
}

SaEnkeltAuthLoginForm.propTypes = {
  updateAuthTokenCallback: PropTypes.func.isRequired,
  order: PropTypes.bool,
  registerForm: PropTypes.bool,
  accountActivated: PropTypes.bool,
  passwordReset: PropTypes.bool,
};

SaEnkeltAuthLoginForm.defaultProps = {
  order: false,
  registerForm: false,
  accountActivated: false,
  passwordReset: false,
};

export default SaEnkeltAuthLoginForm;
