import React, { useEffect } from 'react'
import PageWrapper from './PageWrapper';
import Masonry from 'react-masonry-css'
import { usePappersHafteContext } from '../../context/PappersHafteContext';
import CategoryGroup from './CategoryGroup';

function TableOfContent() {
  const { booklet, bookletCategories, tableOfContent, setTableOfContent } = usePappersHafteContext();

  const categorySettings = booklet?.categorySettings || [];

  useEffect(() => {
    const companies = booklet?.calculatedCompanies || [];

    if (bookletCategories && companies) {
      const newGroupedCategories = bookletCategories.map(category => {
        let companiesInCategory = companies.filter(company => company.companyCategoryId === category._id);

        // Sort companies by city
        companiesInCategory = companiesInCategory.sort((a, b) => {
          const cityA = a?.visitAddress?.city?.trim().toLowerCase();
          const cityB = b?.visitAddress?.city?.trim().toLowerCase();

          if (cityA < cityB) {
            return -1;
          }
          if (cityA > cityB) {
            return 1;
          }
          return 0;
        });

        // Add the category to each company
        const companiesWithCategory = companiesInCategory.map(company => {
          // Find the category name from bookletCategories
          const category = bookletCategories.find(cat => cat._id === company.companyCategoryId);
          const categoryName = category?.name;

          // Find the category setting for the current category
          const categorySetting = booklet?.categorySettings?.find(setting => setting.categoryId === category?._id);

          // If the category setting exists, get the category color
          const categoryColor = categorySetting?.color;

          return {
            ...company,
            category: {
              ...category,
              name: categoryName,
              color: categoryColor // Add the category color if it exists
            }
          };
        });
        return { ...category, companies: companiesWithCategory };
      });

      setTableOfContent([newGroupedCategories]);
    }
  }, [bookletCategories, booklet, setTableOfContent]);

  const sortCategoriesByOrder = (array, booklet) => {
    return array.sort((a, b) => {
      const categorySettingA = booklet?.categorySettings?.find(setting => setting.categoryId === a._id);
      const categorySettingB = booklet?.categorySettings?.find(setting => setting.categoryId === b._id);

      return (categorySettingA?.order || Infinity) - (categorySettingB?.order || Infinity);
    });
  };

  return (
    <div>
      {tableOfContent.map((categoryGroup, pageIndex) => (
        <>
          <PageWrapper key={pageIndex} className='table-of-content'>
            <div className='relative flex flex-col'>
              <h2>{pageIndex === 0 ? 'Innehåll' : 'Innehåll forts.'}</h2>
              <div className="ph-categories">
                <Masonry
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column">
                  {sortCategoriesByOrder(categoryGroup, booklet)
                    .map((category, categoryIndex) => (
                      <CategoryGroup
                        key={categoryIndex}
                        category={category}
                        categoryGroup={categoryGroup}
                        pageIndex={pageIndex}
                        categoryIndex={categoryIndex}
                      />
                    ))}
                </Masonry>
              </div>
            </div>
          </PageWrapper>
          {/* Blank page */}
          {/* <PageWrapper /> */}
        </>
      ))}
    </div>
  )
}

export default TableOfContent
