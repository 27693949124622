import React from 'react'
import PropTypes from 'prop-types'

const FieldWrapper = (props) => {
  const { children, name, title, x2 } = props;

  return (
    <div className={`${name} ${x2 ? 'x2' : ''}`}>
      {children}
      <label htmlFor={name}>{title}</label>
    </div>
  );
}

FieldWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  x2: PropTypes.bool,
};

FieldWrapper.defaultProps = {
  x2: false,
};

export default FieldWrapper


