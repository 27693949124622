/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';
import { resetPasswordMutation } from '../../queries/queries';
import { Field, Button } from '../../common/index';
import { useNavigationContext } from '../../context/NavigationContext';

function AuthResetPasswordForm(props) {
  const { email, temppassword } = props.match.params;
  const [user, setUser] = useState({ password: '' });
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [resetPassword, { loading: resetPasswordLoading, error: resetPasswordError }] = useMutation(resetPasswordMutation);

  const isAuth = localStorage.getItem('AUTH_TOKEN');

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(false);
  }, []);

  function submitForm(e) {
    e.preventDefault();

    if (resetPasswordLoading) {
      console.log('Loading. Double submit canceled.')
      return null;
    }

    const vars = {
      variables: {
        email: email,
        tmpPassword: temppassword,
        newPassword: user.password,
      },
    };

    resetPassword(vars)
      .then(result => { console.log('result', result); setRedirectToLogin(true); })
      .catch(useMutationError => { console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors) });
  }

  const ss = (v) => setUser({ ...user, ...v });

  return (
    <div id="sa-enkelt-login">
      {redirectToLogin && <Redirect to={isAuth ? '/profile' : '/login'} />}
      <header className="login-header">
        <h1 className="mh-logo">Så enkelt</h1>
      </header>
      <div className="left">
        <div className="box md m-0">
          <h3>Välj nytt lösenord</h3>

          <form id="sa-enkelt-form" onSubmit={(e) => { submitForm(e); }}>

            <br />
            <div className='row'>
              <p className="small-label">E-postadress: {email}</p>
            </div>

            <p className="text"></p>
            <div className='row'>
              <Field x2 type="password" name="password" title="Nytt lösenord" value={user.password} onChange={ss} />
            </div>

            {resetPasswordError && resetPasswordError.graphQLErrors.map(({ message }, i) => (
              <p className="error" key={i}>{message}</p>
            ))}
            <Button className="primary lg" loading={resetPasswordLoading} type="submit">Spara lösenord</Button>

          </form>
        </div>
      </div>
      <div className="right" />
    </div>
  );
}

AuthResetPasswordForm.propTypes = {
};

AuthResetPasswordForm.defaultProps = {
};

export default AuthResetPasswordForm;
