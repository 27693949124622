import React, { useState, useEffect } from 'react';
import { graphql } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import { getCompaniesQuery, getCompanyCategoriesQuery, getCompanyTypesQuery, getAdminableBookletsQuery } from '../../queries/queries';
import { LoadingTable, EditButton, sortList, SortButton, TableSection, Button } from '../../common/index';
import CompanyForm from './CompaniesForm';
import Sort from '../../img/sort.svg';
import CompaniesTableBooklet from './CompaniesTableBooklet';
import CompanyTableItem from './CompanyTableItem';
import { useZoneContext } from '../../context/ZoneContext';

function CompaniesTable(props) {
  const { companiesQuery, categoriesQuery, typesQuery, zoneId } = props;
  const { data: adminableBookletsData } = useQuery(getAdminableBookletsQuery, { variables: { zoneId } });

  const { filteredBooklets, setAdminableBooklets, setShowAllBooklets, showAllBooklets, setHiddenBooklets } = useZoneContext();

  const [editing, setEditing] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState(1);
  const [checked, setChecked] = useState([]);
  const sort = { sortBy, sortOrder, setSortBy, setSortOrder };

  useEffect(() => {
    if (adminableBookletsData) {
      setAdminableBooklets(adminableBookletsData.booklets);
    }
  }, [adminableBookletsData, setAdminableBooklets]);

  function populateEditableCheckboxes() {
    setChecked([]);
    if (adminableBookletsData?.booklets) {
      adminableBookletsData.booklets.forEach((bookletObj) => {
        if (bookletObj.couponIds) {
          const couponIds = bookletObj.couponIds;
          const bookletId = bookletObj._id;
          if (couponIds.length > 0) {
            couponIds.forEach((coupon) => {
              let id = `${bookletId}-${coupon}`;
              setChecked((prevChecked) => [...prevChecked, id])
            })
          }
        }
      })
    }
  }

  useEffect(() => {
    populateEditableCheckboxes();
  }, [adminableBookletsData])

  const handleShowAll = () => {
    setShowAllBooklets(!showAllBooklets);
    setHiddenBooklets([]);
  };

  return (
    <TableSection>
      <div className="table-header">
        <h2>Företag</h2>
        <EditButton size="sm add" setEditing={() => setEditing('new')}>Lägg till</EditButton>
      </div>
      <table className='company-table'>
        <thead>
          <tr className='company'>
            <td className="centered-logo">Bild</td>
            <td className='company-name'><SortButton name="name" sort={sort}>Företag<img className="sortBtn" src={Sort} alt="" /></SortButton></td>
            <td><SortButton name="categoryId" sort={sort}>Kategori<img className="sortBtn" src={Sort} alt="" /></SortButton></td>
            <td className='show-all-booklets-container'>
              {!showAllBooklets && <Button className='secondary' onClick={handleShowAll}>Visa alla häften</Button>}
              {showAllBooklets && <Button className='secondary' onClick={handleShowAll}>Visa kommande i zonen</Button>}
            </td>
            {
              filteredBooklets.map((bookletobj) => (
                <td key={bookletobj._id} className="booklet-info booklet-column">
                  <CompaniesTableBooklet booklet={bookletobj} />
                </td>
              ))
            }
          </tr>
          <tr className="button-row">
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {
            editing === 'new'
            && <CompanyForm key="new" categories={categoriesQuery} types={typesQuery} content={{ zoneId }} onComplete={() => setEditing('')} />
          }
          {
            !companiesQuery.companies ? <LoadingTable query={companiesQuery} /> : (
              sortList(companiesQuery.companies, sort).map((obj) => (
                editing === obj._id
                  ? <CompanyForm key={obj._id} categories={categoriesQuery} types={typesQuery} content={obj} onComplete={() => setEditing('')} />
                  : (
                    <CompanyTableItem
                      key={obj._id}
                      company={obj}
                      categoriesQuery={categoriesQuery}
                      setEditing={setEditing}
                      companyId={obj._id}
                      adminEmails={obj.adminEmails}
                      checked={checked}
                      zoneId={zoneId}
                    />
                  )
              ))
            )
          }
        </tbody>
      </table>
    </TableSection>
  );
}

CompaniesTable.propTypes = {
  companiesQuery: PropTypes.object.isRequired,
  categoriesQuery: PropTypes.object.isRequired,
  typesQuery: PropTypes.object.isRequired,
  zoneId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired
};

export default compose(
  graphql(getCompaniesQuery, { name: 'companiesQuery' }),
  graphql(getCompanyCategoriesQuery, { name: 'categoriesQuery' }),
  graphql(getCompanyTypesQuery, { name: 'typesQuery' })
)(CompaniesTable);
