import React from 'react';
import { IoChevronForwardSharp } from 'react-icons/io5';
import Logo from '../../img/sa-enkelt-logo.svg';
import CityLogo from '../../img/city-logo.svg';
import { REACT_APP_BRAND } from '../../env';

function WebshopNavigation() {
  return (
    <nav id="se-nav">
      <div className="container">
        {REACT_APP_BRAND === 'CITY'
          ? <img src={CityLogo} alt="City Logo" />
          : <img src={Logo} alt="Sa Enkelt Logo" />
        }
        {REACT_APP_BRAND === 'CITY'
          ? <a href="https://cityhaftet.se" className='flex items-center'>
            Gå till cityhaftet.se
            <IoChevronForwardSharp />
          </a>
          : <a href="https://saenkelt.se" className='flex items-center'>
            Gå till saenkelt.se
            <IoChevronForwardSharp />
          </a>
        }
      </div>
    </nav>
  )
}

export default WebshopNavigation;
