import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { addZoneMutation, getZonesQuery, updateZoneMutation } from '../../queries/queries';
import { Form, Field, Group } from '../../common/index';

function ZonesForm(props) {
  const { content, onComplete } = props;
  const [sContent, setSContent] = useState(content);

  const ss = (z) => setSContent({ ...sContent, ...z });

  return (
    <tr key={content ? content.id : 'new'} className="open">
      <td colSpan="100">
        <Form sContent={sContent} get={getZonesQuery} add={addZoneMutation} update={updateZoneMutation} onComplete={onComplete}>
          <Group title="Allmänt">
            <Field type="text" req name="name" title="Namn på zon *" value={sContent && sContent.name} onChange={ss} />
            <Field type="admins" x2 name="adminEmails" title="Administratörer" value={sContent && sContent.adminEmails} onChange={ss} />
          </Group>
        </Form>
      </td>
    </tr>
  )
}

ZonesForm.propTypes = {
  content: PropTypes.object,
  onComplete: PropTypes.func.isRequired,
}

ZonesForm.defaultProps = {
  content: null,
}

export default ZonesForm;

