import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { getMyUserQuery } from '../queries/queries';

const inputValidator = (type, value) => {
  let valid;
  let regex;
  let error;
  if (type !== 'emails') {
    error = value === '' ? 'Fyll i det här fältet' : '';
  }

  switch (type) {
    case 'email':
      regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      valid = value !== '' && regex.test(value)
      if (!regex.test(value) && value !== '') {
        error = 'Ogiltig e-postadress'
      }
      return {
        valid,
        error
      };
    case 'emails':
      // console.log(value)
      regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      const validationResult = [];
      value.forEach(email => {
        valid = email !== '' && regex.test(email)
        if (email === '') {
          error = 'Fyll i det här fältet';
        }
        else if (!regex.test(email)) {
          error = 'Ogiltig e-postadress';
        }
        else {
          error = '';
        }
        validationResult.push({ valid, error })
      })
      // console.log(validationResult);
      return validationResult;
    // return {
    //   valid,
    //   error
    // };
    case 'password':
      valid = value.length >= 8
      if (value.length <= 8) {
        error = 'Minst 8 tecken'
      }
      return {
        valid,
        error
      };
    default:
      valid = value !== '';
      return {
        valid,
        error
      };
  }
}

function useAutosize(props) {

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize, false);

    return () => {
      document.querySelector('body').style.cssText = '';
    };
  }, []);

  return dimensions;
}

function useRight(props) {
  const { id, type } = props;
  const { loading, error, data } = useQuery(getMyUserQuery);
  const [hasRight, setHasRight] = useState(false);

  useEffect(() => {
    // console.log('hasRight userData', data, id, type);
    if (!loading && !error) {
      if (data?.user?.superAdmin) {
        setHasRight(true);
      }
      switch (type) {
        case 'city':
          data?.user?.adminObjects?.citiesIAdmin?.forEach((obj) => {
            // console.log('hasRight checking city obj', obj, obj._id, obj._id == id);
            if (obj._id == id) {
              setHasRight(true);
            }
          });
          // console.log('hasRight checking cities', hasRight, id);
          break;
        case 'company':
          data?.user?.adminObjects?.companiesIAdmin?.forEach((obj) => {
            // console.log('hasRight checking company obj', obj, obj._id, obj._id == id);
            if (obj._id == id) {
              setHasRight(true);
            }
          });
          // console.log('hasRight checking companies', hasRight, id);
          break;
        case 'association':
          data?.user?.adminObjects?.associationsIAdmin?.forEach((obj) => {
            // console.log('hasRight checking association obj', obj, obj._id, obj._id == id);
            if (obj._id == id) {
              setHasRight(true);
            }
          });
          // console.log('hasRight checking associations', hasRight, id);
          break;

        default:
          break;
      }
    }
  });

  return hasRight;
}

const validateSvg = (svgString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, 'image/svg+xml');

  // If the root element is an SVG, return true
  if (doc.documentElement.nodeName === 'svg') {
    return true;
  }

  // If the root element is not an SVG, log a warning and return false
  console.warn('Invalid SVG:', svgString);
  return false;
}

inputValidator.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string
}

useRight.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string.isRequired,
};

useRight.defaultProps = {
  id: 0
};

export { useRight, useAutosize, inputValidator, validateSvg };
