import React from 'react'
import { Button } from '../../common';
import { REACT_APP_IMAGE_BASE_URL } from '../../env';

function BookletCard({ booklet, setBookletModal, handleSelectedBooklet, selectedBooklet }) {
  return (
    <div key={booklet._id} className="box">
      <div className="top">
        <div className="bg left" style={{ backgroundColor: booklet.colorLeft }}></div>
        <div className="bg right" style={{ backgroundColor: booklet.colorRight }}></div>
        <div className="img-container">
          {booklet?.logoURL && <img src={REACT_APP_IMAGE_BASE_URL + booklet.logoURL} alt="" />}
        </div>
        <div className='show-booklet c-pointer' onClick={() => setBookletModal({ open: true, booklet: booklet })}><span>Visa rabatter</span></div>
      </div>


      <div className={`bottom-row ${selectedBooklet?._id === booklet._id ? 'selected' : ''}`}>
        <div>
          <div><b>{booklet?.title}</b></div>
          <div><b>{booklet.price ? booklet.price : 0} kr</b></div>
          <small>Häfte aktiveras i iOS/Android</small>
          {(booklet?.validFrom && booklet?.validTo) && <small>Giltig {new Date(booklet.validFrom).toLocaleDateString().replaceAll('/', '-')} - {new Date(booklet.validTo).toLocaleDateString().replaceAll('/', '-')}</small>}
        </div>
        <Button onClick={() => handleSelectedBooklet(booklet)} className="alt sm">
          {selectedBooklet?._id === booklet._id ? 'Valt' : 'Köp'}
        </Button>
      </div>
    </div>
  )
}

export default BookletCard
