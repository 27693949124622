import React from 'react';
import { REACT_APP_BRAND, REACT_APP_IMAGE_BASE_URL } from '../../../env'
import { useQuery } from '@apollo/react-hooks';
import { getAssociationPublicQuery, getMyUserQuery, ongoingPeriodsInAssociationQuery } from '../../../queries/queries';
import Slider from '../../../common/Slider/Slider';
import SlideContent from '../../../common/Slider/SlideContent';
import SwipeArrow from '../../../img/SwipeArrow.svg';
import SlideImg from '../../../img/forening-slide.svg'
import SlideImg2 from '../../../img/forening-slide2.svg'
import SlideMessage from '../../../img/slide-message-icon.svg'
import SlideUsers from '../../../img/slide-users-icon.svg'
import SlideCheck from '../../../img/slide-check-icon.svg'
import SlideUser from '../../../img/slide-user-icon.svg'
import GooglePlay from '../../../img/googleplay_sv.png'
import AppStore from '../../../img/appstore_sv.png'
import Statistics from './TeamsStatistics';
import ToggleSwitch from '../../../common/ToggleSwitch';
import MenuDots from '../../../img/menu_dots.svg';
import { Button } from '../../../common/index'
import { useNavigationContext } from '../../../context/NavigationContext';

function DashboardAssociationHome(props) {
  const { association: associationId } = props.match.params;
  const { data: userData } = useQuery(getMyUserQuery, { variables: { associationId: associationId } });
  const { data: associationData } = useQuery(getAssociationPublicQuery, { variables: { associationId: associationId } });
  const [menu, setMenu] = React.useState(false);
  const [showGuide, setShowGuide] = React.useState();
  const [initial, setInitial] = React.useState(1);

  const appStoreLink = REACT_APP_BRAND === 'CITY' ? 'https://apps.apple.com/se/app/cityh%C3%A4ftet/id1577900667' : 'https://apps.apple.com/se/app/s%C3%A5-enkelt/id6444106162';
  const googlePlayLink = REACT_APP_BRAND === 'CITY' ? 'https://play.google.com/store/apps/details?id=se.cityhaftet.cityhaftet' : 'https://play.google.com/store/apps/details?id=se.saenkelt.app';

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  React.useEffect(() => {
    setGuide(userData && (!userData?.user.associationTutorialVersion || userData?.user.associationTutorialVersion < 1)).then(() => setInitial(0));
  }, [userData])

  const setGuide = async (isGuide) => {
    setShowGuide(isGuide);
  }

  const [period, setPeriod] = React.useState();
  const { data: agreementPeriods } = useQuery(ongoingPeriodsInAssociationQuery, {
    variables: {
      associationId
    }
  });
  React.useEffect(() => {
    agreementPeriods && setPeriod(agreementPeriods.periods.currentPeriod)
  }, [agreementPeriods]);

  return (
    <div id="dashboard">
      <div className="container">
        <div className="user-info">
          <div className="box-circle">
            {associationData && <img src={`${REACT_APP_IMAGE_BASE_URL}${associationData?.association.logoURL}`} alt="" className="" />}
          </div>
          <h3>{associationData?.association?.name}</h3> {/* mobile */}
          <h2>{associationData?.association?.name}</h2> {/* ipad/desktop */}
          <span>Administratör</span>
        </div>


      </div>
      {/* <div className="dots-menu-anchor">
        <div className="dots-menu-button" onClick={() => { setMenu(!menu) }}>
          <span>
            <img src={MenuDots} alt=""></img>
          </span>
        </div>
        <div className={`dots-menu ${menu === true ? '' : 'toggleHide'}`}>
          <div className="dots-menu-sizer">
            <div className="dots-menu-row" onBlur={() => console.log('blur')}>
              <b>Guide</b>
              <ToggleSwitch
                onChange={() => setShowGuide(!showGuide)}
                setMenu={setMenu}
                checked={showGuide}
              />
            </div>
          </div>
        </div>
      </div> */}
      {userData && <>
        {/* <div>show guide</div> */}
        <div className="relative">
          <div className={`dots-menu absolute`}>
            <div className="dots-menu-sizer">
              <div className="dots-menu-row">
                <ToggleSwitch
                  onChange={() => setShowGuide(!showGuide)}
                  setMenu={setMenu}
                  checked={showGuide}
                />
                <span>{showGuide ? 'Visa statistik' : 'Visa guide'}</span>
              </div>
            </div>
          </div>

          {showGuide ?
            <Slider guideType={'association'} user={userData?.user}>
              <SlideContent initial={initial}>
                <div className="slider-title">
                  <h2>Välkommen!</h2>
                </div>
                <div className="slider-center img-container">
                  <p className='sub-heading m-0'>Du är nu inloggad i portalen. Här administrerar du din förening.</p>
                  <img src={SlideImg} alt="" className="img-slide"></img>
                </div>
                <div className="instruction-container slider-bottom">
                  <h4 className="swipe-mobile">Swajpa dig genom guiden</h4>
                  <h4 className="swipe-md">Klicka dig genom guiden</h4>
                  <img src={SwipeArrow} className="swipe-arrow swipe-mobile" alt=""></img>
                  <img src={SwipeArrow} className="click-arrow swipe-md" alt=""></img>
                </div>
              </SlideContent>

              <SlideContent initial={initial}>
                <div className="slider-title">
                  <h3>Din uppgift är att</h3>
                </div>
                <div className="slider-center">
                  <ul>
                    <li>
                      <img src={SlideMessage} alt="" />
                      <p>1. Göra föreningsutskick</p>
                      <p>Informera föreningens medlemmar att ni ska börja sälja med {REACT_APP_BRAND === 'CITY' ? 'Cityhäftet' : 'Så Enkelt'}.</p>
                    </li>
                    <li>
                      <img src={SlideUsers} alt="" />
                      <p>2. Lägg till lag</p>
                      <p>Lägg till lag och bjud in lagledare genom deras e-postadresser.</p>
                    </li>
                  </ul>
                </div>
                <div className="slider-bottom"></div>
              </SlideContent>

              <SlideContent initial={initial}>
                <div className="slider-title">
                  <h3>... efter att du bjudit in lagledare ska dem...</h3>
                </div>

                <div className="slider-center">
                  <ul>
                    <li>
                      <img src={SlideMessage} alt="" />
                      <p>1. Göra lagutskick</p>
                      <p>Lagledaren informerar sina lagmedlemmar hur de aktiverar webbshoppen.</p>
                    </li>
                    <li>
                      <img src={SlideUsers} alt="" />
                      <p>2. Lägga till lagmedlem</p>
                      <p>Lagledaren bjuder in säljarna genom deras eller målsmans e-postadress.</p>
                    </li>
                  </ul>
                </div>
                <div className="slider-bottom"></div>
              </SlideContent>

              <SlideContent initial={initial}>
                <div className="slider-title">
                  <h3>... efter att lagledarna bjudit in lagmedlemmar ska dem...</h3>
                </div>

                <div className="slider-center">
                  <ul>
                    <li>
                      <img src={SlideCheck} alt="" />
                      <p>1. Godkänna sin medverkan</p>
                      <p>Lagmedlemmen godkänner din medverkan och ber därefter sina vänner köpa häftet i appen.</p>
                    </li>
                    <li>
                      <img src={SlideUser} alt="" />
                      <p>2. Skapa ett konto</p>
                      <p>Lagmedlemmen får, om den vill, skapa ett konto för att följa försäljningen.</p>
                    </li>
                  </ul>
                </div>
                <div className="slider-bottom"></div>
              </SlideContent>

              <SlideContent initial={initial}>
                <div className="slider-title">
                  <h3>... Nu är ni redo att sälja!</h3>
                </div>
                <div className="slider-center">
                  <img src={SlideImg2} alt="" />
                  <p>När dessa steg är klara kan alla som vill köpa häftet av er i {REACT_APP_BRAND === 'CITY' ? 'Cityhäftet' : 'Så Enkelt'}-appen.</p>
                </div>
                <div className="slider-bottom download-buttons">
                  <Button onClick={() => { window.location.assign(googlePlayLink) }}><img src={GooglePlay} alt="" /></Button>
                  <Button onClick={() => { window.location.assign(appStoreLink) }}><img src={AppStore} alt="" /></Button>
                </div>
                {/* <button className="more-info primary alt">
                  <b>Säljtips</b>
                </button> */}
              </SlideContent>
            </Slider>
            :
            <div className="home-statistics">
              <Slider>
                <SlideContent initial={initial}>
                  <Statistics
                    associationId={associationId}
                    agreementPeriods={agreementPeriods}
                    period={period}
                    setPeriod={setPeriod}
                  />
                  {/* <div className="more-statistics">
                      <span>
                        <p>Se mer statistik</p>
                      </span>
                    </div> */}
                </SlideContent>
              </Slider>
            </div>
          }
        </div>

      </>}

    </div>

  )
}

export default DashboardAssociationHome;
