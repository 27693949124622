import React, { useState } from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import smallSwishLogo from '../../../img/swish-logo-small.svg';
import { getOrdersByTeamMemberQuery } from '../../../queries/queries';
import { LoadingTable, TableSection, sortList } from '../../../common/index';

function SalesTable(props) {
  const { salesQuery } = props;
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState(1);
  const sort = { sortBy, sortOrder, setSortBy, setSortOrder };
  const [limit, setLimit] = useState(3);
  const [showAll, setShowAll] = useState(false);


  React.useEffect(() => {
    salesQuery?.orders && console.log('Min försäljning', salesQuery.orders);
  }, [salesQuery?.orders]);


  function formatNumber(phone) {
    let newPhone;
    if (phone.length === 10 && phone.match('^07')) {
      newPhone = phone;
    }
    else if (phone.length < 10 && phone.match('^7')) {
      newPhone = '0' + phone;
    }
    else if (phone.length > 10 && phone.match('^46')) {
      newPhone = phone.replace(/^46/, '0')
    }
    else {
      return 'Faulty number'
    }
    return newPhone.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/, '$1 $2 $3 $4');
  }

  function toggleShowAll() {
    setShowAll(!showAll)
    setLimit(limit === salesQuery.orders.length ? 3 : salesQuery.orders.length);

  }




  return (
    <TableSection>

      <table>
        <thead>
          <tr>
            <td>Min försäljning</td>{/* tidigare "Köpare" */}
          </tr>
        </thead>
        <tbody>
          {!salesQuery.orders ?
            <LoadingTable query={salesQuery} />
            : (
              sortList(salesQuery.orders, sort).slice(0, limit).map((obj) => (
                <tr key={obj._id} className='swish'>

                  <th className='swish-info'>
                    <p className='text-left bold m-0'>{obj.bookletTitle}</p>
                    <div className='flex gap-1'>
                      <img src={smallSwishLogo} alt="swishlogo" style={{ maxHeight: 24, alignSelf: 'center' }} />
                      <div className='flex flex-col'>
                        <p className='m-0 font-14'>{formatNumber(obj.swishNumber)}</p>
                        <p className='m-0 font-14'>{obj.userFirstname} {obj.userLastname}</p>
                      </div>

                    </div>
                  </th>
                  <td>
                    {/*<p className='bold m-0'>{new Date(parseInt(obj?._id.toString().substring(0, 8), 16) * 1000).toLocaleString().substring(0, 10)}</p>*/}
                    <p className='bold m-0'>{new Date(`${obj.completedDate}`).toLocaleDateString()}</p>
                  </td>
                </tr>

              )))}
        </tbody>
        <tr className='btn-container show-all'>
          <button onClick={toggleShowAll} className='bold'>{showAll ? 'Visa färre' : 'Visa alla'}</button>
        </tr>
      </table>

    </TableSection>
  );
}

// SaEnkeltSalesTable.propTypes = {
//   salesQuery: PropTypes.object.isRequired,
// };

export default compose(
  graphql(getOrdersByTeamMemberQuery, { name: 'salesQuery' }),
)(SalesTable);

