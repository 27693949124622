import React, { useState } from 'react'
import { Button } from '../../common/index'
import { useMutation, useQuery } from '@apollo/react-hooks';
import { createTeamMemberAndAdmin, getAssociationBySlug, getMyUserQuery, getTeamBySlug } from '../../queries/queries';
import InvitationGuardian from './InvitationGuardian';
import InvitationTeammember from './InvitationTeammember';
import InvitationConfirm from './InvitationConfirm';
import ModalLoading from '../invitations/ModalLoading';
import InvitationCompleted from './InvitationCompleted';
import StepsInvitation from '../invitations/Stepper';
import { IoChevronBackSharp } from 'react-icons/io5';
import { useNavigationContext } from '../../context/NavigationContext';
import saljare from '../../img/shop_user.svg';
import LogoWhite from '../../img/sa-enkelt-logo-white.svg';
import CityLogo from '../../img/city-logo.svg';
import BgShapes from '../../img/saenkelt-bg-shapes.png';
import phoneImg from '../../img/saljare.png';
import { REACT_APP_BRAND } from '../../env';

function Invitation({ updateAuthTokenCallback, logout, match }) {
  const { setNavigationActivated } = useNavigationContext();
  const { data: userData } = useQuery(getMyUserQuery);
  const { data: associationData } = useQuery(getAssociationBySlug, { variables: { slug: match.params.association } });
  const { data: teamData } = useQuery(getTeamBySlug, { variables: { slug: match.params.team, associationSlug: match.params.association } });
  const [createTeamMember, { loading: createLoading }] = useMutation(createTeamMemberAndAdmin);
  const [teamMemberId, setTeamMemberId] = useState(null);

  const [teammember, setTeammember] = useState({
    firstname: '',
    lastname: '',
  });

  const [isCompleted, setIsCompleted] = useState(false);
  const [isWebshopLoading, setIsWebshopLoading] = useState(false);
  const [step, setStep] = React.useState();

  React.useEffect(() => {
    setNavigationActivated(false);
  }, [])

  //Effekt som redirectar till säljarens uppgifter
  //Triggas när användaren går från utloggad till inloggad
  React.useEffect(() => {
    if (userData && JSON.parse(sessionStorage.getItem('account_set'))) {
      setStep(3);
    } else {
      setStep(1);
    }
  }, [userData]);


  const submitTeamMemberAndAdmin = (e) => {
    e.preventDefault()

    if (createLoading) {
      console.log('Loading. Double submit canceled.');
      return null;
    }

    const vars = {
      variables: {
        teamId: teamData?.team?._id,
        firstname: teammember.firstname,
        lastname: teammember.lastname,
        teamMemberCreationToken: match.params.teammembertoken
      }
    }

    createTeamMember(vars)
      .then(result => {
        console.log('result' + result && result.data);
        setTeamMemberId(result && result.data.createTeamMemberAndAddMeAsTeamMemberAdmin._id);
        setIsCompleted(true);
        sessionStorage.removeItem('account_set');
      })
      .catch(err => { console.log('error' + err && err.graphQLErrors); })
  }

  return (
    <>

      <div className={`invitation sa-enkelt-invitation`}>
        {step === 1 &&
          <div className='bg-shapes'>
            {REACT_APP_BRAND === 'SAENKELT' && <img src={BgShapes} alt="" />}
          </div>
        }

        <header className="container flex flex-col justify-center items-center">
          {REACT_APP_BRAND === 'CITY' 
            ? <>
              {step === 1
                ? <img className='saenkelt-logo' src={CityLogo} alt="" />
                : <h1 className="mh-logo">Så Enkelt</h1>
              }
            </>
            : <>
              {step === 1
                ? <img className='saenkelt-logo' src={LogoWhite} alt="" />
                : <h1 className="mh-logo">Så Enkelt</h1>
              }
            </>
          }

          {step > 1 && <div className='go-back' onClick={() => {
            if (step === 3) {
              sessionStorage.removeItem('account_set');
              setStep(step - 1);
            } else {
              setStep(step - 1);
            }
          }}>
            <IoChevronBackSharp size={30} />
          </div>}
          {step > 1 && <StepsInvitation step={step} />}
        </header>

        <div className={`invitation-content container ${step === 1 ? 'step-1' : ''}`}>

          {!isCompleted
            ? <>
              {step === 1 && <div className='intro'>
                <div className="box flex flex-col items-center">
                  <img className='mb sales-icon' src={saljare} alt="" />
                  <strong>Personlig webbshop</strong>
                  <span className='my'>Skapa en personlig webbshop kopplad till:</span>
                  <span className='capitalized mb-lg bold'>{associationData?.association?.name}, {teamData?.team?.name}</span>
                  <Button onClick={() => setStep(2)} className="lg primary alt flex items-center">
                    Gå vidare
                  </Button>
                </div>

                <div className='why'>
                  <h3>Varför personlig webbshop?</h3>
                  <p>Med egen webbshop säljer du bättre!</p>
                  <p>Du får en direktlänk till din shop som du kan dela på sociala medier, det blir så enkelt att handla av dig och ditt lag!</p>
                  <p>Du får även ett inlogg till Mina sidor där du kan hålla koll på hur många häften du sålt.</p>
                  <p>Kan du slå säljrekord i laget?</p>
                  {REACT_APP_BRAND === 'SAENKELT' && <img className='mobile' src={phoneImg} alt="bild på telefon" />}
                </div>
              </div>}
              {step === 2 &&
                <InvitationGuardian
                  setStep={setStep}
                  updateAuthTokenCallback={(token) => updateAuthTokenCallback(token)}
                />
              }
              {step === 3 && <>
                <InvitationTeammember
                  setStep={setStep}
                  teammember={teammember}
                  setTeammember={setTeammember} />
              </>}
              {step === 4 &&
                <InvitationConfirm
                  userData={userData}
                  teammember={teammember}
                  setStep={setStep}
                  submit={submitTeamMemberAndAdmin}
                />
              }
            </>
            : <InvitationCompleted team={teamData?.team} teamMemberId={teamMemberId} teammember={teammember} />
          }
        </div>
      </div>
      {isWebshopLoading && <ModalLoading teammember={teammember} user={userData} />}

    </>
  )
}

export default Invitation


